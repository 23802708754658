export const SRIDEVI = {
 
  "26/12/2022 to 01/01/2023": [
    ["245", "16", "169"],
    ["369", "88", "378"],
    ["338", "41", "236"],
    ["147", "20", "145"],
    ["670", "32", "138"],
    ["288", "80", "668"],
    ["249", "54", "356"]
  ],
  "02/01/2023 to 08/01/2023": [
    ["480", "27", "124"],
    ["677", "09", "577"],
    ["120", "36", "448"],
    ["180", "92", "345"],
    ["125", "87", "188"],
    ["338", "41", "236"],
    ["368", "74", "167"]
  ],
  "09/01/2023 to 15/01/2023": [
    ["559", "98", "260"],
    ["880", "69", "225"],
    ["489", "12", "200"],
    ["290", "17", "188"],
    ["249", "51", "128"],
    ["460", "03", "256"],
    ["369", "88", "378"]
  ],
  "16/01/2023 to 22/01/2023": [
    ["679", "24", "149"],
    ["345", "28", "233"],
    ["380", "13", "689"],
    ["248", "44", "356"],
    ["127", "05", "140"],
    ["568", "90", "578"],
    ["579", "13", "670"]
  ],

  "23/01/2023 to 29/01/2023": [
    ["470", "11", "344"],
    ["268", "65", "456"],
    ["147", "20", "145"],
    ["170", "88", "459"],
    ["288", "85", "780"],
    ["115", "72", "129"],
    ["350", "89", "180"]
  ],
  "30/01/2023 to 05/02/2023": [
    ["479", "05", "159"],
    ["189", "84", "220"],
    ["123", "62", "390"],
    ["190", "05", "447"],
    ["670", "32", "138"],
    ["347", "45", "690"],
    ["156", "24", "789"]
  ],
  "06/02/2023 to 12/02/2023": [
    ["267", "59", "360"],
    ["249", "54", "356"],
    ["156", "26", "457"],
    ["146", "12", "237"],
    ["288", "80", "668"],
    ["349", "63", "157"],
    ["139", "35", "230"]
  ],
  "13/02/2023 to 19/02/2023": [
    ["257", "49", "289"],
    ["778", "29", "135"],
    ["288", "85", "780"],
    ["389", "08", "134"],
    ["125", "86", "600"],
    ["148", "38", "279"],
    ["137", "19", "478"]
  ],
  "20/02/2023 to 26/02/2023": [
    ["466", "67", "278"],
    ["457", "65", "177"],
    ["348", "52", "679"],
    ["467", "72", "147"],
    ["235", "07", "368"],
    ["577", "94", "167"],
    ["359", "71", "146"]
  ],
  "06/03/2023 to 12/03/2023": [
    ["390", "20", "479"],
    ["789", "48", "567"],
    ["600", "63", "120"],
    ["556", "66", "240"],
    ["278", "76", "169"],
    ["178", "66", "268"],
    ["350", "89", "180"]
  ],
  "13/03/2023 to 19/03/2023": [
    ["145", "00", "280"],
    ["337", "39", "234"],
    ["235", "05", "168"],
    ["459", "87", "269"],
    ["345", "28", "125"],
    ["456", "52", "237"],
    ["789", "48", "567"]
  ],
  "20/03/2023 to 26/03/2023": [
    ["370", "00", "370"],
    ["158", "46", "790"],
    ["347", "40", "226"],
    ["470", "18", "279"],
    ["138", "23", "580"],
    ["370", "03", "247"],
    ["160", "79", "289"]
  ],
  "27/03/2023 to 02/04/2023": [
    ["589", "27", "359"],
    ["137", "13", "247"],
    ["489", "15", "348"],
    ["260", "89", "270"],
    ["158", "42", "147"],
    ["600", "63", "120"],
    ["248", "42", "589"]
  ],
  "03/04/2023 to 09/04/2023": [
    ["569", "01", "678"],
    ["370", "01", "399"],
    ["477", "87", "467"],
    ["234", "97", "467"],
    ["366", "55", "230"],
    ["389", "05", "258"],
    ["789", "49", "667"]
  ],
  "10/04/2023 to 16/04/2023": [
    ["157", "39", "469"],
    ["134", "80", "280"],
    ["567", "80", "460"],
    ["180", "93", "788"],
    ["126", "97", "458"],
    ["360", "97", "359"],
    ["145", "07", "269"]
  ],
  "17/04/2023 to 23/04/2023": [
    ["357", "51", "579"],
    ["680", "45", "348"],
    ["345", "24", "590"],
    ["678", "11", "146"],
    ["167", "40", "190"],
    ["556", "66", "240"],
    ["449", "71", "344"]
  ],
  "24/04/2023 to 30/04/2023": [
    ["799", "53", "355"],
    ["477", "88", "116"],
    ["137", "17", "269"],
    ["170", "81", "290"],
    ["578", "07", "377"],
    ["568", "97", "890"],
    ["468", "85", "456"]
  ],
  "01/05/2023 to 07/05/2023": [
    ["256", "39", "135"],
    ["260", "88", "378"],
    ["679", "21", "245"],
    ["569", "01", "236"],
    ["239", "40", "389"],
    ["178", "69", "478"],
    ["158", "41", "137"]
  ],
  "05/08/2023 to 14/05/2023": [
    ["233", "88", "189"],
    ["159", "51", "128"],
    ["228", "22", "480"],
    ["459", "85", "177"],
    ["700", "72", "129"],
    ["246", "28", "134"],
    ["189", "86", "349"]
  ],
  "15/05/2023 to 21/05/2023": [
    ["339", "58", "279"],
    ["356", "40", "136"],
    ["789", "45", "140"],
    ["369", "85", "267"],
    ["448", "66", "259"],
    ["890", "72", "480"],
    ["679", "29", "126"]
  ],
  "22/05/2023 to 28/05/2023": [
    ["246", "29", "360"],
    ["460", "03", "689"],
    ["458", "78", "350"],
    ["190", "09", "568"],
    ["128", "18", "468"],
    ["123", "60", "136"],
    ["245", "13", "238"]
  ],
  "29/05/2023 to 04/06/2023": [
    ["330", "67", "566"],
    ["256", "30", "145"],
    ["156", "24", "699"],
    ["260", "85", "168"],
    ["680", "42", "129"],
    ["800", "89", "379"],
    ["125", "81", "489"]
  ],
  "05/06/2023 to 11/06/2023": [
    ["578", "06", "114"],
    ["239", "47", "278"],
    ["190", "01", "146"],
    ["233", "81", "678"],
    ["890", "75", "140"],
    ["229", "33", "157"],
    ["899", "65", "159"]
  ],
  "12/06/2023 to 18/06/2023": [
    ["349", "69", "289"],
    ["234", "98", "260"],
    ["467", "75", "249"],
    ["347", "41", "236"],
    ["377", "78", "369"],
    ["138", "29", "469"],
    ["237", "26", "268"]
  ],
  "19/06/2023 to 25/06/2023": [
    ["789", "48", "134"],
    ["357", "55", "267"],
    ["159", "55", "267"],
    ["160", "75", "348"],
    ["178", "67", "278"],
    ["560", "17", "124"],
    ["126", "96", "114"]
  ],
  "26/06/2023 to 02/07/2023": [
    ["459", "83", "120"],
    ["479", "08", "125"],
    ["350", "84", "257"],
    ["156", "24", "680"],
    ["147", "23", "346"],
    ["390", "25", "348"],
    ["360", "95", "690"]
  ],
  "03/07/2023 to 09/07/2023": [
    ["349", "61", "470"],
    ["667", "94", "239"],
    ["268", "61", "588"],
    ["118", "04", "455"],
    ["779", "32", "110"],
    ["449", "72", "237"],
    ["689", "39", "144"]
  ],
  "10/07/2023 to 16/07/2023": [
    ["267", "54", "400"],
    ["379", "94", "149"],
    ["180", "96", "358"],
    ["489", "16", "367"],
    ["469", "96", "457"],
    ["670", "37", "368"],
    ["348", "58", "170"]
  ],
  "17/07/2023 to 23/07/2023": [
    ["260", "89", "234"],
    ["599", "33", "148"],
    ["770", "44", "455"],
    ["568", "97", "250"],
    ["678", "19", "126"],
    ["460", "06", "178"],
    ["339", "51", "128"]
  ],
  "24/07/2023 to 30/07/2023": [
    ["460", "02", "246"],
    ["677", "07", "160"],
    ["890", "76", "790"],
    ["450", "95", "366"],
    ["247", "38", "134"],
    ["378", "82", "589"],
    ["369", "82", "138"]
  ],
  "31/07/2023 to 06/08/2023": [
    ["167", "40", "578"],
    ["269", "79", "135"],
    ["180", "96", "259"],
    ["568", "90", "127"],
    ["258", "55", "690"],
    ["478", "99", "289"],
    ["139", "35", "348"]
  ],
  "07/08/2023 to 13/08/2023": [
    ["669", "17", "368"],
    ["124", "70", "280"],
    ["467", "71", "290"],
    ["588", "17", "133"],
    ["234", "93", "157"],
    ["168", "59", "270"],
    ["278", "79", "199"]
  ],
  "14/08/2023 to 20/08/2023": [
    ["389", "05", "456"],
    ["***", "**", "***"],
    ["340", "73", "490"],
    ["459", "86", "178"],
    ["260", "86", "150"],
    ["479", "08", "189"],
    ["136", "01", "380"]
  ],
  "21/08/2023 to 27/08/2023": [
    ["579", "13", "238"],
    ["778", "29", "360"],
    ["235", "06", "466"],
    ["124", "79", "450"],
    ["579", "12", "237"],
    ["390", "29", "379"],
    ["158", "45", "267"]
  ],
"28/08/2023 to 03/09/2023": [
    ["789", "41", "290"],
    ["189", "83", "346"],
    ["359", "70", "280"],
    ["120", "35", "690"],
    ["258", "58", "125"],
    ["257", "46", "268"],
    ["679", "27", "223"]
  ],
  "04/09/2023 to 10/09/2023": [
    ["123", "60", "244"],
    ["458", "72", "679"],
    ["266", "41", "137"],
    ["235", "08", "567"],
    ["190", "01", "669"],
    ["370", "08", "350"],
    ["459", "87", "458"]
  ],
  "09/10/2023 to 15/10/2023": [
    ["144", "90", "479"],
    ["190", "04", "347"],
    ["167", "49", "126"],
    ["148", "33", "157"],
    ["559", "90", "280"],
    ["158", "42", "570"],
    ["568", "90", "118"]
  ],
  "16/10/2023 to 22/10/2023": [
    ["479", "02", "156"],
    ["350", "86", "259"],
    ["140", "57", "179"],
    ["689", "32", "129"],
    ["356", "49", "135"],
    ["169", "63", "247"],
    ["356", "40", "136"]
  ],
  "23/10/2023 to 29/10/2023": [
    ["468", "82", "237"],
    ["467", "77", "458"],
    ["127", "03", "120"],
    ["578", "06", "259"],
    ["268", "61", "470"],
    ["340", "71", "236"],
    ["467", "74", "239"]
  ],
  "30/10/2023 to 05/11/2023": [
    ["128", "16", "150"],
    ["479", "07", "368"],
    ["690", "54", "699"],
    ["180", "98", "260"],
    ["125", "84", "789"],
    ["459", "84", "590"],
    ["569", "07", "179"]
  ],
  "11/06/2023 to 11/12/2023": [
    ["579", "12", "156"],
    ["890", "77", "340"],
    ["179", "71", "489"],
    ["990", "84", "112"],
    ["146", "19", "135"],
    ["357", "58", "567"],
    ["490", "34", "266"]
  ],
  "11/13/2023 to 11/19/2023": [
    ["245", "14", "338"],
    ["170", "85", "366"],
    ["170", "80", "226"],
    ["679", "25", "140"],
    ["289", "97", "124"],
    ["689", "30", "145"],
    ["346", "34", "590"]
  ],
  "11/20/2023 to 11/26/2023": [
    ["334", "02", "688"],
    ["138", "21", "399"],
    ["479", "06", "899"],
    ["689", "32", "138"],
    ["379", "90", "488"],
    ["147", "29", "126"],
    ["289", "96", "349"]
  ],
  "11/27/2023 to 12/03/2023": [
    ["224", "88", "350"],
    ["370", "09", "135"],
    ["127", "06", "367"],
    ["280", "04", "158"],
    ["170", "81", "470"],
    ["347", "45", "348"],
    ["358", "69", "270"]
  ],
  "12/04/2023 to 12/10/2023": [
    ["458", "77", "278"],
    ["288", "85", "159"],
    ["246", "23", "779"],
    ["229", "37", "188"],
    ["579", "17", "124"],
    ["299", "05", "267"],
    ["600", "67", "890"]
  ],
  "12/11/2023 to 12/17/2023": [
    ["569", "09", "379"],
    ["234", "93", "346"],
    ["136", "05", "447"],
    ["367", "66", "349"],
    ["456", "58", "260"],
    ["137", "17", "368"],
    ["230", "52", "345"]
  ],
  "12/18/2023 to 12/24/2023": [
    ["147", "24", "347"],
    ["368", "71", "290"],
    ["180", "93", "157"],
    ["459", "86", "367"],
    ["457", "64", "789"],
    ["280", "04", "167"],
    ["259", "62", "570"]
  ],
  "12/25/2023 to 12/31/2023": [
    ["145", "01", "579"],
    ["169", "67", "467"],
    ["158", "44", "130"],
    ["578", "03", "337"],
    ["118", "02", "390"],
    ["220", "41", "588"],
    ["235", "08", "459"]
  ],
  "01/01/2024 to 01/07/2024": [
    ["367", "69", "568"],
    ["123", "61", "137"],
    ["336", "27", "124"],
    ["389", "06", "349"],
    ["145", "02", "246"],
    ["358", "61", "128"],
    ["134", "83", "779"]
  ],
  "01/08/2024 to 01/14/2024": [
    ["578", "06", "367"],
    ["336", "25", "258"],
    ["690", "57", "377"],
    ["370", "03", "689"],
    ["369", "87", "278"],
    ["357", "59", "568"],
    ["178", "66", "556"]
  ],
  "01/15/2024 to 01/21/2024": [
    ["123", "64", "130"],
    ["115", "72", "390"],
    ["258", "57", "124"],
    ["377", "75", "230"],
    ["247", "38", "378"],
    ["260", "80", "677"],
    ["477", "82", "679"]
  ],
  "01/22/2024 to 01/28/2024": [
    ["123", "69", "478"],
    ["228", "21", "560"],
    ["350", "83", "247"],
    ["669", "13", "148"],
    ["***", "**", "***"],
    ["566", "73", "445"],
    ["470", "13", "256"]
  ],
  "01/29/2024 to 02/04/2024": [
    ["223", "74", "167"],
    ["136", "00", "389"],
    ["260", "89", "144"],
    ["188", "79", "135"],
    ["146", "17", "359"],
    ["236", "14", "130"],
    ["168", "58", "800"]
  ],
  "02/05/2024 to 02/11/2024": [
    ["589", "22", "499"],
    ["578", "00", "235"],
    ["568", "95", "456"],
    ["190", "04", "356"],
    ["467", "73", "139"],
    ["250", "74", "590"],
    ["359", "71", "678"]
  ],
  "02/12/2024 to 02/18/2024": [
    ["290", "32", "467"],
    ["159", "63", "478"],
    ["269", "74", "380"],
    ["578", "83", "465"],
    ["145", "06", "158"],
    ["338", "09", "299"],
    ["570", "17", "333"]
  ],
"02/19/2024 to 02/25/2024": [
    ["135", "05", "588"],
    ["568", "92", "189"],
    ["369", "05", "274"],
    ["455", "56", "136"],
    ["245", "99", "187"],
    ["199", "46", "399"],
    ["345", "15", "276"]
  ],
  "02/26/2024 to 03/03/2024": [
    ["178", "08", "477"],
    ["368", "25", "330"],
    ["379", "21", "689"],
    ["558", "11", "229"],
    ["235", "62", "235"],
    ["479", "38", "179"],
    ["368", "55", "300"]
  ],
  "03/04/2024 to 03/10/2024": [
    ["127", "86", "370"],
    ["150", "20", "556"],
    ["224", "15", "117"],
    ["570", "62", "222"],
    ["368", "43", "138"],
    ["369", "72", "145"],
    ["146", "53", "800"]
  ],
  "03/11/2024 to 03/17/2024": [
    ["350", "72", "347"],
    ["259", "96", "699"],
    ["259", "18", "188"],
    ["567", "44", "369"],
    ["139", "05", "199"],
    ["568", "61", "380"],
    ["268", "83", "356"]
  ],
  "03/18/2024 to 03/24/2024": [
    ["379", "42", "135"],
    ["456", "76", "289"],
    ["347", "95", "279"],
    ["199", "37", "578"],
    ["348", "68", "359"],
    ["357", "17", "126"],
    ["568", "35", "279"]
  ],
  "03/25/2024 to 03/31/2024": [
    ["458", "99", "144"],
    ["370", "56", "249"],
    ["259", "72", "186"],
    ["589", "02", "168"],
    ["368", "56", "699"],
    ["270", "91", "230"],
    ["479", "67", "145"]
  ],
  "04/01/2024 to 04/07/2024": [
    ["368", "73", "788"],
    ["133", "72", "147"],
    ["255", "23", "788"],
    ["237", "26", "899"],
    ["899", "63", "247"],
    ["160", "70", "127"],
    ["260", "81", "579"]
  ],
  "04/08/2024 to 04/14/2024": [
    ["289", "98", "134"],
    ["369", "81", "146"],
    ["150", "61", "245"],
    ["790", "67", "458"],
    ["569", "00", "370"],
    ["168", "52", "570"],
    ["249", "56", "790"]
  ],
"04/15/2024 to 04/21/2024": [
    ["357", "52", "156"],
    ["569", "04", "248"],
    ["178", "63", "670"],
    ["468", "87", "250"],
    ["130", "42", "237"],
    ["124", "76", "268"],
    ["233", "89", "270"]
  ],
  "04/22/2024 to 04/28/2024": [
    ["668", "00", "127"],
    ["370", "08", "134"],
    ["556", "62", "589"],
    ["358", "60", "389"],
    ["600", "68", "567"],
    ["469", "98", "116"],
    ["389", "09", "270"]
  ],
  "04/29/2024 to 05/05/2024": [
    ["456", "59", "144"],
    ["339", "57", "449"],
    ["158", "48", "477"],
    ["167", "48", "558"],
    ["678", "18", "224"],
    ["479", "03", "689"],
    ["559", "92", "110"]
  ],
  "05/06/2024 to 05/12/2024": [
    ["169", "65", "780"],
    ["220", "42", "660"],
    ["239", "46", "600"],
    ["366", "57", "458"],
    ["489", "17", "377"],
    ["357", "53", "166"],
    ["360", "95", "159"]
  ],
  "05/13/2024 to 05/19/2024": [
    ["880", "64", "112"],
    ["570", "29", "577"],
    ["478", "95", "267"],
    ["557", "70", "578"],
    ["346", "37", "467"],
    ["470", "19", "135"],
    ["179", "78", "567"]
  ],
  "05/20/2024 to 05/26/2024": [
    ["190", "05", "159"],
    ["457", "68", "125"],
    ["245", "13", "580"],
    ["267", "53", "256"],
    ["370", "05", "230"],
    ["236", "13", "599"],
    ["890", "72", "156"]
  ],
  "05/27/2024 to 06/02/2024": [
    ["147", "23", "346"],
    ["778", "25", "159"],
    ["140", "59", "180"],
    ["669", "16", "448"],
    ["588", "10", "127"],
    ["336", "28", "279"],
    ["179", "76", "259"]
  ],
  "06/03/2024 to 06/09/2024": [
    ["246", "29", "450"],
    ["678", "16", "367"],
    ["359", "73", "256"],
    ["678", "15", "258"],
    ["690", "51", "290"],
    ["579", "19", "234"],
    ["670", "32", "345"]
  ],
  "06/24/2024 to 06/30/2024": [
    ["128", "12", "147"],
    ["137", "18", "224"],
    ["147", "28", "350"],
    ["234", "97", "250"],
    ["368", "73", "490"],
    ["250", "76", "178"],
    ["279", "84", "356"]
  ],
  "07/01/2024 to 07/07/2024": [
    ["780", "51", "560"],
    ["357", "50", "136"],
    ["690", "54", "590"],
    ["147", "21", "155"],
    ["158", "49", "270"],
    ["467", "76", "790"],
    ["140", "57", "269"]
  ],
  "07/08/2024 to 07/14/2024": [
    ["890", "77", "223"],
    ["800", "88", "468"],
    ["566", "73", "247"],
    ["134", "88", "170"],
    ["360", "91", "579"],
    ["790", "60", "569"],
    ["168", "53", "148"]
  ],
  "07/15/2024 to 07/21/2024": [
    ["450", "97", "890"],
    ["334", "10", "190"],
    ["459", "81", "669"],
    ["123", "69", "379"],
    ["360", "97", "359"],
    ["168", "57", "269"],
    ["467", "71", "470"]
  ],
  "07/22/2024 to 07/28/2024": [
    ["168", "51", "579"],
    ["447", "53", "166"],
    ["136", "09", "126"],
    ["170", "83", "157"],
    ["370", "08", "170"],
    ["468", "82", "480"],
    ["356", "**", "369"]
  ]
};

export const TIMEBAZAR = {
  "02/01/2023 to 08/01/2023": [
    ["120", "30", "578"],
    ["166", "33", "355"],
    ["390", "25", "447"],
    ["177", "56", "367"],
    ["146", "16", "457"],
    ["120", "31", "290"]
  ],
  "09/01/2023 to 15/01/2023": [
    ["450", "98", "350"],
    ["568", "90", "136"],
    ["258", "53", "139"],
    ["457", "66", "358"],
    ["235", "08", "288"],
    ["390", "29", "568"]
  ],
  "16/01/2023 to 22/01/2023": [
    ["478", "91", "155"],
    ["589", "25", "267"],
    ["156", "28", "279"],
    ["790", "60", "127"],
    ["456", "55", "249"],
    ["130", "43", "148"]
  ],
  "23/01/2023 to 29/01/2023": [
    ["699", "41", "399"],
    ["388", "91", "236"],
    ["256", "33", "120"],
    ["378", "87", "133"],
    ["144", "96", "259"],
    ["123", "66", "240"]
  ],
  "30/01/2023 to 05/02/2023": [
    ["258", "58", "378"],
    ["468", "89", "126"],
    ["189", "83", "157"],
    ["569", "07", "250"],
    ["236", "13", "238"],
    ["699", "48", "116"]
  ],
  "06/02/2023 to 12/02/2023": [
    ["568", "98", "279"],
    ["134", "82", "237"],
    ["126", "94", "789"],
    ["455", "45", "267"],
    ["356", "44", "590"],
    ["140", "56", "600"]
  ],
  "13/02/2023 to 19/02/2023": [
    ["278", "73", "148"],
    ["559", "91", "137"],
    ["169", "68", "189"],
    ["389", "01", "470"],
    ["160", "71", "146"],
    ["378", "87", "133"]
  ],
  "20/02/2023 to 26/02/2023": [
    ["347", "46", "448"],
    ["380", "11", "560"],
    ["377", "72", "480"],
    ["147", "27", "133"],
    ["336", "26", "556"],
    ["130", "42", "480"]
  ],
  "27/02/2023 to 05/03/2023": [
    ["117", "98", "369"],
    ["668", "06", "790"],
    ["246", "24", "158"],
    ["170", "83", "580"],
    ["267", "57", "115"],
    ["468", "87", "890"]
  ],
  "06/03/2023 to 12/03/2023": [
    ["369", "84", "239"],
    ["780", "50", "370"],
    ["678", "14", "248"],
    ["789", "46", "259"],
    ["780", "53", "256"],
    ["126", "97", "160"]
  ],
  "13/03/2023 to 19/03/2023": [
    ["336", "20", "370"],
    ["134", "80", "136"],
    ["668", "09", "289"],
    ["448", "65", "122"],
    ["590", "48", "279"],
    ["669", "10", "118"]
  ],
  "20/03/2023 to 26/03/2023": [
    ["479", "04", "158"],
    ["678", "14", "248"],
    ["344", "19", "667"],
    ["390", "20", "118"],
    ["259", "67", "269"],
    ["456", "58", "125"]
  ],
  "27/03/2023 to 02/04/2023": [
    ["589", "22", "129"],
    ["677", "05", "780"],
    ["378", "88", "279"],
    ["148", "32", "246"],
    ["280", "09", "577"],
    ["488", "00", "370"]
  ],
  "03/04/2023 to 09/04/2023": [
    ["230", "58", "224"],
    ["458", "72", "200"],
    ["377", "73", "346"],
    ["460", "09", "180"],
    ["580", "37", "449"],
    ["457", "64", "789"]
  ],
  "10/04/2023 to 16/04/2023": [
    ["146", "67", "240"],
    ["245", "93", "158"],
    ["367", "14", "356"],
    ["390", "70", "217"],
    ["214", "86", "468"],
    ["580", "47", "191"]
  ],
  "17/04/2023 to 23/04/2023": [
    ["240", "30", "500"],
    ["569", "56", "246"],
    ["378", "80", "117"],
    ["667", "09", "150"],
    ["458", "83", "284"],
    ["500", "21", "270"]
  ],
  "24/04/2023 to 30/04/2023": [
    ["470", "99", "221"],
    ["568", "60", "278"],
    ["354", "52", "220"],
    ["457", "07", "124"],
    ["132", "74", "388"],
    ["569", "81", "137"]
  ],
  "01/05/2023 to 07/05/2023": [
    ["460", "19", "400"],
    ["122", "79", "250"],
    ["259", "10", "568"],
    ["500", "37", "482"],
    ["369", "88", "130"],
    ["578", "69", "274"]
  ],
  "08/05/2023 to 14/05/2023": [
    ["380", "64", "190"],
    ["490", "59", "278"],
    ["590", "36", "156"],
    ["123", "77", "390"],
    ["468", "84", "230"],
    ["456", "69", "314"]
  ],
  "15/05/2023 to 21/05/2023": [
    ["350", "82", "418"],
    ["486", "90", "320"],
    ["459", "72", "138"],
    ["572", "66", "214"],
    ["634", "49", "137"],
    ["468", "58", "244"]
  ],
  "22/05/2023 to 28/05/2023": [
    ["246", "91", "279"],
    ["579", "62", "379"],
    ["360", "53", "350"],
    ["420", "89", "286"],
    ["470", "76", "448"],
    ["235", "40", "229"]
  ],
  "29/05/2023 to 04/06/2023": [
    ["510", "55", "370"],
    ["250", "70", "310"],
    ["366", "83", "489"],
    ["549", "98", "226"],
    ["468", "69", "369"],
    ["578", "80", "247"]
  ],
  "05/06/2023 to 11/06/2023": [
    ["200", "64", "498"],
    ["189", "80", "470"],
    ["370", "29", "379"],
    ["478", "89", "236"],
    ["580", "92", "149"],
    ["489", "74", "269"]
  ],
  "12/06/2023 to 18/06/2023": [
    ["420", "84", "350"],
    ["250", "45", "309"],
    ["590", "92", "276"],
    ["144", "55", "280"],
    ["270", "68", "122"],
    ["567", "80", "140"]
  ],
  "19/06/2023 to 25/06/2023": [
    ["368", "63", "280"],
    ["230", "79", "299"],
    ["480", "96", "317"],
    ["578", "65", "229"],
    ["340", "45", "489"],
    ["199", "62", "380"]
  ],
  "26/06/2023 to 02/07/2023": [
    ["590", "80", "290"],
    ["128", "60", "480"],
    ["250", "94", "367"],
    ["580", "91", "237"],
    ["459", "56", "190"],
    ["498", "77", "258"]
  ],
  "03/07/2023 to 09/07/2023": [
    ["320", "99", "400"],
    ["380", "66", "278"],
    ["590", "83", "126"],
    ["590", "92", "170"],
    ["200", "81", "340"],
    ["166", "72", "156"]
  ],
  "10/07/2023 to 16/07/2023": [
    ["458", "77", "365"],
    ["233", "95", "490"],
    ["369", "89", "225"],
    ["489", "96", "158"],
    ["350", "62", "319"],
    ["279", "90", "550"]
  ],
  "17/07/2023 to 23/07/2023": [
    ["568", "94", "237"],
    ["279", "84", "401"],
    ["154", "72", "298"],
    ["489", "66", "340"],
    ["556", "82", "289"],
    ["366", "95", "252"]
  ],
  "24/07/2023 to 30/07/2023": [
    ["278", "89", "490"],
    ["559", "56", "373"],
    ["278", "78", "415"],
    ["489", "69", "280"],
    ["190", "82", "240"],
    ["560", "99", "289"]
  ],
  "31/07/2023 to 06/08/2023": [
    ["324", "67", "348"],
    ["490", "95", "268"],
    ["288", "85", "365"],
    ["245", "71", "401"],
    ["378", "56", "299"],
    ["289", "48", "360"]
  ],
  "07/08/2023 to 13/08/2023": [
    ["589", "25", "690"],
    ["250", "77", "467"],
    ["399", "10", "235"],
    ["380", "10", "460"],
    ["279", "87", "467"],
    ["580", "32", "147"]
  ],
  "14/08/2023 to 20/08/2023": [
    ["134", "86", "178"],
    ["589", "25", "690"],
    ["138", "28", "990"],
    ["590", "48", "125"],
    ["156", "25", "249"],
    ["116", "84", "680"]
  ],
  "21/08/2023 to 27/08/2023": [
    ["245", "93", "158"],
    ["248", "44", "789"],
    ["133", "79", "469"],
    ["229", "35", "456"],
    ["113", "59", "360"],
    ["470", "11", "146"]
  ],
  "28/08/2023 to 03/09/2023": [
    ["689", "35", "799"],
    ["378", "82", "246"],
    ["134", "87", "160"],
    ["690", "54", "130"],
    ["139", "31", "489"],
    ["139", "31", "236"]
  ],
  "04/09/2023 to 10/09/2023": [
    ["250", "76", "178"],
    ["590", "40", "460"],
    ["489", "13", "256"],
    ["150", "62", "237"],
    ["247", "32", "480"],
    ["180", "97", "377"]
  ],
  "11/09/2023 to 17/09/2023": [
    ["357", "59", "234"],
    ["158", "43", "247"],
    ["146", "11", "128"],
    ["279", "84", "167"],
    ["158", "43", "247"],
    ["458", "78", "369"]
  ],
  "18/09/2023 to 24/09/2023": [
    ["690", "57", "278"],
    ["114", "64", "680"],
    ["137", "16", "600"],
    ["350", "80", "127"],
    ["116", "81", "128"],
    ["123", "64", "680"]
  ],
  "25/09/2023 to 01/10/2023": [
    ["158", "46", "178"],
    ["489", "16", "790"],
    ["469", "92", "390"],
    ["119", "11", "335"],
    ["279", "85", "258"],
    ["150", "60", "460"]
  ],
  "02/10/2023 to 08/10/2023": [
    ["170", "80", "299"],
    ["260", "85", "140"],
    ["799", "53", "346"],
    ["459", "84", "347"],
    ["130", "40", "235"],
    ["180", "94", "167"]
  ],
  "09/10/2023 to 15/10/2023": [
    ["489", "16", "457"],
    ["469", "92", "390"],
    ["680", "45", "447"],
    ["279", "85", "258"],
    ["180", "94", "167"],
    ["224", "87", "377"]
  ],
  "16/10/2023 to 22/10/2023": [
    ["889", "56", "268"],
    ["390", "27", "467"],
    ["137", "18", "134"],
    ["227", "13", "599"],
    ["467", "79", "117"],
    ["235", "00", "569"]
  ],
  "23/10/2023 to 29/10/2023": [
    ["889", "56", "268"],
    ["390", "27", "467"],
    ["668", "**", "560"],
    ["157", "36", "259"],
    ["467", "77", "340"],
    ["478", "98", "170"]
  ],
  "30/10/2023 to 05/11/2023": [
    ["167", "44", "789"],
    ["478", "98", "170"],
    ["235", "**", "345"],
    ["458", "78", "990"],
    ["700", "71", "470"],
    ["349", "67", "278"]
  ],
  "06/11/2023 to 12/11/2023": [
    ["680", "47", "179"],
    ["580", "38", "378"],
    ["228", "26", "349"],
    ["190", "00", "127"],
    ["114", "69", "126"],
    ["349", "67", "278"]
  ],
  "13/11/2023 to 19/11/2023": [
    ["889", "56", "268"],
    ["390", "27", "467"],
    ["248", "42", "228"],
    ["690", "59", "234"],
    ["126", "99", "144"],
    ["259", "67", "377"]
  ],
  "20/11/2023 to 26/11/2023": [
    ["269", "78", "125"],
    ["290", "17", "115"],
    ["340", "71", "245"],
    ["500", "50", "280"],
    ["447", "59", "667"],
    ["248", "42", "228"]
  ],
  "27/11/2023 to 03/12/2023": [
    ["778", "21", "470"],
    ["356", "44", "220"],
    ["690", "59", "234"],
    ["259", "67", "377"],
    ["114", "69", "126"],
    ["139", "34", "220"]
  ],
  "04/12/2023 to 10/12/2023": [
    ["448", "68", "477"],
    ["337", "35", "690"],
    ["290", "11", "489"],
    ["126", "99", "144"],
    ["259", "67", "377"],
    ["114", "69", "126"]
  ],
  "11/12/2023 to 17/12/2023": [
    ["120", "33", "346"],
    ["378", "81", "245"],
    ["115", "72", "390"],
    ["670", "37", "890"],
    ["199", "99", "289"],
    ["348", "54", "789"]
  ],
  "18/12/2023 to 24/12/2023": [
    ["780", "50", "569"],
    ["670", "37", "890"],
    ["477", "84", "400"],
    ["248", "41", "245"],
    ["800", "84", "257"],
    ["119", "18", "350"]
  ],
  "12/25/2023 to 12/31/2023": [
    ["670", "37", "890"],
    ["234", "92", "156"],
    ["167", "40", "190"],
    ["156", "22", "390"],
    ["349", "65", "348"],
    ["156", "27", "359"]
  ],
  "01/01/2024 to 01/07/2024": [
    ["146", "13", "166"],
    ["580", "36", "240"],
    ["670", "35", "348"],
    ["249", "59", "225"],
    ["128", "17", "269"],
    ["477", "84", "400"]
  ],
  "01/08/2024 to 01/14/2024": [
    ["689", "35", "348"],
    ["477", "84", "400"],
    ["446", "47", "340"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["158", "40", "280"]
  ],
  "01/15/2024 to 01/21/2024": [
    ["369", "83", "670"],
    ["179", "76", "448"],
    ["370", "00", "578"],
    ["140", "56", "457"],
    ["290", "11", "560"],
    ["356", "44", "257"]
  ],
  "01/22/2024 to 01/28/2024": [
    ["119", "17", "449"],
    ["558", "85", "177"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["370", "00", "578"],
    ["238", "37", "467"]
  ],
  "29/01/2024 to 04/02/2024": [
    ["470", "14", "446"],
    ["166", "32", "679"],
    ["167", "44", "347"],
    ["134", "86", "169"],
    ["238", "37", "467"],
    ["560", "13", "670"]
  ],
  "05/02/2024 to 11/02/2024": [
    ["126", "97", "188"],
    ["700", "73", "157"],
    ["227", "16", "330"],
    ["223", "79", "180"],
    ["380", "10", "668"],
    ["347", "43", "148"]
  ],
  "12/02/2024 to 18/02/2024": [
    ["169", "65", "889"],
    ["113", "54", "356"],
    ["790", "68", "224"],
    ["457", "67", "359"],
    ["149", "42", "570"],
    ["347", "46", "240"]
  ],
  "19/02/2024 to 25/02/2024": [
    ["350", "80", "299"],
    ["557", "71", "344"],
    ["689", "34", "149"],
    ["270", "94", "888"],
    ["119", "16", "259"],
    ["169", "65", "889"]
  ],

  "01/01/2024 to 01/07/2024": [
    ["146", "13", "166"],
    ["580", "36", "240"],
    ["670", "35", "348"],
    ["249", "59", "225"],
    ["128", "17", "269"],
    ["477", "84", "400"]
  ],
  "01/08/2024 to 01/14/2024": [
    ["689", "35", "348"],
    ["477", "84", "400"],
    ["446", "47", "340"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["158", "40", "280"]
  ],
  "01/15/2024 to 01/21/2024": [
    ["369", "83", "670"],
    ["179", "76", "448"],
    ["370", "00", "578"],
    ["140", "56", "457"],
    ["290", "11", "560"],
    ["356", "44", "257"]
  ],
  "01/22/2024 to 01/28/2024": [
    ["119", "17", "449"],
    ["558", "85", "177"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["370", "00", "578"],
    ["238", "37", "467"]
  ],
  "29/01/2024 to 04/02/2024": [
    ["470", "14", "446"],
    ["166", "32", "679"],
    ["167", "44", "347"],
    ["134", "86", "169"],
    ["238", "37", "467"],
    ["560", "13", "670"]
  ],
  "05/02/2024 to 11/02/2024": [
    ["126", "97", "188"],
    ["700", "73", "157"],
    ["227", "16", "330"],
    ["223", "79", "180"],
    ["380", "10", "668"],
    ["347", "43", "148"]
  ],
  "12/02/2024 to 18/02/2024": [
    ["169", "65", "889"],
    ["113", "54", "356"],
    ["790", "68", "224"],
    ["457", "67", "359"],
    ["149", "42", "570"],
    ["347", "46", "240"]
  ],
  "19/02/2024 to 25/02/2024": [
    ["350", "80", "299"],
    ["557", "71", "344"],
    ["689", "34", "149"],
    ["270", "94", "888"],
    ["119", "16", "259"],
    ["169", "65", "889"]
  ],
  "26/02/2024 to 03/03/2024": [
    ["349", "65", "159"],
    ["380", "10", "668"],
    ["347", "43", "148"],
    ["138", "24", "356"],
    ["170", "84", "149"],
    ["689", "34", "149"]
  ],
  "04/03/2024 to 10/03/2024": [
    ["379", "94", "167"],
    ["267", "59", "144"],
    ["179", "72", "200"],
    ["458", "71", "146"],
    ["369", "80", "370"],
    ["779", "30", "569"]
  ],
  "11/03/2024 to 17/03/2024": [
    ["469", "96", "178"],
    ["249", "50", "280"],
    ["170", "89", "478"],
    ["250", "79", "234"],
    ["449", "71", "344"],
    ["124", "74", "130"]
  ],
  "18/03/2024 to 24/03/2024": [
    ["189", "80", "145"],
    ["358", "61", "146"],
    ["149", "40", "118"],
    ["236", "12", "246"],
    ["124", "74", "130"],
    ["258", "59", "559"]
  ],
  "25/03/2024 to 31/03/2024": [
    ["148", "37", "250"],
    ["467", "71", "290"],
    ["160", "74", "257"],
    ["148", "37", "250"],
    ["148", "33", "148"],
    ["130", "49", "360"]
  ],
  "01/04/2024 to 07/04/2024": [
    ["480", "24", "239"],
    ["238", "34", "149"],
    ["140", "53", "670"],
    ["155", "12", "499"],
    ["138", "29", "577"],
    ["148", "37", "250"]
  ],


  "01/01/2024 to 01/07/2024": [
    ["146", "13", "166"],
    ["580", "36", "240"],
    ["670", "35", "348"],
    ["249", "59", "225"],
    ["128", "17", "269"],
    ["477", "84", "400"]
  ],
  "01/08/2024 to 01/14/2024": [
    ["689", "35", "348"],
    ["477", "84", "400"],
    ["446", "47", "340"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["158", "40", "280"]
  ],
  "01/15/2024 to 01/21/2024": [
    ["369", "83", "670"],
    ["179", "76", "448"],
    ["370", "00", "578"],
    ["140", "56", "457"],
    ["290", "11", "560"],
    ["356", "44", "257"]
  ],
  "01/22/2024 to 01/28/2024": [
    ["119", "17", "449"],
    ["558", "85", "177"],
    ["556", "61", "146"],
    ["156", "23", "670"],
    ["370", "00", "578"],
    ["238", "37", "467"]
  ],
  "29/01/2024 to 04/02/2024": [
    ["470", "14", "446"],
    ["166", "32", "679"],
    ["167", "44", "347"],
    ["134", "86", "169"],
    ["238", "37", "467"],
    ["560", "13", "670"]
  ],
  "05/02/2024 to 11/02/2024": [
    ["126", "97", "188"],
    ["700", "73", "157"],
    ["227", "16", "330"],
    ["223", "79", "180"],
    ["380", "10", "668"],
    ["347", "43", "148"]
  ],
  "12/02/2024 to 18/02/2024": [
    ["169", "65", "889"],
    ["113", "54", "356"],
    ["790", "68", "224"],
    ["457", "67", "359"],
    ["149", "42", "570"],
    ["347", "46", "240"]
  ],
  "19/02/2024 to 25/02/2024": [
    ["350", "80", "299"],
    ["557", "71", "344"],
    ["689", "34", "149"],
    ["270", "94", "888"],
    ["119", "16", "259"],
    ["169", "65", "889"]
  ],
  "26/02/2024 to 03/03/2024": [
    ["349", "65", "159"],
    ["380", "10", "668"],
    ["347", "43", "148"],
    ["138", "24", "356"],
    ["170", "84", "149"],
    ["689", "34", "149"]
  ],
  "04/03/2024 to 10/03/2024": [
    ["379", "94", "167"],
    ["267", "59", "144"],
    ["179", "72", "200"],
    ["458", "71", "146"],
    ["369", "80", "370"],
    ["779", "30", "569"]
  ],
  "11/03/2024 to 17/03/2024": [
    ["469", "96", "178"],
    ["249", "50", "280"],
    ["170", "89", "478"],
    ["250", "79", "234"],
    ["449", "71", "344"],
    ["124", "74", "130"]
  ],
  "18/03/2024 to 24/03/2024": [
    ["189", "80", "145"],
    ["358", "61", "146"],
    ["149", "40", "118"],
    ["236", "12", "246"],
    ["124", "74", "130"],
    ["258", "59", "559"]
  ],
  "25/03/2024 to 31/03/2024": [
    ["148", "37", "250"],
    ["467", "71", "290"],
    ["160", "74", "257"],
    ["148", "37", "250"],
    ["148", "33", "148"],
    ["130", "49", "360"]
  ],
  "01/04/2024 to 07/04/2024": [
    ["480", "24", "239"],
    ["238", "34", "149"],
    ["140", "53", "670"],
    ["155", "12", "499"],
    ["138", "29", "577"],
    ["148", "37", "250"]
  ],
  "08/04/2024 to 14/04/2024": [
    ["155", "15", "159"],
    ["247", "38", "279"],
    ["137", "19", "559"],
    ["390", "27", "467"],
    ["270", "95", "230"],
    ["359", "70", "127"]
  ],
  "15/04/2024 to 21/04/2024": [
    ["347", "40", "127"],
    ["458", "77", "124"],
    ["234", "96", "169"],
    ["110", "24", "158"],
    ["278", "77", "179"],
    ["456", "55", "357"]
  ],
  "22/04/2024 to 28/04/2024": [
    ["250", "70", "280"],
    ["779", "30", "334"],
    ["246", "28", "279"],
    ["489", "12", "200"],
    ["399", "13", "337"],
    ["448", "69", "126"]
  ],
  "29/04/2024 to 05/05/2024": [
    ["157", "30", "677"],
    ["189", "85", "140"],
    ["125", "89", "568"],
    ["245", "12", "480"],
    ["268", "64", "347"],
    ["169", "63", "670"]
  ],
  "06/05/2024 to 12/05/2024": [
    ["689", "30", "244"],
    ["135", "91", "290"],
    ["255", "25", "357"],
    ["380", "14", "356"],
    ["133", "72", "200"],
    ["140", "53", "670"]
  ],
  "13/05/2024 to 19/05/2024": [
    ["469", "95", "339"],
    ["790", "64", "590"],
    ["279", "87", "133"],
    ["137", "17", "890"],
    ["359", "77", "124"],
    ["489", "17", "467"]
  ],
  "20/05/2024 to 26/05/2024": [
    ["360", "99", "289"],
    ["500", "59", "135"],
    ["150", "68", "369"],
    ["558", "84", "347"],
    ["445", "31", "146"],
    ["380", "14", "699"]
  ],



  "27/05/2024 to 02/06/2024": [
    ["599", "30", "677"],
    ["670", "35", "456"],
    ["118", "02", "147"],
    ["499", "22", "255"],
    ["169", "60", "460"],
    ["179", "73", "139"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["169", "69", "450"],
    ["470", "16", "244"],
    ["250", "78", "369"],
    ["356", "45", "140"],
    ["257", "49", "126"],
    ["247", "31", "777"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["278", "71", "489"],
    ["238", "31", "335"],
    ["466", "63", "247"],
    ["359", "78", "350"],
    ["590", "49", "117"],
    ["567", "82", "138"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["289", "91", "380"],
    ["129", "24", "248"],
    ["157", "30", "190"],
    ["456", "53", "788"],
    ["157", "36", "178"],
    ["245", "12", "660"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["679", "20", "190"],
    ["388", "92", "228"],
    ["556", "62", "237"],
    ["199", "95", "456"],
    ["159", "52", "237"],
    ["249", "56", "457"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["460", "06", "349"],
    ["400", "43", "788"],
    ["280", "05", "348"],
    ["333", "92", "589"],
    ["788", "34", "455"],
    ["477", "84", "400"],
  ],
};

export const MADHURDAY = {
  "12/26/2022 to 01/01/2023": [
    ["270", "92", "679"],
    ["337", "32", "246"],
    ["148", "37", "557"],
    ["235", "00", "370"],
    ["499", "20", "569"],
    ["269", "77", "179"],
    ["337", "32", "246"],
  ],
  "01/02/2023 to 01/08/2023": [
    ["450", "90", "145"],
    ["146", "15", "690"],
    ["270", "98", "189"],
    ["289", "90", "370"],
    ["899", "67", "188"],
    ["140", "51", "137"],
    ["579", "14", "680"],
  ],

  "01/09/2023 to 01/15/2023": [
    ["166", "32", "336"],
    ["700", "77", "359"],
    ["199", "93", "300"],
    ["134", "89", "117"],
    ["148", "38", "189"],
    ["337", "32", "246"],
    ["148", "37", "557"],
  ],

  "01/16/2023 to 01/22/2023": [
    ["236", "19", "117"],
    ["228", "27", "368"],
    ["179", "79", "568"],
    ["255", "24", "220"],
    ["350", "82", "660"],
    ["668", "05", "258"],
    ["345", "28", "369"],
  ],

  "01/23/2023 to 01/29/2023": [
    ["190", "03", "247"],
    ["460", "00", "370"],
    ["129", "23", "139"],
    ["499", "20", "569"],
    ["556", "63", "779"],
    ["269", "77", "179"],
    ["367", "66", "268"],
  ],

  "01/30/2023 to 02/05/2023": [
    ["566", "78", "233"],
    ["890", "70", "136"],
    ["800", "88", "468"],
    ["134", "84", "347"],
    ["357", "56", "268"],
    ["225", "91", "489"],
    ["449", "74", "770"],
  ],

  "02/06/2023 to 02/12/2023": [
    ["268", "67", "700"],
    ["469", "95", "122"],
    ["380", "14", "400"],
    ["235", "00", "370"],
    ["459", "88", "170"],
    ["499", "20", "569"],
    ["790", "61", "560"],
  ],

  "02/13/2023 to 02/19/2023": [
    ["577", "91", "137"],
    ["370", "00", "578"],
    ["347", "45", "177"],
    ["367", "65", "168"],
    ["457", "65", "348"],
    ["579", "15", "456"],
    ["770", "42", "679"],
  ],

  "02/20/2023 to 02/26/2023": [
    ["233", "84", "770"],
    ["360", "97", "124"],
    ["368", "77", "223"],
    ["456", "59", "234"],
    ["357", "56", "448"],
    ["599", "36", "169"],
    ["359", "70", "370"],
  ],

  "02/27/2023 to 03/05/2023": [
    ["367", "69", "289"],
    ["567", "82", "390"],
    ["267", "58", "125"],
    ["170", "89", "135"],
    ["137", "17", "188"],
    ["256", "30", "488"],
    ["459", "84", "347"],
  ],

  "03/06/2023 to 03/12/2023": [
    ["460", "02", "480"],
    ["114", "68", "233"],
    ["256", "30", "488"],
    ["560", "13", "689"],
    ["360", "99", "333"],
    ["449", "75", "159"],
    ["380", "17", "160"],
  ],

  "03/13/2023 to 03/19/2023": [
    ["350", "80", "460"],
    ["159", "55", "177"],
    ["366", "52", "147"],
    ["110", "24", "789"],
    ["389", "09", "469"],
    ["146", "13", "139"],
    ["156", "24", "130"],
  ],

  "03/20/2023 to 03/26/2023": [
    ["229", "35", "249"],
    ["679", "25", "366"],
    ["139", "32", "156"],
    ["789", "48", "369"],
    ["229", "37", "124"],
    ["349", "60", "578"],
    ["147", "29", "126"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["166", "35", "159"],
    ["368", "72", "200"],
    ["348", "53", "599"],
    ["259", "65", "267"],
    ["145", "04", "220"],
    ["569", "02", "336"],
    ["345", "27", "340"],
  ],

  "04/03/2023 to 04/09/2023": [
    ["479", "04", "455"],
    ["699", "40", "136"],
    ["789", "49", "568"],
    ["690", "52", "480"],
    ["280", "08", "189"],
    ["125", "80", "389"],
    ["138", "24", "239"],
  ],

  "04/10/2023 to 04/16/2023": [
    ["279", "86", "169"],
    ["556", "61", "470"],
    ["227", "17", "124"],
    ["388", "95", "140"],
    ["169", "69", "360"],
    ["180", "93", "148"],
    ["450", "97", "458"],
  ],

  "04/17/2023 to 04/23/2023": [
    ["359", "76", "457"],
    ["578", "03", "139"],
    ["670", "35", "780"],
    ["120", "34", "699"],
    ["569", "02", "255"],
    ["245", "19", "568"],
    ["234", "93", "689"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["200", "29", "199"],
    ["569", "03", "247"],
    ["335", "10", "136"],
    ["250", "71", "489"],
    ["480", "28", "468"],
    ["340", "77", "467"],
    ["349", "65", "249"],
  ],

  "05/01/2023 to 05/07/2023": [
    ["367", "60", "190"],
    ["380", "13", "490"],
    ["229", "39", "388"],
    ["344", "17", "467"],
    ["588", "12", "156"],
    ["112", "42", "237"],
    ["158", "42", "660"],
  ],

  "05/08/2023 to 05/14/2023": [
    ["238", "33", "157"],
    ["579", "12", "679"],
    ["689", "33", "247"],
    ["456", "53", "670"],
    ["226", "04", "590"],
    ["579", "16", "150"],
    ["356", "41", "399"],
  ],

  "05/15/2023 to 05/21/2023": [
    ["148", "34", "130"],
    ["260", "86", "330"],
    ["148", "32", "246"],
    ["235", "08", "567"],
    ["239", "42", "570"],
    ["256", "30", "136"],
    ["889", "56", "790"],
  ],

  "05/22/2023 to 05/28/2023": [
    ["136", "07", "377"],
    ["479", "02", "336"],
    ["670", "35", "168"],
    ["128", "19", "126"],
    ["224", "80", "370"],
    ["127", "09", "234"],
    ["579", "12", "778"],
  ],

  "05/29/2023 to 06/04/2023": [
    ["117", "96", "169"],
    ["123", "64", "455"],
    ["779", "39", "469"],
    ["233", "81", "560"],
    ["246", "24", "248"],
    ["390", "28", "468"],
    ["780", "51", "777"],
  ],

  "06/05/2023 to 06/11/2023": [
    ["223", "70", "578"],
    ["160", "79", "559"],
    ["149", "42", "660"],
    ["469", "97", "133"],
    ["247", "39", "559"],
    ["166", "32", "480"],
    ["128", "15", "348"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["679", "27", "467"],
    ["679", "21", "128"],
    ["244", "07", "278"],
    ["337", "30", "226"],
    ["445", "31", "380"],
    ["379", "95", "500"],
    ["269", "73", "670"],
  ],

  "06/19/2023 to 06/25/2023": [
    ["489", "15", "267"],
    ["157", "37", "160"],
    ["233", "89", "234"],
    ["356", "41", "236"],
    ["114", "63", "580"],
    ["148", "39", "117"],
    ["440", "86", "150"],
  ],

  "06/26/2023 to 07/02/2023": [
    ["478", "91", "588"],
    ["135", "95", "113"],
    ["679", "22", "499"],
    ["259", "62", "129"],
    ["110", "22", "156"],
    ["458", "79", "135"],
    ["147", "28", "125"],
  ],

  "07/03/2023 to 07/09/2023": [
    ["338", "46", "123"],
    ["689", "37", "890"],
    ["356", "44", "167"],
    ["450", "99", "568"],
    ["260", "80", "479"],
    ["990", "84", "789"],
    ["280", "08", "260"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["679", "29", "180"],
    ["470", "17", "133"],
    ["460", "03", "580"],
    ["240", "67", "160"],
    ["138", "25", "159"],
    ["158", "40", "578"],
    ["268", "61", "560"],
  ],

  "07/17/2023 to 07/23/2023": [
    ["360", "98", "125"],
    ["790", "61", "470"],
    ["268", "68", "440"],
    ["224", "88", "125"],
    ["478", "99", "450"],
    ["600", "67", "124"],
    ["460", "09", "469"],
  ],

  "07/24/2023 to 07/30/2023": [
    ["128", "19", "900"],
    ["456", "52", "499"],
    ["799", "52", "589"],
    ["567", "82", "679"],
    ["667", "97", "160"],
    ["130", "42", "147"],
    ["278", "78", "170"],
  ],

  "07/31/2023 to 08/06/2023": [
    ["890", "79", "450"],
    ["169", "61", "137"],
    ["589", "23", "238"],
    ["778", "20", "244"],
    ["378", "88", "350"],
    ["690", "51", "489"],
    ["447", "51", "290"],
  ],

  "08/07/2023 to 08/13/2023": [
    ["278", "73", "346"],
    ["899", "66", "240"],
    ["489", "15", "555"],
    ["128", "10", "136"],
    ["290", "12", "147"],
    ["220", "43", "689"],
    ["367", "69", "126"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["278", "73", "346"],
    ["899", "66", "240"],
    ["489", "15", "555"],
    ["128", "10", "136"],
    ["290", "12", "147"],
    ["220", "43", "689"],
    ["367", "69", "126"],
  ],

  "08/14/2023 to 08/20/2023": [
    ["336", "22", "345"],
    ["256", "30", "488"],
    ["489", "10", "668"],
    ["880", "69", "577"],
    ["466", "67", "278"],
    ["156", "23", "148"],
    ["457", "67", "250"],
  ],

  "08/21/2023 to 08/27/2023": [
    ["180", "96", "466"],
    ["388", "94", "789"],
    ["790", "66", "123"],
    ["677", "00", "136"],
    ["478", "93", "238"],
    ["136", "02", "660"],
    ["140", "59", "469"],
  ],

  "08/28/2023 to 09/03/2023": [
    ["688", "27", "133"],
    ["112", "40", "550"],
    ["459", "82", "246"],
    ["456", "54", "590"],
    ["239", "43", "148"],
    ["112", "46", "240"],
    ["688", "20", "226"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["559", "92", "589"],
    ["288", "89", "234"],
    ["236", "17", "890"],
    ["489", "17", "890"],
    ["440", "85", "780"],
    ["160", "79", "900"],
    ["350", "88", "369"],
  ],

  "09/11/2023 to 09/17/2023": [
    ["670", "31", "137"],
    ["179", "77", "467"],
    ["159", "53", "148"],
    ["456", "58", "224"],
    ["900", "93", "346"],
    ["377", "71", "470"],
    ["578", "03", "120"],
  ],

  "09/18/2023 to 09/24/2023": [
    ["370", "05", "348"],
    ["277", "60", "244"],
    ["258", "59", "126"],
    ["135", "92", "570"],
    ["144", "97", "458"],
    ["480", "22", "237"],
    ["255", "29", "234"],
  ],

  "09/25/2023 to 10/01/2023": [
    ["569", "01", "100"],
    ["568", "93", "490"],
    ["235", "08", "125"],
    ["789", "42", "129"],
    ["137", "14", "239"],
    ["268", "67", "160"],
    ["267", "54", "356"],
  ],

  "10/02/2023 to 10/08/2023": [
    ["669", "15", "159"],
    ["678", "14", "220"],
    ["336", "21", "155"],
    ["170", "86", "349"],
    ["146", "13", "779"],
    ["780", "56", "268"],
    ["579", "13", "148"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["118", "06", "448"],
    ["239", "42", "228"],
    ["345", "28", "125"],
    ["249", "58", "125"],
    ["680", "48", "170"],
    ["114", "69", "135"],
    ["139", "34", "248"],
  ],

  "10/16/2023 to 10/22/2023": [
    ["145", "06", "448"],
    ["333", "93", "157"],
    ["168", "54", "356"],
    ["379", "91", "335"],
    ["357", "59", "667"],
    ["688", "24", "446"],
    ["679", "23", "355"],
  ],

  "10/23/2023 to 10/29/2023": [
    ["389", "06", "169"],
    ["455", "42", "147"],
    ["149", "41", "128"],
    ["356", "48", "125"],
    ["235", "02", "156"],
    ["260", "80", "550"],
    ["347", "46", "259"],
  ],

  "10/30/2023 to 11/05/2023": [
    ["190", "04", "680"],
    ["169", "64", "356"],
    ["178", "60", "280"],
    ["570", "26", "240"],
    ["166", "35", "500"],
    ["170", "85", "799"],
    ["458", "70", "460"],
  ],

  "11/06/2023 to 11/12/2023": [
    ["699", "43", "779"],
    ["123", "68", "567"],
    ["334", "07", "250"],
    ["166", "37", "179"],
    ["256", "30", "488"],
    ["178", "60", "280"],
    ["360", "91", "470"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["178", "60", "280"],
    ["257", "45", "267"],
    ["160", "70", "479"],
    ["899", "67", "700"],
    ["457", "68", "116"],
    ["279", "81", "146"],
    ["289", "95", "348"],
  ],

  "11/20/2023 to 11/26/2023": [
    ["359", "71", "245"],
    ["489", "11", "470"],
    ["679", "24", "789"],
    ["124", "74", "149"],
    ["125", "86", "358"],
    ["566", "78", "440"],
    ["360", "91", "470"],
  ],

  "11/27/2023 to 12/03/2023": [
    ["688", "23", "670"],
    ["468", "88", "459"],
    ["370", "07", "269"],
    ["347", "49", "379"],
    ["257", "45", "267"],
    ["160", "70", "479"],
    ["577", "90", "127"],
  ],

  "12/04/2023 to 12/10/2023": [
    ["455", "49", "225"],
    ["357", "56", "790"],
    ["138", "22", "255"],
    ["225", "91", "245"],
    ["279", "81", "146"],
    ["789", "44", "112"],
    ["390", "20", "370"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["237", "22", "147"],
    ["899", "67", "700"],
    ["457", "68", "116"],
    ["368", "72", "129"],
    ["468", "81", "669"],
    ["669", "12", "570"],
    ["260", "86", "367"],
  ],

  "12/18/2023 to 12/24/2023": [
    ["478", "98", "224"],
    ["447", "50", "136"],
    ["225", "95", "456"],
    ["145", "08", "468"],
    ["145", "03", "238"],
    ["289", "95", "348"],
    ["126", "95", "258"],
  ],

  "12/25/2023 to 12/31/2023": [
    ["489", "15", "230"],
    ["235", "05", "258"],
    ["167", "42", "156"],
    ["110", "21", "146"],
    ["138", "20", "668"],
    ["378", "82", "679"],
    ["227", "11", "137"],
  ],

  "01/01/2024 to 01/07/2024": [
    ["568", "93", "300"],
    ["233", "82", "480"],
    ["130", "44", "590"],
    ["790", "69", "667"],
    ["457", "60", "235"],
    ["129", "29", "450"],
    ["277", "64", "130"],
  ],

  "01/08/2024 to 01/14/2024": [
    ["188", "75", "339"],
    ["188", "73", "148"],
    ["170", "88", "134"],
    ["289", "95", "168"],
    ["190", "01", "678"],
    ["778", "21", "137"],
    ["467", "76", "457"],
  ],

  "01/15/2024 to 01/21/2024": [
    ["560", "12", "688"],
    ["288", "85", "889"],
    ["137", "16", "349"],
    ["156", "23", "166"],
    ["158", "42", "147"],
    ["280", "01", "146"],
    ["157", "38", "260"],
  ],

  "01/22/2024 to 01/28/2024": [
    ["000", "01", "245"],
    ["445", "30", "244"],
    ["450", "91", "155"],
    ["568", "97", "890"],
    ["277", "64", "130"],
    ["590", "40", "145"],
    ["356", "48", "279"],
  ],

  "01/29/2024 to 02/04/2024": [
    ["669", "14", "149"],
    ["599", "32", "246"],
    ["137", "14", "356"],
    ["347", "47", "250"],
    ["349", "66", "790"],
    ["116", "83", "490"],
    ["155", "10", "136"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["350", "88", "990"],
    ["499", "27", "133"],
    ["589", "21", "470"],
    ["336", "24", "347"],
    ["456", "59", "289"],
    ["190", "09", "469"],
    ["336", "27", "278"],
  ],

  "02/12/2024 to 02/18/2024": [
    ["377", "72", "480"],
    ["367", "63", "689"],
    ["579", "10", "190"],
    ["299", "08", "288"],
    ["345", "24", "257"],
    ["369", "81", "137"],
    ["157", "35", "780"],
  ],

  "02/19/2024 to 02/25/2024": [
    ["148", "31", "470"],
    ["125", "80", "389"],
    ["489", "17", "223"],
    ["356", "49", "360"],
    ["239", "42", "147"],
    ["260", "88", "369"],
    ["350", "86", "259"],
  ],

  "02/26/2024 to 03/03/2024": [
    ["247", "34", "239"],
    ["130", "44", "239"],
    ["346", "30", "479"],
    ["127", "05", "159"],
    ["350", "88", "279"],
    ["550", "02", "156"],
    ["367", "64", "248"],
  ],

  "03/04/2024 to 03/10/2024": [
    ["139", "34", "455"],
    ["560", "10", "460"],
    ["349", "62", "156"],
    ["690", "59", "180"],
    ["245", "19", "360"],
    ["667", "98", "233"],
    ["349", "66", "880"],
  ],

  "03/11/2024 to 03/17/2024": [
    ["369", "80", "370"],
    ["290", "16", "899"],
    ["457", "69", "234"],
    ["355", "32", "138"],
    ["126", "94", "158"],
    ["447", "56", "349"],
    ["112", "44", "356"],
  ],

  "03/18/2024 to 03/24/2024": [
    ["470", "11", "137"],
    ["790", "65", "140"],
    ["477", "80", "127"],
    ["678", "16", "259"],
    ["146", "13", "238"],
    ["890", "73", "166"],
    ["249", "54", "167"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["277", "64", "130"],
    ["360", "91", "137"],
    ["455", "41", "146"],
    ["479", "03", "580"],
    ["299", "09", "135"],
    ["289", "93", "670"],
    ["479", "07", "179"],
  ],

  "04/01/2024 to 04/07/2024": [
    ["267", "50", "190"],
    ["124", "71", "245"],
    ["160", "77", "223"],
    ["900", "94", "446"],
    ["990", "87", "269"],
    ["890", "77", "467"],
    ["134", "80", "235"],
  ],

  "04/08/2024 to 04/14/2024": [
    ["380", "13", "238"],
    ["249", "52", "147"],
    ["158", "47", "467"],
    ["589", "23", "111"],
    ["256", "32", "345"],
    ["349", "68", "990"],
    ["790", "64", "248"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["289", "94", "167"],
    ["245", "19", "199"],
    ["588", "10", "145"],
    ["228", "23", "346"],
    ["180", "92", "499"],
    ["800", "86", "600"],
    ["120", "39", "135"],
  ],

  "04/22/2024 to 04/28/2024": [
    ["299", "02", "679"],
    ["138", "25", "249"],
    ["349", "68", "800"],
    ["489", "16", "367"],
    ["479", "00", "677"],
    ["237", "24", "356"],
    ["359", "74", "347"],
  ],

  "04/29/2024 to 05/05/2024": [
    ["577", "94", "770"],
    ["266", "45", "249"],
    ["139", "38", "800"],
    ["112", "42", "138"],
    ["567", "87", "458"],
    ["579", "19", "379"],
    ["339", "51", "290"],
  ],

  "05/06/2024 to 05/12/2024": [
    ["269", "71", "399"],
    ["688", "21", "560"],
    ["118", "08", "459"],
    ["450", "97", "269"],
    ["356", "46", "899"],
    ["112", "44", "239"],
    ["578", "08", "567"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["377", "72", "570"],
    ["356", "47", "124"],
    ["226", "09", "568"],
    ["558", "86", "240"],
    ["178", "67", "700"],
    ["790", "65", "500"],
    ["389", "07", "188"],
  ],

  "05/20/2024 to 05/26/2024": [
    ["600", "65", "348"],
    ["188", "79", "117"],
    ["380", "12", "778"],
    ["156", "21", "678"],
    ["677", "06", "169"],
    ["133", "77", "359"],
    ["799", "51", "146"],
  ],

  "05/27/2024 to 06/02/2024": [
    ["290", "11", "128"],
    ["148", "31", "489"],
    ["125", "82", "147"],
    ["468", "82", "156"],
    ["357", "51", "344"],
    ["356", "46", "367"],
    ["125", "85", "690"],
  ],

  "06/03/2024 to 06/09/2024": [
    ["260", "87", "269"],
    ["350", "82", "570"],
    ["678", "12", "390"],
    ["490", "32", "480"],
    ["360", "91", "236"],
    ["239", "42", "679"],
    ["470", "14", "257"],
  ],

  "06/24/2024 to 06/30/2024": [
    ["478", "90", "569"],
    ["250", "71", "334"],
    ["457", "69", "234"],
    ["118", "08", "558"],
    ["125", "82", "688"],
    ["134", "81", "119"],
    ["268", "68", "480"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["688", "27", "566"],
    ["679", "23", "157"],
    ["130", "42", "480"],
    ["900", "99", "180"],
    ["367", "68", "468"],
    ["568", "97", "467"],
    ["250", "79", "289"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["578", "01", "334"],
    ["788", "36", "367"],
    ["129", "26", "556"],
    ["789", "48", "116"],
    ["389", "05", "357"],
    ["370", "08", "477"],
    ["499", "27", "278"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["680", "42", "138"],
    ["588", "10", "235"],
    ["500", "51", "470"],
    ["238", "30", "145"],
    ["466", "66", "244"],
    ["180", "93", "337"],
    ["237", "24", "149"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["249", "53", "670"],
    ["267", "58", "170"],
    ["190", "03", "580"],
    ["134", "81", "579"],
    ["800", "81", "588"],
    ["899", "63", "120"],
    ["459", "80", "226"],
  ],
};

export const MADHURNIGHT = {
  "01/02/2023 to 01/08/2023": [
    ["355", "32", "255"],
    ["450", "93", "490"],
    ["147", "26", "358"],
    ["450", "98", "369"],
    ["115", "77", "269"],
    ["124", "73", "238"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["335", "17", "467"],
    ["467", "76", "899"],
    ["234", "98", "567"],
    ["125", "88", "116"],
    ["190", "04", "239"],
    ["400", "47", "269"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["368", "72", "147"],
    ["899", "69", "180"],
    ["256", "31", "290"],
    ["146", "15", "122"],
    ["570", "20", "389"],
    ["399", "16", "268"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["489", "12", "589"],
    ["389", "01", "137"],
    ["100", "10", "460"],
    ["490", "36", "330"],
    ["190", "03", "148"],
    ["146", "15", "122"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["236", "12", "138"],
    ["169", "61", "335"],
    ["770", "45", "267"],
    ["338", "40", "479"],
    ["689", "38", "440"],
    ["455", "44", "239"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["457", "66", "790"],
    ["990", "88", "279"],
    ["356", "45", "447"],
    ["125", "86", "150"],
    ["299", "06", "556"],
    ["160", "71", "137"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["230", "50", "389"],
    ["239", "49", "270"],
    ["800", "84", "239"],
    ["168", "52", "246"],
    ["340", "71", "470"],
    ["480", "24", "167"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["237", "23", "580"],
    ["256", "31", "245"],
    ["488", "07", "890"],
    ["569", "05", "258"],
    ["346", "37", "124"],
    ["889", "50", "569"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["146", "16", "123"],
    ["477", "82", "138"],
    ["669", "15", "447"],
    ["158", "42", "688"],
    ["367", "62", "138"],
    ["130", "49", "667"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["146", "16", "123"],
    ["477", "82", "138"],
    ["238", "32", "129"],
    ["126", "90", "569"],
    ["458", "79", "180"],
    ["669", "15", "447"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["990", "83", "120"],
    ["458", "78", "369"],
    ["147", "25", "168"],
    ["377", "75", "799"],
    ["348", "59", "126"],
    ["350", "88", "459"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["389", "01", "669"],
    ["570", "25", "799"],
    ["446", "43", "670"],
    ["690", "58", "170"],
    ["170", "84", "130"],
    ["679", "21", "470"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["248", "40", "479"],
    ["258", "56", "259"],
    ["390", "20", "370"],
    ["130", "44", "356"],
    ["240", "62", "129"],
    ["356", "40", "677"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["389", "07", "700"],
    ["248", "45", "357"],
    ["158", "40", "136"],
    ["389", "04", "130"],
    ["360", "97", "890"],
    ["477", "80", "460"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["234", "95", "249"],
    ["559", "92", "679"],
    ["348", "51", "470"],
    ["115", "79", "379"],
    ["337", "31", "380"],
    ["249", "56", "349"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["380", "15", "113"],
    ["166", "32", "589"],
    ["133", "78", "350"],
    ["129", "22", "336"],
    ["677", "09", "478"],
    ["579", "13", "238"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["577", "97", "890"],
    ["225", "92", "660"],
    ["335", "16", "268"],
    ["357", "50", "578"],
    ["445", "33", "247"],
    ["780", "50", "226"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["260", "85", "168"],
    ["169", "67", "377"],
    ["134", "83", "120"],
    ["357", "54", "112"],
    ["478", "91", "489"],
    ["246", "20", "389"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["335", "18", "279"],
    ["368", "71", "155"],
    ["499", "22", "129"],
    ["690", "51", "155"],
    ["690", "55", "258"],
    ["458", "73", "670"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["368", "77", "223"],
    ["230", "58", "468"],
    ["239", "42", "237"],
    ["149", "42", "147"],
    ["146", "15", "456"],
    ["468", "87", "890"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["289", "91", "380"],
    ["115", "76", "240"],
    ["589", "20", "677"],
    ["460", "02", "778"],
    ["679", "23", "148"],
    ["168", "53", "148"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["478", "99", "117"],
    ["480", "25", "267"],
    ["789", "44", "590"],
    ["479", "05", "140"],
    ["330", "67", "133"],
    ["458", "77", "124"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["399", "12", "147"],
    ["669", "18", "170"],
    ["367", "63", "148"],
    ["279", "83", "300"],
    ["489", "17", "458"],
    ["224", "81", "470"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["237", "22", "345"],
    ["557", "73", "247"],
    ["490", "37", "368"],
    ["779", "32", "255"],
    ["570", "27", "467"],
    ["348", "50", "127"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["128", "10", "389"],
    ["160", "77", "467"],
    ["790", "63", "346"],
    ["567", "85", "780"],
    ["590", "44", "158"],
    ["580", "34", "149"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["677", "08", "459"],
    ["290", "19", "360"],
    ["235", "00", "127"],
    ["680", "49", "234"],
    ["230", "55", "447"],
    ["120", "34", "680"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["799", "56", "169"],
    ["235", "03", "788"],
    ["578", "07", "557"],
    ["388", "98", "260"],
    ["128", "19", "225"],
    ["457", "66", "240"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["550", "03", "157"],
    ["390", "28", "125"],
    ["448", "64", "590"],
    ["150", "67", "160"],
    ["450", "91", "236"],
    ["234", "97", "124"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["180", "99", "270"],
    ["690", "59", "568"],
    ["360", "93", "139"],
    ["360", "90", "488"],
    ["358", "68", "260"],
    ["479", "01", "245"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["129", "22", "246"],
    ["233", "86", "556"],
    ["267", "51", "678"],
    ["440", "88", "459"],
    ["244", "06", "349"],
    ["239", "48", "558"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["388", "90", "235"],
    ["134", "80", "145"],
    ["226", "09", "126"],
    ["348", "54", "167"],
    ["557", "76", "457"],
    ["468", "87", "160"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["679", "22", "228"],
    ["700", "71", "669"],
    ["238", "32", "390"],
    ["780", "58", "224"],
    ["248", "43", "238"],
    ["146", "13", "139"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["289", "97", "133"],
    ["147", "21", "344"],
    ["146", "16", "150"],
    ["225", "95", "159"],
    ["350", "86", "600"],
    ["700", "71", "669"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["358", "61", "137"],
    ["456", "54", "248"],
    ["880", "62", "570"],
    ["136", "08", "288"],
    ["467", "77", "458"],
    ["569", "04", "257"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["149", "46", "457"],
    ["138", "20", "550"],
    ["148", "33", "788"],
    ["160", "73", "139"],
    ["115", "77", "115"],
    ["125", "83", "689"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["130", "45", "348"],
    ["123", "60", "578"],
    ["369", "86", "123"],
    ["270", "94", "400"],
    ["125", "88", "459"],
    ["449", "71", "678"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["488", "06", "358"],
    ["380", "18", "134"],
    ["560", "18", "134"],
    ["690", "59", "450"],
    ["156", "20", "190"],
    ["188", "79", "135"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["160", "77", "340"],
    ["689", "35", "348"],
    ["129", "25", "456"],
    ["688", "21", "380"],
    ["278", "73", "670"],
    ["367", "63", "148"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["126", "93", "148"],
    ["136", "00", "299"],
    ["256", "38", "279"],
    ["237", "22", "228"],
    ["346", "33", "148"],
    ["560", "16", "358"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["228", "27", "179"],
    ["600", "66", "277"],
    ["158", "46", "349"],
    ["300", "31", "236"],
    ["670", "35", "339"],
    ["167", "44", "167"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["160", "78", "134"],
    ["568", "92", "138"],
    ["330", "66", "169"],
    ["457", "62", "345"],
    ["440", "87", "890"],
    ["135", "98", "260"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["144", "91", "380"],
    ["445", "33", "256"],
    ["134", "82", "778"],
    ["389", "00", "488"],
    ["260", "83", "148"],
    ["346", "35", "140"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["140", "53", "120"],
    ["129", "23", "256"],
    ["160", "74", "356"],
    ["378", "85", "267"],
    ["380", "15", "249"],
    ["370", "02", "679"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["138", "23", "238"],
    ["358", "69", "900"],
    ["580", "33", "139"],
    ["350", "85", "140"],
    ["688", "26", "277"],
    ["350", "81", "678"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["266", "42", "660"],
    ["235", "05", "249"],
    ["670", "32", "246"],
    ["238", "34", "130"],
    ["350", "85", "140"],
    ["350", "81", "678"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["799", "53", "670"],
    ["358", "69", "900"],
    ["688", "26", "277"],
    ["117", "96", "277"],
    ["350", "81", "678"],
    ["348", "57", "269"],

  ],
  "11/20/2023 to 11/26/2023": [
    ["250", "71", "128"],
    ["366", "52", "129"],
    ["379", "94", "789"],
    ["799", "53", "670"],
    ["348", "57", "269"],
    ["578", "09", "360"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["680", "41", "579"],
    ["400", "49", "469"],
    ["578", "07", "160"],
    ["130", "45", "159"],
    ["679", "24", "149"],
    ["568", "99", "234"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["780", "52", "200"],
    ["117", "96", "277"],
    ["114", "66", "123"],
    ["180", "97", "368"],
    ["255", "20", "569"],
    ["278", "71", "489"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["356", "44", "112"],
    ["468", "83", "490"],
    ["269", "74", "338"],
    ["157", "33", "166"],
    ["790", "65", "690"],
    ["237", "28", "468"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["158", "46", "123"],
    ["348", "59", "388"],
    ["380", "17", "377"],
    ["249", "54", "149"],
    ["129", "29", "126"],
    ["244", "02", "570"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["125", "82", "147"],
    ["100", "19", "450"],
    ["259", "63", "346"],
    ["270", "92", "156"],
    ["246", "21", "588"],
    ["114", "67", "890"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["890", "75", "168"],
    ["159", "54", "699"],
    ["357", "50", "127"],
    ["349", "69", "126"],
    ["780", "56", "240"],
    ["880", "62", "480"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["660", "23", "445"],
    ["560", "15", "249"],
    ["379", "98", "378"],
    ["345", "26", "114"],
    ["249", "55", "230"],
    ["569", "05", "456"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["558", "89", "360"],
    ["457", "60", "578"],
    ["290", "12", "679"],
    ["346", "31", "579"],
    ["269", "74", "699"],
    ["346", "39", "234"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["449", "76", "457"],
    ["236", "16", "259"],
    ["477", "80", "226"],
    ["234", "92", "138"],
    ["***", "**", "***"],
    ["120", "31", "560"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["147", "23", "139"],
    ["480", "25", "258"],
    ["899", "67", "340"],
    ["116", "88", "233"],
    ["230", "58", "125"],
    ["580", "36", "150"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["368", "72", "345"],
    ["589", "20", "460"],
    ["358", "61", "290"],
    ["128", "19", "450"],
    ["380", "13", "670"],
    ["116", "86", "259"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["590", "46", "448"],
    ["337", "37", "700"],
    ["890", "72", "345"],
    ["280", "08", "378"],
    ["599", "37", "160"],
    ["670", "35", "456"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["778", "20", "190"],
    ["123", "63", "148"],
    ["550", "01", "245"],
    ["190", "02", "138"],
    ["226", "06", "240"],
    ["348", "56", "123"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["147", "27", "124"],
    ["667", "94", "257"],
    ["129", "21", "236"],
    ["350", "83", "337"],
    ["789", "44", "149"],
    ["480", "21", "399"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["556", "60", "479"],
    ["359", "73", "346"],
    ["550", "09", "225"],
    ["467", "70", "235"],
    ["359", "75", "456"],
    ["689", "37", "188"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["366", "59", "126"],
    ["468", "88", "350"],
    ["699", "45", "168"],
    ["189", "87", "133"],
    ["159", "59", "450"],
    ["570", "23", "157"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["600", "65", "168"],
    ["125", "84", "347"],
    ["289", "94", "149"],
    ["357", "55", "348"],
    ["188", "71", "245"],
    ["890", "77", "340"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["***", "**", "***"],
    ["156", "27", "368"],
    ["278", "78", "279"],
    ["246", "28", "260"],
    ["556", "62", "345"],
    ["579", "10", "235"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["335", "14", "590"],
    ["467", "73", "256"],
    ["224", "86", "150"],
    ["190", "08", "279"],
    ["240", "69", "144"],
    ["260", "83", "247"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["156", "28", "170"],
    ["788", "31", "128"],
    ["270", "96", "349"],
    ["128", "14", "257"],
    ["234", "90", "280"],
    ["690", "53", "346"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["237", "20", "145"],
    ["148", "39", "478"],
    ["778", "24", "257"],
    ["188", "70", "578"],
    ["140", "55", "168"],
    ["114", "67", "124"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["130", "45", "159"],
    ["590", "44", "789"],
    ["489", "12", "255"],
    ["249", "52", "147"],
    ["557", "73", "238"],
    ["380", "15", "456"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["778", "26", "240"],
    ["880", "60", "389"],
    ["470", "10", "578"],
    ["360", "94", "400"],
    ["560", "12", "228"],
    ["589", "21", "128"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["267", "53", "247"],
    ["700", "72", "147"],
    ["568", "93", "490"],
    ["449", "75", "447"],
    ["469", "90", "244"],
    ["378", "81", "236"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["468", "81", "137"],
    ["700", "77", "223"],
    ["660", "29", "234"],
    ["246", "22", "570"],
    ["489", "11", "579"],
    ["117", "94", "257"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["133", "71", "579"],
    ["235", "03", "490"],
    ["168", "50", "460"],
    ["267", "56", "268"],
    ["139", "31", "128"],
    ["335", "18", "990"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["899", "63", "779"],
    ["235", "02", "390"],
    ["289", "91", "560"],
    ["690", "58", "170"],
    ["157", "30", "668"],
    ["278", "77", "449"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["157", "36", "169"],
    ["477", "87", "449"],
    ["240", "63", "120"],
    ["190", "00", "145"],
    ["589", "25", "267"],
    ["599", "31", "119"],
  ],
  "06/10/2024 to 06/16/2024": [
    ["670", "38", "350"],
    ["380", "14", "338"],
    ["445", "36", "880"],
    ["290", "12", "390"],
    ["156", "28", "134"],
    ["469", "98", "800"],
  ],
  "06/17/2024 to 06/23/2024": [
    ["145", "08", "260"],
    ["488", "07", "179"],
    ["369", "82", "237"],
    ["110", "24", "167"],
    ["240", "61", "236"],
    ["249", "59", "469"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["279", "88", "567"],
    ["113", "52", "660"],
    ["700", "75", "258"],
    ["127", "07", "368"],
    ["237", "28", "125"],
    ["230", "58", "260"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["356", "43", "580"],
    ["558", "87", "359"],
    ["560", "10", "145"],
    ["150", "62", "589"],
    ["127", "01", "579"],
    ["244", "04", "248"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["889", "59", "450"],
    ["589", "26", "259"],
    ["479", "07", "566"],
    ["457", "60", "370"],
    ["237", "27", "160"],
    ["469", "94", "590"],
  ],
};
export const RAJDHANIDAY = {
  "01/02/2023 to 01/08/2023": [
    ["469", "98", "170"],
    ["150", "60", "280"],
    ["556", "64", "789"],
    ["156", "23", "779"],
    ["130", "43", "157"],
    ["560", "11", "380"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["278", "74", "167"],
    ["700", "78", "134"],
    ["360", "98", "440"],
    ["233", "86", "240"],
    ["145", "01", "146"],
    ["560", "13", "490"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["589", "22", "390"],
    ["179", "78", "350"],
    ["260", "89", "234"],
    ["660", "22", "156"],
    ["244", "04", "680"],
    ["345", "27", "377"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["890", "75", "140"],
    ["340", "73", "490"],
    ["236", "19", "379"],
    ["560", "11", "380"],
    ["900", "91", "588"],
    ["359", "78", "134"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["347", "40", "668"],
    ["459", "86", "330"],
    ["579", "11", "470"],
    ["240", "69", "469"],
    ["350", "84", "257"],
    ["378", "89", "180"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["670", "37", "359"],
    ["169", "69", "135"],
    ["669", "10", "334"],
    ["245", "15", "168"],
    ["339", "57", "269"],
    ["359", "72", "129"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["440", "86", "556"],
    ["460", "02", "570"],
    ["690", "52", "390"],
    ["578", "05", "230"],
    ["236", "13", "256"],
    ["334", "01", "560"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["789", "45", "159"],
    ["469", "95", "357"],
    ["112", "46", "240"],
    ["280", "00", "136"],
    ["129", "21", "588"],
    ["125", "89", "135"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["450", "92", "138"],
    ["668", "09", "270"],
    ["123", "64", "158"],
    ["189", "87", "269"],
    ["489", "12", "237"],
    ["114", "60", "145"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["146", "15", "780"],
    ["356", "41", "560"],
    ["560", "11", "380"],
    ["690", "53", "337"],
    ["137", "10", "668"],
    ["379", "96", "349"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["689", "32", "138"],
    ["900", "99", "135"],
    ["159", "50", "244"],
    ["479", "05", "249"],
    ["688", "23", "490"],
    ["158", "45", "690"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["344", "12", "570"],
    ["156", "26", "169"],
    ["456", "53", "120"],
    ["990", "87", "467"],
    ["157", "34", "149"],
    ["578", "03", "166"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["570", "27", "458"],
    ["177", "50", "370"],
    ["135", "93", "599"],
    ["567", "84", "158"],
    ["378", "81", "137"],
    ["669", "14", "356"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["256", "39", "568"],
    ["340", "75", "113"],
    ["299", "00", "190"],
    ["145", "05", "168"],
    ["356", "40", "569"],
    ["680", "47", "359"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["558", "83", "490"],
    ["120", "37", "340"],
    ["239", "40", "127"],
    ["449", "71", "155"],
    ["236", "16", "358"],
    ["247", "33", "346"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["169", "65", "249"],
    ["168", "50", "334"],
    ["335", "12", "679"],
    ["159", "52", "345"],
    ["677", "01", "588"],
    ["270", "99", "360"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["130", "45", "780"],
    ["350", "89", "180"],
    ["248", "43", "157"],
    ["670", "34", "347"],
    ["135", "97", "269"],
    ["789", "48", "134"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["224", "80", "460"],
    ["227", "13", "148"],
    ["256", "33", "157"],
    ["230", "58", "440"],
    ["128", "17", "160"],
    ["299", "00", "668"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["157", "33", "490"],
    ["469", "94", "699"],
    ["147", "20", "127"],
    ["139", "34", "149"],
    ["136", "01", "245"],
    ["440", "80", "479"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["450", "97", "133"],
    ["249", "55", "456"],
    ["359", "71", "335"],
    ["233", "80", "578"],
    ["156", "22", "345"],
    ["990", "83", "346"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["239", "41", "579"],
    ["359", "71", "290"],
    ["345", "22", "480"],
    ["479", "08", "990"],
    ["128", "15", "230"],
    ["489", "18", "369"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["166", "35", "159"],
    ["136", "07", "269"],
    ["570", "21", "380"],
    ["390", "28", "189"],
    ["280", "04", "356"],
    ["160", "70", "677"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["234", "99", "667"],
    ["460", "03", "157"],
    ["135", "97", "340"],
    ["244", "04", "789"],
    ["257", "42", "129"],
    ["117", "96", "466"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["290", "16", "150"],
    ["235", "06", "349"],
    ["577", "94", "590"],
    ["247", "37", "557"],
    ["190", "02", "390"],
    ["144", "99", "126"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["167", "42", "147"],
    ["680", "49", "469"],
    ["899", "60", "145"],
    ["260", "88", "567"],
    ["349", "61", "669"],
    ["128", "16", "123"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["560", "13", "120"],
    ["360", "97", "115"],
    ["130", "42", "679"],
    ["238", "31", "489"],
    ["166", "31", "588"],
    ["358", "67", "368"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["488", "08", "378"],
    ["370", "07", "250"],
    ["150", "60", "145"],
    ["270", "97", "160"],
    ["119", "19", "469"],
    ["334", "08", "260"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["469", "90", "479"],
    ["568", "98", "134"],
    ["266", "46", "114"],
    ["248", "48", "260"],
    ["480", "25", "258"],
    ["360", "92", "147"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["157", "35", "690"],
    ["268", "60", "145"],
    ["122", "51", "678"],
    ["490", "35", "500"],
    ["267", "59", "270"],
    ["380", "15", "230"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["459", "87", "269"],
    ["134", "89", "360"],
    ["245", "16", "268"],
    ["124", "78", "125"],
    ["556", "63", "139"],
    ["229", "31", "236"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["390", "28", "567"],
    ["580", "36", "259"],
    ["233", "89", "478"],
    ["456", "59", "289"],
    ["360", "94", "130"],
    ["370", "00", "370"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["239", "48", "800"],
    ["268", "62", "246"],
    ["567", "87", "340"],
    ["233", "86", "178"],
    ["115", "73", "670"],
    ["990", "84", "789"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["578", "02", "138"],
    ["560", "11", "380"],
    ["670", "30", "677"],
    ["550", "09", "126"],
    ["248", "49", "135"],
    ["690", "55", "780"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["269", "72", "138"],
    ["447", "50", "136"],
    ["330", "64", "248"],
    ["469", "94", "130"],
    ["260", "81", "245"],
    ["446", "45", "230"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["490", "34", "112"],
    ["460", "03", "256"],
    ["580", "32", "129"],
    ["990", "82", "228"],
    ["159", "57", "458"],
    ["110", "22", "390"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["130", "41", "380"],
    ["133", "77", "250"],
    ["158", "49", "180"],
    ["689", "35", "168"],
    ["228", "23", "148"],
    ["234", "93", "580"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["136", "07", "467"],
    ["167", "49", "199"],
    ["450", "99", "388"],
    ["467", "70", "479"],
    ["155", "15", "690"],
    ["246", "29", "234"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["668", "07", "160"],
    ["140", "55", "339"],
    ["267", "51", "470"],
    ["400", "40", "190"],
    ["137", "17", "359"],
    ["550", "01", "579"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["180", "97", "467"],
    ["244", "08", "288"],
    ["235", "05", "159"],
    ["238", "34", "112"],
    ["168", "50", "190"],
    ["146", "14", "248"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["340", "79", "450"],
    ["118", "05", "168"],
    ["189", "84", "220"],
    ["120", "36", "169"],
    ["470", "17", "160"],
    ["133", "72", "156"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["490", "36", "268"],
    ["479", "07", "179"],
    ["558", "82", "237"],
    ["267", "59", "568"],
    ["360", "90", "280"],
    ["234", "91", "560"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["122", "54", "239"],
    ["890", "75", "113"],
    ["260", "86", "114"],
    ["479", "07", "359"],
    ["678", "18", "378"],
    ["137", "10", "127"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["389", "04", "356"],
    ["360", "98", "116"],
    ["139", "35", "348"],
    ["238", "31", "137"],
    ["589", "23", "157"],
    ["110", "24", "257"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["370", "07", "368"],
    ["128", "15", "140"],
    ["266", "41", "236"],
    ["239", "46", "150"],
    ["129", "22", "237"],
    ["136", "01", "344"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["579", "12", "589"],
    ["600", "68", "378"],
    ["460", "08", "440"],
    ["790", "64", "338"],
    ["238", "31", "137"],
    ["234", "91", "560"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["389", "04", "356"],
    ["678", "18", "378"],
    ["129", "22", "237"],
    ["136", "01", "344"],
    ["267", "59", "568"],
    ["360", "90", "280"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["226", "06", "349"],
    ["123", "63", "148"],
    ["290", "11", "579"],
    ["459", "86", "240"],
    ["257", "41", "344"],
    ["167", "40", "677"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["235", "09", "379"],
    ["234", "94", "347"],
    ["268", "66", "790"],
    ["138", "24", "158"],
    ["478", "97", "467"],
    ["189", "80", "226"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["346", "33", "689"],
    ["178", "64", "149"],
    ["344", "16", "367"],
    ["245", "15", "456"],
    ["379", "91", "128"],
    ["177", "59", "180"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["257", "46", "178"],
    ["169", "63", "337"],
    ["599", "39", "450"],
    ["569", "07", "124"],
    ["167", "48", "350"],
    ["250", "79", "568"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["379", "97", "160"],
    ["127", "02", "778"],
    ["126", "94", "149"],
    ["358", "66", "150"],
    ["390", "27", "179"],
    ["118", "03", "256"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["137", "14", "239"],
    ["189", "87", "115"],
    ["780", "59", "180"],
    ["900", "92", "390"],
    ["124", "75", "366"],
    ["137", "11", "335"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["129", "23", "148"],
    ["135", "99", "126"],
    ["179", "70", "479"],
    ["389", "05", "339"],
    ["246", "21", "678"],
    ["135", "99", "900"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["255", "26", "123"],
    ["790", "64", "347"],
    ["118", "08", "350"],
    ["258", "50", "479"],
    ["180", "99", "577"],
    ["447", "50", "280"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["678", "16", "790"],
    ["489", "17", "188"],
    ["179", "70", "460"],
    ["134", "83", "580"],
    ["770", "40", "389"],
    ["360", "91", "380"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["790", "63", "355"],
    ["680", "45", "140"],
    ["150", "62", "147"],
    ["900", "95", "168"],
    ["***", "**", "***"],
    ["570", "21", "344"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["247", "33", "148"],
    ["680", "46", "268"],
    ["158", "44", "130"],
    ["457", "60", "479"],
    ["677", "02", "480"],
    ["268", "62", "246"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["126", "96", "358"],
    ["448", "67", "890"],
    ["125", "88", "477"],
    ["369", "81", "380"],
    ["127", "02", "345"],
    ["355", "31", "128"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["149", "44", "356"],
    ["679", "23", "148"],
    ["446", "47", "359"],
    ["457", "64", "455"],
    ["580", "31", "236"],
    ["368", "70", "668"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["479", "08", "459"],
    ["259", "66", "240"],
    ["278", "78", "558"],
    ["145", "03", "599"],
    ["577", "93", "580"],
    ["127", "00", "569"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["289", "93", "120"],
    ["500", "57", "368"],
    ["380", "19", "568"],
    ["588", "13", "256"],
    ["360", "96", "178"],
    ["570", "28", "224"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["300", "34", "220"],
    ["269", "73", "247"],
    ["499", "23", "689"],
    ["246", "28", "369"],
    ["257", "41", "678"],
    ["369", "84", "239"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["133", "71", "236"],
    ["340", "77", "557"],
    ["200", "28", "189"],
    ["149", "42", "679"],
    ["359", "76", "349"],
    ["800", "89", "450"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["289", "95", "168"],
    ["123", "66", "114"],
    ["880", "61", "128"],
    ["260", "80", "190"],
    ["145", "05", "456"],
    ["116", "83", "256"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["***", "**", "***"],
    ["590", "45", "140"],
    ["122", "58", "260"],
    ["669", "13", "166"],
    ["236", "10", "479"],
    ["190", "07", "890"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["149", "41", "245"],
    ["689", "33", "300"],
    ["136", "09", "270"],
    ["680", "41", "146"],
    ["136", "03", "139"],
    ["166", "38", "288"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["289", "99", "568"],
    ["460", "08", "189"],
    ["445", "37", "269"],
    ["349", "68", "369"],
    ["170", "81", "669"],
    ["146", "11", "227"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["240", "63", "689"],
    ["300", "37", "179"],
    ["279", "82", "589"],
    ["127", "06", "457"],
    ["380", "15", "690"],
    ["155", "16", "169"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["467", "75", "113"],
    ["449", "72", "570"],
    ["357", "53", "490"],
    ["125", "84", "680"],
    ["226", "05", "230"],
    ["229", "33", "670"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["267", "56", "457"],
    ["136", "02", "679"],
    ["116", "88", "170"],
    ["357", "59", "126"],
    ["660", "22", "138"],
    ["336", "24", "347"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["128", "12", "138"],
    ["389", "05", "140"],
    ["470", "14", "239"],
    ["448", "61", "146"],
    ["125", "80", "299"],
    ["189", "80", "578"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["579", "19", "379"],
    ["330", "65", "348"],
    ["149", "40", "334"],
    ["489", "14", "167"],
    ["790", "67", "269"],
    ["477", "80", "389"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["128", "16", "178"],
    ["189", "83", "238"],
    ["115", "71", "579"],
    ["340", "74", "590"],
    ["468", "84", "220"],
    ["110", "22", "156"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["236", "15", "230"],
    ["790", "63", "670"],
    ["559", "97", "340"],
    ["580", "33", "247"],
    ["180", "95", "177"],
    ["122", "54", "347"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["490", "31", "560"],
    ["579", "18", "440"],
    ["570", "22", "480"],
    ["246", "28", "260"],
    ["279", "87", "223"],
    ["700", "76", "150"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["580", "33", "256"],
    ["158", "46", "448"],
    ["123", "64", "590"],
    ["240", "63", "247"],
    ["668", "08", "990"],
    ["480", "29", "270"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["489", "19", "469"],
    ["236", "13", "139"],
    ["156", "29", "199"],
    ["780", "56", "123"],
    ["179", "75", "159"],
    ["557", "79", "478"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["259", "68", "468"],
    ["113", "53", "238"],
    ["159", "57", "133"],
    ["588", "14", "248"],
    ["468", "81", "290"],
    ["480", "26", "457"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["580", "30", "550"],
    ["579", "14", "590"],
    ["339", "54", "149"],
    ["489", "12", "156"],
    ["356", "40", "479"],
    ["233", "82", "570"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["458", "70", "370"],
    ["348", "54", "590"],
    ["288", "85", "258"],
    ["158", "41", "678"],
    ["340", "74", "446"],
    ["570", "29", "469"],
  ],
};

export const MILANDAY = {
  "01/02/2023 to 01/08/2023": [
    ["260", "86", "330"],
    ["477", "85", "357"],
    ["235", "02", "688"],
    ["460", "05", "168"],
    ["177", "52", "480"],
    ["123", "61", "290"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["388", "95", "249"],
    ["339", "53", "120"],
    ["380", "18", "800"],
    ["127", "09", "379"],
    ["168", "53", "300"],
    ["299", "06", "268"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["138", "26", "600"],
    ["137", "12", "156"],
    ["340", "70", "299"],
    ["669", "12", "336"],
    ["269", "73", "599"],
    ["590", "48", "224"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["359", "77", "359"],
    ["770", "44", "130"],
    ["145", "09", "289"],
    ["299", "06", "268"],
    ["127", "09", "478"],
    ["460", "03", "779"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["459", "88", "125"],
    ["239", "48", "567"],
    ["590", "49", "469"],
    ["789", "44", "257"],
    ["668", "07", "449"],
    ["248", "49", "117"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["133", "74", "248"],
    ["570", "26", "240"],
    ["478", "96", "349"],
    ["230", "57", "115"],
    ["225", "97", "449"],
    ["123", "62", "499"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["259", "62", "156"],
    ["150", "68", "567"],
    ["223", "77", "278"],
    ["123", "64", "789"],
    ["470", "14", "680"],
    ["234", "94", "130"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["890", "72", "345"],
    ["589", "23", "580"],
    ["380", "16", "790"],
    ["158", "49", "360"],
    ["128", "15", "799"],
    ["115", "77", "377"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["488", "00", "460"],
    ["336", "22", "589"],
    ["779", "34", "130"],
    ["147", "27", "890"],
    ["169", "66", "457"],
    ["129", "29", "568"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["249", "53", "346"],
    ["248", "45", "258"],
    ["299", "06", "268"],
    ["499", "21", "470"],
    ["367", "63", "300"],
    ["670", "35", "348"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["679", "27", "700"],
    ["148", "30", "479"],
    ["460", "00", "460"],
    ["249", "57", "458"],
    ["258", "51", "489"],
    ["247", "33", "166"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["266", "40", "389"],
    ["123", "60", "370"],
    ["127", "01", "146"],
    ["124", "75", "267"],
    ["239", "44", "149"],
    ["347", "42", "679"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["257", "42", "480"],
    ["568", "96", "240"],
    ["589", "24", "699"],
    ["240", "63", "139"],
    ["124", "76", "123"],
    ["130", "41", "290"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["112", "41", "470"],
    ["677", "09", "388"],
    ["225", "93", "157"],
    ["478", "96", "169"],
    ["679", "20", "569"],
    ["456", "59", "289"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["588", "11", "100"],
    ["300", "31", "560"],
    ["355", "36", "277"],
    ["269", "79", "289"],
    ["479", "07", "179"],
    ["149", "41", "146"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["588", "10", "235"],
    ["780", "52", "480"],
    ["890", "76", "899"],
    ["146", "15", "159"],
    ["133", "74", "239"],
    ["477", "83", "670"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["137", "10", "136"],
    ["499", "21", "245"],
    ["670", "34", "680"],
    ["169", "64", "167"],
    ["370", "07", "269"],
    ["234", "90", "370"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["179", "72", "688"],
    ["448", "68", "116"],
    ["679", "29", "577"],
    ["480", "28", "378"],
    ["139", "31", "335"],
    ["117", "93", "355"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["560", "19", "478"],
    ["347", "47", "449"],
    ["278", "73", "120"],
    ["600", "65", "500"],
    ["990", "83", "670"],
    ["450", "98", "116"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["770", "41", "344"],
    ["150", "67", "250"],
    ["122", "58", "800"],
    ["220", "41", "146"],
    ["129", "23", "788"],
    ["124", "74", "590"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["267", "58", "260"],
    ["889", "50", "488"],
    ["335", "12", "589"],
    ["279", "80", "578"],
    ["260", "82", "480"],
    ["348", "58", "279"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["225", "99", "199"],
    ["179", "74", "770"],
    ["578", "09", "478"],
    ["566", "76", "358"],
    ["125", "83", "238"],
    ["167", "42", "679"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["567", "89", "135"],
    ["268", "65", "230"],
    ["790", "68", "189"],
    ["578", "08", "369"],
    ["236", "10", "389"],
    ["680", "49", "388"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["440", "82", "147"],
    ["299", "00", "389"],
    ["347", "47", "566"],
    ["269", "70", "127"],
    ["127", "02", "345"],
    ["170", "81", "236"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["399", "19", "379"],
    ["346", "33", "788"],
    ["567", "88", "170"],
    ["467", "77", "133"],
    ["124", "76", "169"],
    ["567", "88", "125"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["249", "57", "223"],
    ["668", "02", "228"],
    ["660", "23", "689"],
    ["344", "18", "116"],
    ["380", "12", "156"],
    ["124", "73", "120"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["234", "99", "568"],
    ["345", "26", "600"],
    ["467", "71", "119"],
    ["230", "54", "888"],
    ["799", "50", "578"],
    ["468", "86", "899"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["115", "71", "335"],
    ["134", "89", "478"],
    ["136", "03", "229"],
    ["788", "32", "336"],
    ["590", "43", "148"],
    ["137", "12", "345"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["359", "74", "167"],
    ["148", "35", "249"],
    ["157", "33", "779"],
    ["166", "39", "450"],
    ["126", "98", "279"],
    ["670", "33", "490"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["170", "81", "470"],
    ["446", "42", "679"],
    ["238", "30", "190"],
    ["199", "96", "790"],
    ["145", "07", "250"],
    ["145", "02", "246"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["557", "70", "235"],
    ["123", "63", "238"],
    ["158", "45", "168"],
    ["280", "07", "115"],
    ["369", "84", "239"],
    ["490", "38", "134"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["369", "89", "234"],
    ["347", "43", "670"],
    ["568", "90", "136"],
    ["440", "83", "599"],
    ["120", "36", "880"],
    ["349", "66", "240"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["357", "57", "223"],
    ["440", "83", "599"],
    ["137", "11", "245"],
    ["167", "46", "790"],
    ["456", "55", "357"],
    ["270", "92", "246"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["440", "83", "599"],
    ["223", "71", "236"],
    ["890", "70", "190"],
    ["125", "87", "269"],
    ["559", "98", "567"],
    ["245", "15", "249"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["224", "88", "279"],
    ["134", "88", "125"],
    ["160", "70", "389"],
    ["670", "31", "470"],
    ["113", "51", "128"],
    ["668", "05", "249"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["357", "52", "589"],
    ["160", "72", "480"],
    ["233", "80", "190"],
    ["689", "31", "678"],
    ["166", "39", "180"],
    ["135", "94", "266"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["440", "87", "557"],
    ["470", "16", "349"],
    ["470", "17", "458"],
    ["259", "69", "360"],
    ["128", "15", "690"],
    ["347", "49", "388"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["122", "50", "668"],
    ["440", "80", "299"],
    ["399", "11", "137"],
    ["178", "62", "237"],
    ["340", "78", "125"],
    ["699", "43", "337"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["235", "02", "237"],
    ["356", "48", "468"],
    ["166", "31", "146"],
    ["289", "95", "177"],
    ["355", "38", "116"],
    ["340", "72", "129"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["355", "30", "299"],
    ["456", "55", "230"],
    ["380", "12", "480"],
    ["155", "18", "260"],
    ["136", "01", "678"],
    ["258", "54", "770"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["458", "72", "589"],
    ["570", "27", "160"],
    ["788", "33", "120"],
    ["370", "09", "135"],
    ["469", "96", "457"],
    ["127", "04", "266"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["800", "82", "778"],
    ["450", "99", "126"],
    ["357", "51", "678"],
    ["240", "63", "788"],
    ["146", "11", "678"],
    ["567", "86", "367"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["240", "68", "224"],
    ["259", "60", "235"],
    ["170", "83", "148"],
    ["125", "80", "479"],
    ["890", "78", "170"],
    ["779", "32", "390"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["369", "82", "336"],
    ["447", "56", "349"],
    ["336", "29", "117"],
    ["128", "14", "158"],
    ["135", "92", "156"],
    ["145", "04", "220"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["146", "16", "268"],
    ["120", "33", "445"],
    ["389", "09", "289"],
    ["248", "42", "138"],
    ["890", "78", "170"],
    ["779", "32", "390"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["447", "56", "349"],
    ["336", "29", "117"],
    ["248", "42", "138"],
    ["890", "78", "170"],
    ["248", "46", "358"],
    ["334", "06", "349"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["337", "39", "478"],
    ["119", "15", "366"],
    ["770", "49", "360"],
    ["248", "46", "358"],
    ["334", "06", "349"],
    ["134", "89", "478"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["236", "10", "569"],
    ["127", "03", "247"],
    ["299", "01", "678"],
    ["690", "52", "589"],
    ["780", "51", "399"],
    ["123", "62", "147"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["345", "23", "120"],
    ["130", "49", "270"],
    ["799", "55", "500"],
    ["450", "99", "126"],
    ["340", "70", "226"],
    ["580", "39", "450"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["148", "35", "339"],
    ["558", "84", "789"],
    ["400", "49", "225"],
    ["160", "74", "130"],
    ["799", "54", "455"],
    ["288", "81", "155"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["366", "53", "120"],
    ["347", "43", "337"],
    ["390", "23", "670"],
    ["447", "58", "800"],
    ["267", "58", "279"],
    ["446", "44", "356"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["380", "11", "380"],
    ["240", "63", "247"],
    ["348", "55", "357"],
    ["199", "91", "137"],
    ["357", "58", "378"],
    ["260", "81", "119"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["399", "15", "168"],
    ["458", "72", "138"],
    ["246", "26", "899"],
    ["450", "96", "358"],
    ["290", "16", "150"],
    ["357", "55", "249"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["269", "76", "114"],
    ["115", "74", "149"],
    ["125", "88", "279"],
    ["188", "76", "899"],
    ["135", "93", "120"],
    ["469", "98", "459"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["470", "19", "126"],
    ["159", "56", "169"],
    ["477", "81", "669"],
    ["146", "17", "458"],
    ["389", "02", "156"],
    ["289", "96", "330"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["247", "39", "568"],
    ["134", "87", "133"],
    ["140", "52", "147"],
    ["448", "65", "780"],
    ["248", "46", "358"],
    ["334", "06", "349"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["560", "17", "700"],
    ["358", "64", "356"],
    ["235", "07", "278"],
    ["237", "26", "330"],
    ["469", "91", "245"],
    ["270", "98", "260"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["289", "98", "125"],
    ["157", "30", "668"],
    ["240", "62", "345"],
    ["128", "13", "689"],
    ["137", "17", "179"],
    ["389", "01", "560"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["677", "06", "367"],
    ["288", "80", "578"],
    ["379", "95", "140"],
    ["338", "43", "229"],
    ["889", "55", "690"],
    ["359", "78", "279"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["240", "63", "337"],
    ["159", "51", "245"],
    ["380", "15", "267"],
    ["257", "41", "236"],
    ["449", "71", "146"],
    ["347", "40", "578"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["144", "97", "223"],
    ["118", "08", "477"],
    ["145", "09", "234"],
    ["480", "26", "123"],
    ["345", "27", "368"],
    ["160", "76", "600"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["188", "76", "114"],
    ["359", "73", "779"],
    ["188", "78", "116"],
    ["144", "99", "577"],
    ["160", "71", "137"],
    ["278", "71", "236"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["468", "83", "355"],
    ["245", "16", "114"],
    ["500", "59", "117"],
    ["370", "08", "224"],
    ["447", "54", "400"],
    ["169", "65", "140"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["269", "77", "458"],
    ["250", "75", "447"],
    ["236", "11", "399"],
    ["379", "98", "260"],
    ["124", "70", "280"],
    ["280", "01", "399"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["248", "46", "358"],
    ["334", "06", "349"],
    ["577", "94", "347"],
    ["369", "83", "120"],
    ["479", "04", "266"],
    ["248", "49", "360"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["489", "10", "244"],
    ["270", "92", "480"],
    ["568", "91", "335"],
    ["190", "03", "779"],
    ["588", "18", "233"],
    ["123", "63", "247"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["246", "25", "249"],
    ["448", "60", "226"],
    ["249", "56", "268"],
    ["148", "37", "557"],
    ["138", "21", "579"],
    ["138", "26", "259"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["269", "70", "668"],
    ["340", "72", "570"],
    ["778", "25", "177"],
    ["125", "88", "350"],
    ["567", "86", "178"],
    ["370", "04", "167"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["246", "27", "179"],
    ["235", "07", "700"],
    ["358", "66", "358"],
    ["226", "06", "240"],
    ["445", "31", "119"],
    ["260", "80", "235"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["346", "33", "139"],
    ["189", "89", "126"],
    ["469", "95", "357"],
    ["369", "89", "379"],
    ["177", "56", "169"],
    ["268", "66", "178"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["600", "64", "158"],
    ["179", "79", "135"],
    ["357", "54", "347"],
    ["688", "29", "199"],
    ["900", "94", "112"],
    ["124", "77", "269"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["279", "82", "138"],
    ["147", "29", "234"],
    ["167", "49", "379"],
    ["457", "65", "140"],
    ["334", "08", "170"],
    ["599", "37", "115"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["114", "65", "690"],
    ["160", "77", "340"],
    ["135", "94", "789"],
    ["257", "43", "670"],
    ["689", "35", "267"],
    ["238", "38", "170"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["339", "59", "270"],
    ["479", "02", "246"],
    ["990", "86", "259"],
    ["380", "16", "178"],
    ["344", "10", "136"],
    ["577", "98", "440"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["127", "01", "146"],
    ["240", "62", "147"],
    ["349", "69", "333"],
    ["345", "25", "267"],
    ["588", "12", "480"],
    ["234", "94", "257"],
  ],
  "06/10/2024 to 06/16/2024": [
    ["468", "86", "880"],
    ["167", "44", "158"],
    ["146", "15", "258"],
    ["670", "39", "667"],
    ["178", "60", "370"],
    ["340", "78", "558"],
  ],
  "06/17/2024 to 06/23/2024": [
    ["334", "00", "235"],
    ["290", "14", "158"],
    ["490", "32", "147"],
    ["149", "47", "467"],
    ["128", "10", "235"],
    ["445", "31", "128"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["112", "40", "145"],
    ["880", "66", "123"],
    ["337", "34", "167"],
    ["236", "16", "880"],
    ["260", "88", "260"],
    ["257", "49", "180"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["115", "71", "399"],
    ["237", "21", "560"],
    ["230", "50", "389"],
    ["113", "54", "400"],
    ["280", "08", "224"],
    ["144", "91", "245"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["456", "50", "299"],
    ["568", "96", "466"],
    ["789", "41", "245"],
    ["229", "38", "468"],
    ["150", "67", "890"],
    ["369", "83", "120"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["660", "26", "150"],
    ["360", "97", "278"],
    ["270", "99", "379"],
    ["115", "78", "224"],
    ["567", "87", "467"],
    ["267", "58", "468"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["470", "17", "467"],
    ["899", "67", "467"],
    ["140", "57", "160"],
    ["369", "89", "568"],
    ["480", "20", "370"],
    ["480", "28", "170"],
  ],
};

export const KALYAN = {
  "01/02/2023 to 01/08/2023": [
    ["260", "87", "359"],
    ["137", "11", "588"],
    ["678", "11", "380"],
    ["567", "85", "348"],
    ["579", "17", "467"],
    ["470", "19", "126"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["469", "97", "368"],
    ["247", "35", "230"],
    ["359", "79", "180"],
    ["690", "57", "124"],
    ["179", "72", "246"],
    ["247", "33", "670"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["460", "02", "110"],
    ["669", "15", "122"],
    ["245", "15", "168"],
    ["899", "69", "450"],
    ["360", "96", "790"],
    ["459", "82", "246"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["225", "94", "167"],
    ["177", "56", "123"],
    ["378", "80", "389"],
    ["***", "**", "***"],
    ["279", "89", "568"],
    ["137", "15", "230"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["338", "48", "170"],
    ["256", "38", "125"],
    ["130", "48", "378"],
    ["580", "30", "569"],
    ["400", "47", "368"],
    ["236", "12", "570"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["266", "44", "130"],
    ["199", "95", "168"],
    ["100", "11", "470"],
    ["129", "22", "679"],
    ["129", "22", "679"],
    ["270", "92", "589"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["279", "80", "127"],
    ["599", "39", "469"],
    ["889", "52", "336"],
    ["330", "68", "189"],
    ["790", "67", "566"],
    ["700", "73", "355"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["680", "43", "670"],
    ["137", "11", "579"],
    ["780", "50", "244"],
    ["380", "12", "336"],
    ["589", "26", "268"],
    ["113", "56", "240"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["168", "51", "489"],
    ["199", "94", "130"],
    ["567", "85", "366"],
    ["259", "60", "334"],
    ["256", "37", "467"],
    ["579", "16", "259"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["227", "14", "590"],
    ["229", "34", "590"],
    ["***", "**", "***"],
    ["568", "93", "238"],
    ["367", "61", "470"],
    ["358", "64", "248"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["135", "96", "169"],
    ["479", "00", "145"],
    ["157", "30", "145"],
    ["599", "38", "378"],
    ["567", "89", "450"],
    ["459", "83", "256"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["124", "76", "150"],
    ["570", "22", "679"],
    ["550", "07", "566"],
    ["126", "97", "340"],
    ["280", "01", "380"],
    ["456", "58", "134"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["779", "36", "169"],
    ["567", "87", "359"],
    ["247", "37", "700"],
    ["450", "95", "168"],
    ["448", "68", "350"],
    ["145", "07", "160"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["150", "60", "127"],
    ["369", "81", "137"],
    ["257", "43", "355"],
    ["269", "72", "660"],
    ["390", "26", "150"],
    ["126", "98", "567"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["278", "70", "460"],
    ["235", "04", "590"],
    ["248", "42", "679"],
    ["236", "17", "250"],
    ["230", "58", "567"],
    ["300", "35", "780"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["236", "12", "345"],
    ["359", "76", "169"],
    ["366", "51", "560"],
    ["589", "27", "368"],
    ["780", "52", "345"],
    ["389", "04", "257"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["160", "71", "560"],
    ["266", "41", "128"],
    ["480", "28", "378"],
    ["170", "83", "139"],
    ["580", "38", "378"],
    ["280", "00", "127"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["288", "88", "170"],
    ["249", "55", "456"],
    ["880", "63", "148"],
    ["557", "79", "469"],
    ["368", "71", "489"],
    ["240", "62", "589"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["247", "38", "459"],
    ["229", "37", "124"],
    ["229", "35", "267"],
    ["899", "63", "670"],
    ["390", "22", "570"],
    ["456", "51", "137"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["566", "71", "669"],
    ["399", "15", "357"],
    ["389", "00", "145"],
    ["239", "42", "129"],
    ["389", "06", "358"],
    ["149", "45", "456"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["668", "05", "357"],
    ["337", "36", "178"],
    ["569", "06", "367"],
    ["799", "56", "240"],
    ["348", "56", "169"],
    ["569", "06", "349"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["122", "54", "257"],
    ["889", "58", "459"],
    ["345", "20", "479"],
    ["459", "86", "240"],
    ["157", "37", "179"],
    ["346", "32", "138"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["447", "58", "800"],
    ["267", "58", "224"],
    ["499", "20", "460"],
    ["336", "24", "248"],
    ["569", "00", "479"],
    ["237", "29", "270"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["469", "92", "688"],
    ["368", "78", "459"],
    ["778", "24", "158"],
    ["137", "19", "360"],
    ["677", "01", "588"],
    ["690", "52", "200"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["445", "30", "550"],
    ["179", "76", "448"],
    ["280", "03", "346"],
    ["259", "61", "588"],
    ["346", "33", "139"],
    ["359", "73", "490"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["122", "55", "889"],
    ["167", "41", "344"],
    ["570", "24", "167"],
    ["345", "20", "190"],
    ["237", "26", "240"],
    ["580", "31", "128"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["690", "53", "256"],
    ["167", "43", "689"],
    ["168", "56", "123"],
    ["168", "51", "146"],
    ["568", "95", "230"],
    ["567", "80", "460"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["570", "27", "278"],
    ["159", "53", "157"],
    ["178", "61", "470"],
    ["568", "94", "167"],
    ["150", "61", "236"],
    ["130", "46", "899"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["469", "92", "480"],
    ["700", "74", "266"],
    ["399", "14", "590"],
    ["149", "43", "490"],
    ["689", "31", "137"],
    ["488", "03", "139"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["600", "63", "779"],
    ["379", "92", "237"],
    ["224", "83", "157"],
    ["700", "74", "239"],
    ["459", "86", "880"],
    ["789", "40", "668"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["770", "41", "588"],
    ["300", "39", "388"],
    ["157", "35", "456"],
    ["599", "30", "136"],
    ["489", "18", "288"],
    ["778", "26", "259"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["450", "96", "178"],
    ["789", "48", "170"],
    ["380", "10", "488"],
    ["140", "59", "450"],
    ["269", "78", "990"],
    ["348", "57", "359"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["469", "95", "339"],
    ["***", "**", "***"],
    ["270", "98", "125"],
    ["278", "74", "248"],
    ["367", "64", "248"],
    ["233", "84", "167"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["236", "13", "229"],
    ["136", "00", "578"],
    ["135", "92", "679"],
    ["578", "01", "344"],
    ["269", "76", "367"],
    ["240", "67", "269"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["899", "62", "336"],
    ["110", "24", "266"],
    ["589", "22", "480"],
    ["128", "12", "246"],
    ["559", "99", "289"],
    ["790", "60", "578"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["290", "18", "459"],
    ["345", "29", "559"],
    ["336", "25", "889"],
    ["380", "19", "234"],
    ["245", "18", "468"],
    ["780", "51", "236"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["568", "95", "357"],
    ["188", "77", "890"],
    ["600", "69", "568"],
    ["346", "37", "700"],
    ["567", "81", "560"],
    ["290", "12", "237"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["230", "57", "467"],
    ["580", "32", "480"],
    ["136", "03", "247"],
    ["368", "70", "136"],
    ["144", "95", "159"],
    ["249", "54", "789"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["368", "78", "990"],
    ["367", "60", "235"],
    ["399", "10", "145"],
    ["677", "06", "790"],
    ["570", "28", "288"],
    ["148", "39", "450"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["114", "68", "170"],
    ["355", "33", "256"],
    ["460", "00", "226"],
    ["266", "45", "168"],
    ["690", "52", "255"],
    ["479", "01", "236"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["368", "75", "456"],
    ["490", "37", "133"],
    ["178", "67", "467"],
    ["156", "26", "367"],
    ["180", "90", "136"],
    ["459", "89", "180"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["268", "67", "179"],
    ["667", "90", "145"],
    ["199", "95", "357"],
    ["680", "44", "158"],
    ["229", "35", "447"],
    ["566", "72", "237"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["880", "67", "566"],
    ["223", "75", "799"],
    ["689", "39", "379"],
    ["160", "73", "779"],
    ["368", "77", "278"],
    ["135", "93", "580"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["479", "04", "158"],
    ["468", "83", "337"],
    ["668", "08", "288"],
    ["113", "51", "470"],
    ["167", "41", "380"],
    ["460", "00", "370"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["269", "74", "167"],
    ["156", "28", "189"],
    ["148", "31", "489"],
    ["257", "40", "479"],
    ["***", "**", "***"],
    ["***", "**", "***"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["***", "**", "***"],
    ["***", "**", "***"],
    ["***", "**", "***"],
    ["***", "**", "***"],
    ["***", "**", "***"],
    ["***", "**", "***"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["159", "53", "148"],
    ["348", "53", "120"],
    ["357", "51", "137"],
    ["289", "98", "378"],
    ["257", "48", "260"],
    ["179", "70", "389"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["269", "76", "268"],
    ["146", "13", "139"],
    ["230", "56", "169"],
    ["235", "06", "790"],
    ["580", "33", "139"],
    ["120", "31", "489"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["469", "90", "460"],
    ["270", "97", "340"],
    ["256", "35", "447"],
    ["690", "56", "178"],
    ["588", "13", "445"],
    ["124", "72", "129"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["259", "69", "234"],
    ["257", "46", "600"],
    ["450", "96", "349"],
    ["120", "38", "224"],
    ["112", "48", "134"],
    ["150", "65", "230"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["138", "22", "237"],
    ["249", "59", "379"],
    ["589", "29", "478"],
    ["248", "44", "356"],
    ["470", "14", "680"],
    ["678", "16", "349"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["158", "48", "288"],
    ["677", "02", "589"],
    ["589", "24", "455"],
    ["267", "53", "580"],
    ["690", "54", "347"],
    ["135", "97", "250"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["446", "41", "560"],
    ["169", "60", "280"],
    ["135", "95", "267"],
    ["240", "63", "355"],
    ["480", "25", "690"],
    ["245", "15", "140"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["467", "73", "238"],
    ["112", "42", "138"],
    ["200", "29", "577"],
    ["790", "63", "355"],
    ["256", "38", "134"],
    ["290", "12", "345"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["449", "75", "339"],
    ["890", "75", "140"],
    ["345", "27", "890"],
    ["280", "05", "690"],
    ["589", "25", "339"],
    ["490", "37", "160"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["689", "30", "226"],
    ["300", "37", "269"],
    ["230", "51", "380"],
    ["137", "14", "680"],
    ["***", "**", "***"],
    ["237", "20", "145"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["568", "91", "489"],
    ["124", "72", "589"],
    ["139", "30", "668"],
    ["167", "45", "690"],
    ["334", "04", "149"],
    ["455", "48", "378"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["137", "16", "268"],
    ["556", "62", "688"],
    ["770", "45", "159"],
    ["368", "72", "499"],
    ["189", "87", "700"],
    ["379", "98", "170"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["578", "01", "344"],
    ["126", "97", "124"],
    ["578", "06", "150"],
    ["226", "07", "278"],
    ["447", "52", "237"],
    ["390", "25", "168"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["446", "44", "266"],
    ["479", "04", "257"],
    ["159", "52", "129"],
    ["112", "40", "460"],
    ["569", "03", "779"],
    ["558", "89", "478"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["779", "32", "390"],
    ["668", "08", "378"],
    ["348", "58", "170"],
    ["168", "56", "457"],
    ["699", "47", "340"],
    ["450", "96", "178"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["234", "93", "670"],
    ["467", "76", "358"],
    ["120", "37", "467"],
    ["124", "72", "110"],
    ["226", "03", "689"],
    ["479", "03", "256"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["599", "39", "360"],
    ["480", "27", "458"],
    ["580", "39", "225"],
    ["145", "08", "288"],
    ["589", "28", "189"],
    ["289", "96", "457"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["267", "59", "568"],
    ["790", "60", "370"],
    ["237", "27", "359"],
    ["580", "33", "256"],
    ["123", "66", "790"],
    ["446", "40", "235"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["***", "**", "***"],
    ["577", "97", "250"],
    ["348", "55", "249"],
    ["256", "39", "234"],
    ["677", "08", "990"],
    ["478", "91", "560"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["900", "96", "448"],
    ["880", "64", "338"],
    ["667", "97", "359"],
    ["455", "41", "470"],
    ["680", "49", "289"],
    ["148", "36", "123"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["347", "44", "338"],
    ["355", "32", "255"],
    ["233", "88", "558"],
    ["170", "83", "229"],
    ["600", "64", "347"],
    ["367", "67", "223"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["112", "46", "790"],
    ["270", "97", "160"],
    ["357", "52", "679"],
    ["990", "81", "137"],
    ["370", "02", "345"],
    ["235", "04", "680"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["569", "03", "256"],
    ["248", "49", "577"],
    ["257", "42", "147"],
    ["160", "78", "468"],
    ["358", "67", "890"],
    ["569", "02", "390"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["779", "33", "580"],
    ["568", "92", "138"],
    ["230", "58", "189"],
    ["349", "60", "136"],
    ["145", "08", "468"],
    ["390", "24", "257"],
  ],

  "05/06/2024 to 05/12/2024": [
    ["147", "27", "250"],
    ["589", "21", "335"],
    ["116", "83", "670"],
    ["359", "79", "180"],
    ["668", "01", "489"],
    ["158", "44", "789"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["234", "98", "369"],
    ["149", "40", "569"],
    ["347", "45", "249"],
    ["568", "96", "457"],
    ["267", "53", "779"],
    ["238", "30", "190"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["156", "26", "150"],
    ["349", "66", "114"],
    ["133", "70", "118"],
    ["136", "02", "589"],
    ["399", "16", "240"],
    ["347", "42", "147"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["370", "00", "578"],
    ["259", "63", "120"],
    ["126", "94", "130"],
    ["455", "44", "338"],
    ["400", "46", "178"],
    ["157", "34", "680"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["688", "27", "890"],
    ["178", "63", "599"],
    ["799", "50", "127"],
    ["113", "53", "599"],
    ["269", "72", "390"],
    ["277", "62", "390"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["789", "45", "168"],
    ["660", "26", "268"],
    ["349", "64", "149"],
    ["130", "47", "115"],
    ["880", "66", "457"],
    ["560", "15", "690"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["467", "77", "458"],
    ["123", "66", "358"],
    ["138", "24", "167"],
    ["268", "64", "347"],
    ["489", "13", "445"],
    ["468", "84", "356"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["679", "26", "367"],
    ["450", "91", "335"],
    ["129", "29", "900"],
    ["260", "88", "116"],
    ["578", "04", "400"],
    ["177", "55", "168"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["450", "95", "690"],
    ["148", "37", "467"],
    ["126", "92", "480"],
    ["900", "99", "289"],
    ["458", "70", "226"],
    ["459", "82", "679"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["690", "55", "267"],
    ["560", "15", "267"],
    ["689", "34", "266"],
    ["346", "31", "560"],
    ["256", "39", "234"],
    ["125", "89", "135"],
  ],
};

export const KALYANNIGHT = {
  "01/02/2023 to 01/08/2023": [
    ["790", "68", "459"],
    ["380", "12", "336"],
    ["469", "97", "890"],
    ["699", "43", "157"],
    ["778", "21", "399"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["120", "36", "457"],
    ["459", "87", "359"],
    ["278", "70", "569"],
    ["566", "74", "257"],
    ["690", "51", "678"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["370", "04", "149"],
    ["448", "68", "378"],
    ["169", "60", "370"],
    ["600", "60", "334"],
    ["458", "78", "134"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["580", "36", "790"],
    ["349", "69", "289"],
    ["355", "39", "469"],
    ["***", "**", "***"],
    ["257", "42", "480"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["790", "67", "124"],
    ["677", "03", "788"],
    ["346", "32", "147"],
    ["360", "94", "590"],
    ["137", "11", "146"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["447", "57", "188"],
    ["149", "44", "400"],
    ["259", "61", "380"],
    ["117", "98", "224"],
    ["780", "50", "677"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["140", "57", "458"],
    ["116", "84", "400"],
    ["120", "32", "390"],
    ["488", "02", "679"],
    ["369", "81", "128"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["459", "84", "356"],
    ["670", "37", "890"],
    ["120", "34", "158"],
    ["178", "64", "789"],
    ["670", "37", "269"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["245", "15", "230"],
    ["569", "05", "357"],
    ["690", "59", "135"],
    ["249", "55", "168"],
    ["568", "97", "124"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["780", "58", "134"],
    ["136", "06", "457"],
    ["***", "**", "***"],
    ["247", "30", "677"],
    ["469", "99", "379"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["147", "29", "478"],
    ["789", "40", "136"],
    ["346", "38", "378"],
    ["135", "99", "117"],
    ["129", "29", "568"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["469", "96", "457"],
    ["780", "53", "779"],
    ["358", "65", "177"],
    ["480", "21", "678"],
    ["358", "65", "113"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["668", "03", "238"],
    ["116", "88", "990"],
    ["467", "71", "579"],
    ["135", "94", "266"],
    ["247", "38", "468"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["478", "98", "233"],
    ["468", "84", "257"],
    ["449", "75", "799"],
    ["788", "33", "148"],
    ["299", "04", "220"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["488", "00", "370"],
    ["460", "04", "248"],
    ["266", "41", "146"],
    ["130", "47", "269"],
    ["588", "13", "247"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["167", "41", "399"],
    ["489", "12", "688"],
    ["679", "21", "579"],
    ["446", "40", "578"],
    ["158", "42", "138"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["340", "76", "358"],
    ["589", "20", "145"],
    ["488", "04", "356"],
    ["127", "09", "478"],
    ["458", "74", "239"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["116", "85", "140"],
    ["136", "08", "233"],
    ["223", "74", "699"],
    ["137", "19", "270"],
    ["146", "18", "224"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["146", "15", "168"],
    ["260", "82", "660"],
    ["458", "77", "223"],
    ["559", "92", "156"],
    ["379", "93", "670"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["225", "98", "350"],
    ["188", "77", "700"],
    ["237", "26", "358"],
    ["334", "09", "270"],
    ["677", "04", "112"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["269", "79", "469"],
    ["370", "00", "136"],
    ["356", "44", "167"],
    ["145", "06", "600"],
    ["156", "28", "800"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["899", "63", "157"],
    ["124", "73", "166"],
    ["124", "76", "240"],
    ["800", "85", "168"],
    ["133", "78", "260"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["599", "39", "289"],
    ["249", "56", "330"],
    ["166", "34", "149"],
    ["120", "31", "146"],
    ["156", "26", "349"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["277", "68", "125"],
    ["277", "64", "266"],
    ["233", "80", "370"],
    ["147", "22", "129"],
    ["120", "31", "470"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["390", "21", "579"],
    ["778", "22", "156"],
    ["450", "92", "570"],
    ["280", "04", "248"],
    ["113", "56", "790"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["267", "56", "457"],
    ["570", "22", "390"],
    ["256", "37", "179"],
    ["237", "25", "140"],
    ["358", "69", "478"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["168", "58", "440"],
    ["127", "03", "247"],
    ["268", "60", "578"],
    ["270", "96", "367"],
    ["255", "21", "380"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["450", "90", "244"],
    ["269", "70", "299"],
    ["233", "85", "159"],
    ["290", "15", "113"],
    ["170", "86", "790"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["117", "98", "134"],
    ["148", "38", "378"],
    ["236", "16", "277"],
    ["378", "82", "589"],
    ["160", "70", "226"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["230", "59", "126"],
    ["335", "17", "160"],
    ["290", "17", "160"],
    ["669", "15", "230"],
    ["367", "66", "268"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["560", "19", "126"],
    ["789", "40", "479"],
    ["590", "40", "244"],
    ["199", "97", "115"],
    ["378", "89", "379"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["380", "17", "467"],
    ["458", "77", "340"],
    ["570", "24", "455"],
    ["100", "14", "257"],
    ["360", "96", "259"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["336", "22", "660"],
    ["***", "**", "***"],
    ["477", "89", "379"],
    ["339", "55", "249"],
    ["477", "89", "900"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["345", "28", "125"],
    ["190", "02", "138"],
    ["110", "28", "134"],
    ["580", "32", "110"],
    ["159", "50", "145"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["147", "29", "450"],
    ["168", "55", "230"],
    ["378", "84", "770"],
    ["558", "86", "457"],
    ["189", "81", "678"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["234", "97", "458"],
    ["668", "00", "460"],
    ["134", "80", "226"],
    ["146", "16", "448"],
    ["280", "01", "245"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["378", "87", "890"],
    ["269", "78", "134"],
    ["280", "07", "179"],
    ["189", "84", "680"],
    ["566", "71", "489"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["335", "18", "440"],
    ["122", "57", "359"],
    ["169", "61", "579"],
    ["578", "01", "128"],
    ["114", "60", "668"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["238", "35", "140"],
    ["177", "58", "125"],
    ["660", "29", "559"],
    ["267", "55", "348"],
    ["118", "09", "135"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["345", "23", "256"],
    ["125", "81", "290"],
    ["557", "78", "170"],
    ["470", "11", "470"],
    ["138", "23", "346"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["256", "31", "245"],
    ["237", "25", "690"],
    ["267", "50", "136"],
    ["188", "77", "449"],
    ["390", "21", "236"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["150", "66", "790"],
    ["480", "28", "369"],
    ["390", "29", "289"],
    ["224", "83", "689"],
    ["267", "59", "450"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["127", "02", "246"],
    ["237", "24", "239"],
    ["457", "65", "177"],
    ["790", "65", "140"],
    ["239", "44", "347"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["780", "54", "130"],
    ["248", "40", "127"],
    ["257", "46", "123"],
    ["248", "41", "380"],
    ["246", "23", "580"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["457", "63", "599"],
    ["489", "10", "479"],
    ["123", "69", "568"],
    ["568", "93", "148"],
    ["257", "46", "123"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["248", "40", "127"],
    ["478", "94", "590"],
    ["223", "76", "466"],
    ["169", "67", "223"],
    ["459", "82", "147"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["256", "39", "135"],
    ["170", "86", "367"],
    ["150", "66", "367"],
    ["478", "94", "590"],
    ["780", "58", "378"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["256", "37", "368"],
    ["280", "08", "567"],
    ["259", "67", "160"],
    ["223", "76", "466"],
    ["234", "95", "177"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["127", "04", "239"],
    ["158", "41", "489"],
    ["580", "37", "269"],
    ["339", "59", "117"],
    ["235", "03", "337"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["270", "98", "558"],
    ["169", "67", "223"],
    ["459", "82", "147"],
    ["116", "87", "133"],
    ["800", "82", "390"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["379", "90", "136"],
    ["239", "41", "560"],
    ["279", "86", "349"],
    ["140", "53", "346"],
    ["130", "45", "249"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["160", "79", "360"],
    ["190", "03", "148"],
    ["457", "60", "578"],
    ["140", "53", "157"],
    ["122", "59", "199"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["568", "93", "779"],
    ["770", "42", "345"],
    ["360", "98", "116"],
    ["700", "76", "880"],
    ["257", "42", "570"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["128", "19", "126"],
    ["356", "47", "160"],
    ["158", "47", "223"],
    ["124", "75", "456"],
    ["166", "36", "790"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["139", "36", "448"],
    ["679", "29", "568"],
    ["278", "79", "234"],
    ["144", "97", "458"],
    ["690", "51", "236"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["160", "79", "478"],
    ["268", "68", "468"],
    ["355", "35", "447"],
    ["120", "32", "589"],
    ["***", "**", "***"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["467", "70", "118"],
    ["250", "70", "280"],
    ["148", "34", "338"],
    ["139", "30", "235"],
    ["455", "42", "156"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["289", "95", "230"],
    ["459", "85", "122"],
    ["790", "62", "480"],
    ["279", "80", "569"],
    ["568", "91", "579"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["679", "28", "459"],
    ["238", "31", "227"],
    ["457", "63", "238"],
    ["447", "51", "146"],
    ["168", "54", "400"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["499", "28", "170"],
    ["580", "35", "258"],
    ["778", "24", "699"],
    ["170", "83", "148"],
    ["113", "52", "200"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["289", "90", "677"],
    ["499", "28", "125"],
    ["440", "87", "340"],
    ["268", "66", "457"],
    ["336", "28", "477"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["770", "44", "699"],
    ["226", "06", "790"],
    ["135", "96", "358"],
    ["670", "33", "256"],
    ["456", "52", "480"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["467", "77", "458"],
    ["350", "80", "668"],
    ["570", "21", "128"],
    ["178", "67", "368"],
    ["147", "25", "249"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["890", "74", "130"],
    ["370", "05", "456"],
    ["348", "59", "577"],
    ["788", "33", "689"],
    ["259", "63", "120"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["***", "**", "***"],
    ["356", "44", "239"],
    ["599", "31", "100"],
    ["445", "32", "246"],
    ["679", "29", "469"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["367", "64", "770"],
    ["445", "33", "670"],
    ["380", "15", "357"],
    ["370", "03", "120"],
    ["789", "45", "249"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["244", "01", "470"],
    ["280", "01", "137"],
    ["389", "07", "449"],
    ["367", "65", "348"],
    ["560", "16", "268"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["679", "29", "568"],
    ["560", "16", "457"],
    ["469", "96", "457"],
    ["340", "71", "579"],
    ["250", "75", "258"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["150", "64", "680"],
    ["137", "12", "499"],
    ["167", "42", "336"],
    ["457", "66", "349"],
    ["169", "61", "290"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["560", "16", "268"],
    ["259", "62", "688"],
    ["147", "26", "240"],
    ["490", "32", "138"],
    ["339", "50", "479"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["590", "49", "559"],
    ["138", "22", "570"],
    ["149", "46", "240"],
    ["680", "42", "237"],
    ["159", "55", "140"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["349", "64", "130"],
    ["668", "07", "566"],
    ["569", "05", "159"],
    ["688", "24", "789"],
    ["345", "20", "479"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["160", "75", "159"],
    ["357", "51", "227"],
    ["117", "95", "799"],
    ["223", "77", "359"],
    ["150", "60", "677"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["188", "71", "146"],
    ["490", "33", "490"],
    ["136", "07", "124"],
    ["249", "59", "126"],
    ["330", "68", "170"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["157", "30", "569"],
    ["780", "59", "144"],
    ["788", "34", "338"],
    ["349", "60", "569"],
    ["370", "02", "679"],
  ],
  "06/10/2024 to 06/16/2024": [
    ["449", "78", "125"],
    ["150", "67", "269"],
    ["250", "70", "460"],
    ["259", "60", "488"],
    ["589", "23", "445"],
  ],
  "06/17/2024 to 06/23/2024": [
    ["668", "07", "115"],
    ["278", "76", "268"],
    ["229", "36", "448"],
    ["358", "61", "470"],
    ["169", "68", "440"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["567", "81", "470"],
    ["116", "84", "130"],
    ["459", "84", "239"],
    ["360", "91", "137"],
    ["137", "18", "170"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["368", "74", "248"],
    ["680", "43", "256"],
    ["368", "79", "469"],
    ["112", "47", "359"],
    ["578", "05", "799"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["127", "00", "370"],
    ["169", "60", "460"],
    ["588", "12", "255"],
    ["238", "31", "236"],
    ["128", "12", "147"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["690", "57", "557"],
    ["269", "77", "179"],
    ["470", "15", "348"],
    ["225", "97", "278"],
    ["368", "77", "467"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["340", "78", "378"],
    ["157", "37", "890"],
    ["134", "84", "455"],
    ["560", "13", "580"],
    ["669", "13", "139"],
  ],
  "07/29/2024 to 08/04/2024": [
    ["689", "39", "270"],
    ["148", "32", "345"],
    ["367", "69", "289"],
    ["234", "90", "145"],
    ["238", "36", "114"],
  ],
  "08/05/2024 to 08/11/2024": [
    ["158", "46", "150"],
    ["128", "16", "330"],
    ["334", "02", "570"],
    ["458", "70", "235"],
    ["379", "97", "179"],
  ],
  "08/12/2024 to 08/18/2024": [
    ["157", "34", "356"],
    ["459", "85", "168"],
    ["880", "61", "470"],
    ["***", "**", "***"],
    ["258", "52", "246"],
  ],
  "08/19/2024 to 08/25/2024": [
    ["279", "81", "669"],
    ["146", "14", "789"],
    ["690", "52", "336"],
    ["257", "41", "489"],
    ["229", "38", "459"],
  ],
  "08/26/2024 to 09/01/2024": [
    ["390", "21", "119"],
    ["790", "61", "380"],
    ["134", "82", "390"],
    ["490", "31", "227"],
    ["460", "01", "236"],
  ],
};

export const SRIDEVINIGHT = {
  "01/01/2024 to 01/07/2024": [
    ["800", "87", "377"],
    ["467", "70", "334"],
    ["477", "86", "169"],
    ["890", "70", "244"],
    ["228", "26", "899"],
    ["450", "93", "490"],
    ["122", "56", "268"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["448", "61", "236"],
    ["668", "04", "248"],
    ["147", "22", "129"],
    ["488", "01", "128"],
    ["119", "18", "459"],
    ["257", "40", "190"],
    ["699", "41", "489"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["146", "19", "289"],
    ["180", "94", "347"],
    ["568", "93", "490"],
    ["134", "82", "138"],
    ["457", "68", "567"],
    ["236", "11", "290"],
    ["458", "71", "245"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["266", "47", "188"],
    ["134", "85", "447"],
    ["448", "65", "348"],
    ["478", "98", "189"],
    ["***", "**", "***"],
    ["129", "21", "146"],
    ["346", "39", "360"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["678", "19", "225"],
    ["156", "23", "670"],
    ["369", "87", "179"],
    ["125", "82", "679"],
    ["167", "40", "569"],
    ["690", "59", "180"],
    ["259", "64", "699"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["338", "44", "239"],
    ["279", "88", "990"],
    ["367", "60", "578"],
    ["334", "08", "350"],
    ["459", "83", "346"],
    ["660", "22", "390"],
    ["356", "48", "477"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["246", "22", "237"],
    ["125", "88", "260"],
    ["446", "45", "267"],
    ["790", "60", "136"],
    ["359", "77", "890"],
    ["577", "92", "255"],
    ["559", "90", "370"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["789", "48", "369"],
    ["600", "60", "190"],
    ["357", "51", "470"],
    ["169", "65", "230"],
    ["179", "79", "126"],
    ["788", "33", "256"],
    ["246", "26", "457"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["255", "27", "359"],
    ["137", "17", "115"],
    ["260", "80", "226"],
    ["140", "58", "477"],
    ["267", "58", "279"],
    ["478", "92", "679"],
    ["290", "15", "258"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["880", "69", "270"],
    ["479", "00", "668"],
    ["334", "08", "134"],
    ["579", "19", "568"],
    ["250", "79", "289"],
    ["128", "15", "249"],
    ["157", "39", "568"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["578", "07", "160"],
    ["255", "28", "189"],
    ["127", "09", "180"],
    ["470", "16", "880"],
    ["557", "74", "770"],
    ["669", "16", "178"],
    ["589", "28", "279"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["122", "55", "258"],
    ["455", "48", "459"],
    ["344", "15", "267"],
    ["800", "81", "227"],
    ["226", "09", "117"],
    ["677", "05", "249"],
    ["457", "61", "155"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["***", "**", "***"],
    ["447", "51", "236"],
    ["156", "27", "223"],
    ["160", "74", "699"],
    ["167", "49", "559"],
    ["247", "39", "379"],
    ["467", "78", "125"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["370", "07", "250"],
    ["299", "00", "668"],
    ["289", "97", "557"],
    ["400", "48", "567"],
    ["388", "95", "122"],
    ["158", "43", "670"],
    ["236", "12", "570"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["334", "07", "458"],
    ["112", "44", "257"],
    ["500", "56", "349"],
    ["990", "80", "668"],
    ["289", "90", "479"],
    ["260", "81", "290"],
    ["689", "35", "113"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["180", "97", "467"],
    ["499", "21", "245"],
    ["135", "94", "266"],
    ["236", "14", "257"],
    ["139", "36", "358"],
    ["158", "41", "560"],
    ["567", "83", "490"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["138", "24", "149"],
    ["566", "73", "445"],
    ["269", "78", "233"],
    ["369", "86", "240"],
    ["168", "55", "159"],
    ["137", "14", "239"],
    ["169", "60", "127"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["140", "55", "258"],
    ["156", "21", "489"],
    ["679", "20", "190"],
    ["125", "85", "159"],
    ["490", "35", "690"],
    ["579", "14", "130"],
    ["128", "13", "238"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["457", "64", "446"],
    ["230", "57", "269"],
    ["679", "23", "580"],
    ["258", "51", "678"],
    ["138", "22", "345"],
    ["128", "17", "467"],
    ["990", "84", "257"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["259", "61", "489"],
    ["290", "16", "259"],
    ["440", "80", "569"],
    ["239", "44", "158"],
    ["479", "07", "890"],
    ["127", "01", "678"],
    ["270", "93", "346"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["450", "98", "233"],
    ["340", "77", "377"],
    ["458", "72", "480"],
    ["347", "41", "344"],
    ["667", "90", "145"],
    ["244", "07", "223"],
    ["257", "47", "269"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["800", "89", "117"],
    ["446", "41", "236"],
    ["136", "02", "237"],
    ["257", "45", "249"],
    ["369", "88", "459"],
    ["166", "35", "690"],
    ["789", "40", "668"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["266", "45", "113"],
    ["150", "62", "138"],
    ["259", "60", "389"],
    ["580", "35", "267"],
    ["358", "62", "660"],
    ["578", "00", "479"],
    ["269", "72", "688"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["299", "03", "689"],
    ["145", "03", "157"],
    ["158", "44", "789"],
    ["237", "25", "249"],
    ["239", "49", "135"],
    ["259", "64", "130"],
    ["238", "39", "270"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["890", "78", "224"],
    ["345", "21", "579"],
    ["479", "04", "158"],
    ["445", "36", "600"],
    ["337", "39", "360"],
    ["145", "00", "479"],
    ["556", "60", "578"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["370", "01", "146"],
    ["778", "29", "126"],
    ["349", "61", "678"],
    ["689", "31", "489"],
    ["580", "31", "290"],
    ["368", "79", "469"],
    ["458", "76", "240"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["159", "53", "580"],
    ["679", "22", "246"],
    ["557", "78", "558"],
    ["229", "30", "677"],
    ["448", "61", "146"],
    ["126", "90", "235"],
    ["229", "30", "389"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["367", "61", "119"],
    ["247", "35", "339"],
    ["133", "78", "189"],
    ["120", "37", "340"],
    ["113", "59", "234"],
    ["400", "41", "579"],
    ["110", "23", "256"],
  ],
};

export const MILANNIGHT = {
  "01/02/2023 to 01/08/2023": [
    ["345", "26", "268"],
    ["370", "04", "167"],
    ["124", "70", "488"],
    ["900", "96", "268"],
    ["459", "81", "588"],
    ["136", "07", "467"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["177", "59", "234"],
    ["127", "07", "890"],
    ["479", "03", "346"],
    ["456", "54", "356"],
    ["478", "92", "246"],
    ["568", "97", "160"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["460", "00", "569"],
    ["246", "25", "500"],
    ["220", "43", "779"],
    ["160", "71", "380"],
    ["336", "29", "180"],
    ["370", "06", "899"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["246", "21", "128"],
    ["680", "48", "468"],
    ["577", "91", "678"],
    ["***", "**", "***"],
    ["459", "80", "370"],
    ["114", "69", "117"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["224", "81", "100"],
    ["259", "67", "467"],
    ["799", "53", "599"],
    ["224", "82", "778"],
    ["370", "03", "148"],
    ["246", "26", "790"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["568", "98", "440"],
    ["669", "13", "490"],
    ["239", "42", "345"],
    ["447", "51", "669"],
    ["170", "89", "388"],
    ["455", "44", "248"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["240", "60", "334"],
    ["600", "67", "269"],
    ["780", "51", "236"],
    ["236", "19", "900"],
    ["368", "71", "470"],
    ["237", "25", "230"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["117", "91", "290"],
    ["266", "45", "799"],
    ["458", "75", "456"],
    ["136", "02", "147"],
    ["334", "01", "146"],
    ["367", "63", "148"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["237", "26", "114"],
    ["590", "47", "449"],
    ["335", "17", "133"],
    ["270", "98", "224"],
    ["459", "80", "235"],
    ["267", "53", "689"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["113", "54", "770"],
    ["130", "49", "126"],
    ["***", "**", "***"],
    ["240", "61", "470"],
    ["358", "65", "366"],
    ["245", "17", "278"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["600", "66", "556"],
    ["499", "28", "567"],
    ["290", "12", "237"],
    ["228", "23", "355"],
    ["446", "41", "119"],
    ["345", "25", "122"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["440", "88", "990"],
    ["123", "66", "600"],
    ["139", "30", "299"],
    ["256", "38", "468"],
    ["137", "14", "680"],
    ["149", "44", "130"],
  ],
  "01/02/2023 to 01/08/2023": [
    ["345", "26", "268"],
    ["370", "04", "167"],
    ["124", "70", "488"],
    ["900", "96", "268"],
    ["459", "81", "588"],
    ["136", "07", "467"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["177", "59", "234"],
    ["127", "07", "890"],
    ["479", "03", "346"],
    ["456", "54", "356"],
    ["478", "92", "246"],
    ["568", "97", "160"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["460", "00", "569"],
    ["246", "25", "500"],
    ["220", "43", "779"],
    ["160", "71", "380"],
    ["336", "29", "180"],
    ["370", "06", "899"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["246", "21", "128"],
    ["680", "48", "468"],
    ["577", "91", "678"],
    ["***", "**", "***"],
    ["459", "80", "370"],
    ["114", "69", "117"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["224", "81", "100"],
    ["259", "67", "467"],
    ["799", "53", "599"],
    ["224", "82", "778"],
    ["370", "03", "148"],
    ["246", "26", "790"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["568", "98", "440"],
    ["669", "13", "490"],
    ["239", "42", "345"],
    ["447", "51", "669"],
    ["170", "89", "388"],
    ["455", "44", "248"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["240", "60", "334"],
    ["600", "67", "269"],
    ["780", "51", "236"],
    ["236", "19", "900"],
    ["368", "71", "470"],
    ["237", "25", "230"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["117", "91", "290"],
    ["266", "45", "799"],
    ["458", "75", "456"],
    ["136", "02", "147"],
    ["334", "01", "146"],
    ["367", "63", "148"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["237", "26", "114"],
    ["590", "47", "449"],
    ["335", "17", "133"],
    ["270", "98", "224"],
    ["459", "80", "235"],
    ["267", "53", "689"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["113", "54", "770"],
    ["130", "49", "126"],
    ["***", "**", "***"],
    ["240", "61", "470"],
    ["358", "65", "366"],
    ["245", "17", "278"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["600", "66", "556"],
    ["499", "28", "567"],
    ["290", "12", "237"],
    ["228", "23", "355"],
    ["446", "41", "119"],
    ["345", "25", "122"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["440", "88", "990"],
    ["123", "66", "600"],
    ["139", "30", "299"],
    ["256", "38", "468"],
    ["137", "14", "680"],
    ["149", "44", "130"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["125", "80", "578"],
    ["229", "36", "150"],
    ["277", "63", "229"],
    ["125", "86", "169"],
    ["200", "20", "569"],
    ["135", "90", "569"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["570", "27", "124"],
    ["249", "57", "115"],
    ["580", "30", "226"],
    ["334", "03", "139"],
    ["338", "45", "113"],
    ["169", "62", "246"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["669", "12", "110"],
    ["288", "85", "140"],
    ["257", "46", "150"],
    ["345", "21", "560"],
    ["335", "16", "268"],
    ["560", "13", "157"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["457", "69", "667"],
    ["457", "64", "770"],
    ["168", "51", "290"],
    ["458", "71", "245"],
    ["160", "72", "129"],
    ["237", "29", "568"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["567", "89", "270"],
    ["245", "10", "389"],
    ["130", "45", "357"],
    ["129", "29", "388"],
    ["366", "56", "240"],
    ["139", "34", "338"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["124", "72", "147"],
    ["335", "10", "578"],
    ["578", "05", "889"],
    ["570", "26", "123"],
    ["128", "16", "358"],
    ["225", "93", "788"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["139", "30", "127"],
    ["237", "26", "150"],
    ["278", "76", "790"],
    ["455", "48", "170"],
    ["233", "82", "147"],
    ["249", "55", "249"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["490", "32", "147"],
    ["790", "62", "345"],
    ["259", "64", "699"],
    ["668", "09", "117"],
    ["100", "13", "238"],
    ["236", "19", "568"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["126", "99", "126"],
    ["200", "28", "990"],
    ["680", "49", "577"],
    ["358", "68", "233"],
    ["258", "57", "269"],
    ["460", "09", "270"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["577", "90", "370"],
    ["116", "88", "567"],
    ["450", "98", "558"],
    ["770", "49", "379"],
    ["259", "67", "160"],
    ["240", "64", "347"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["234", "93", "490"],
    ["588", "19", "450"],
    ["255", "23", "337"],
    ["349", "60", "370"],
    ["567", "85", "258"],
    ["589", "24", "680"],
  ],
  "01/02/2023 to 01/08/2023": [
    ["345", "26", "268"],
    ["370", "04", "167"],
    ["124", "70", "488"],
    ["900", "96", "268"],
    ["459", "81", "588"],
    ["136", "07", "467"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["177", "59", "234"],
    ["127", "07", "890"],
    ["479", "03", "346"],
    ["456", "54", "356"],
    ["478", "92", "246"],
    ["568", "97", "160"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["460", "00", "569"],
    ["246", "25", "500"],
    ["220", "43", "779"],
    ["160", "71", "380"],
    ["336", "29", "180"],
    ["370", "06", "899"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["246", "21", "128"],
    ["680", "48", "468"],
    ["577", "91", "678"],
    ["459", "80", "370"],
    ["114", "69", "117"],
    ["246", "25", "500"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["224", "81", "100"],
    ["259", "67", "467"],
    ["799", "53", "599"],
    ["224", "82", "778"],
    ["370", "03", "148"],
    ["246", "26", "790"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["568", "98", "440"],
    ["669", "13", "490"],
    ["239", "42", "345"],
    ["447", "51", "669"],
    ["170", "89", "388"],
    ["455", "44", "248"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["240", "60", "334"],
    ["600", "67", "269"],
    ["780", "51", "236"],
    ["236", "19", "900"],
    ["368", "71", "470"],
    ["237", "25", "230"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["117", "91", "290"],
    ["266", "45", "799"],
    ["458", "75", "456"],
    ["136", "02", "147"],
    ["334", "01", "146"],
    ["367", "63", "148"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["237", "26", "114"],
    ["590", "47", "449"],
    ["335", "17", "133"],
    ["270", "98", "224"],
    ["459", "80", "235"],
    ["267", "53", "689"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["113", "54", "770"],
    ["130", "49", "126"],
    ["***", "**", "***"],
    ["240", "61", "470"],
    ["358", "65", "366"],
    ["245", "17", "278"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["600", "66", "556"],
    ["499", "28", "567"],
    ["290", "12", "237"],
    ["228", "23", "355"],
    ["446", "41", "119"],
    ["345", "25", "122"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["440", "88", "990"],
    ["123", "66", "600"],
    ["139", "30", "299"],
    ["256", "38", "468"],
    ["137", "14", "680"],
    ["149", "44", "130"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["125", "80", "578"],
    ["229", "36", "150"],
    ["277", "63", "229"],
    ["125", "86", "169"],
    ["200", "20", "569"],
    ["135", "90", "569"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["570", "27", "124"],
    ["249", "57", "115"],
    ["580", "30", "226"],
    ["334", "03", "139"],
    ["338", "45", "113"],
    ["169", "62", "246"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["669", "12", "110"],
    ["288", "85", "140"],
    ["257", "46", "150"],
    ["345", "21", "560"],
    ["335", "16", "268"],
    ["560", "13", "157"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["457", "69", "667"],
    ["457", "64", "770"],
    ["168", "51", "290"],
    ["458", "71", "245"],
    ["160", "72", "129"],
    ["237", "29", "568"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["567", "89", "270"],
    ["245", "10", "389"],
    ["130", "45", "357"],
    ["129", "29", "388"],
    ["366", "56", "240"],
    ["139", "34", "338"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["124", "72", "147"],
    ["335", "10", "578"],
    ["578", "05", "889"],
    ["570", "26", "123"],
    ["128", "16", "358"],
    ["225", "93", "788"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["139", "30", "127"],
    ["237", "26", "150"],
    ["278", "76", "790"],
    ["455", "48", "170"],
    ["233", "82", "147"],
    ["249", "55", "249"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["490", "32", "147"],
    ["790", "62", "345"],
    ["259", "64", "699"],
    ["668", "09", "117"],
    ["100", "13", "238"],
    ["236", "19", "568"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["126", "99", "126"],
    ["200", "28", "990"],
    ["680", "49", "577"],
    ["358", "68", "233"],
    ["258", "57", "269"],
    ["460", "09", "270"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["577", "90", "370"],
    ["116", "88", "567"],
    ["450", "98", "558"],
    ["770", "49", "379"],
    ["259", "67", "160"],
    ["240", "64", "347"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["234", "93", "490"],
    ["588", "19", "450"],
    ["255", "23", "337"],
    ["349", "60", "370"],
    ["567", "85", "258"],
    ["589", "24", "680"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["899", "62", "200"],
    ["667", "90", "578"],
    ["240", "65", "177"],
    ["799", "52", "246"],
    ["148", "34", "699"],
    ["148", "32", "345"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["229", "33", "670"],
    ["445", "32", "246"],
    ["349", "69", "450"],
    ["129", "29", "135"],
    ["348", "57", "223"],
    ["346", "31", "119"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["144", "99", "289"],
    ["477", "82", "390"],
    ["489", "16", "457"],
    ["335", "16", "790"],
    ["167", "42", "570"],
    ["345", "27", "458"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["148", "38", "369"],
    ["456", "56", "880"],
    ["780", "59", "478"],
    ["689", "33", "238"],
    ["112", "44", "167"],
    ["170", "85", "690"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["220", "44", "347"],
    ["666", "85", "339"],
    ["125", "85", "267"],
    ["550", "01", "399"],
    ["120", "38", "350"],
    ["368", "71", "245"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["130", "49", "270"],
    ["334", "07", "566"],
    ["489", "11", "560"],
    ["889", "53", "238"],
    ["234", "93", "157"],
    ["289", "95", "456"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["246", "20", "136"],
    ["550", "04", "266"],
    ["225", "99", "289"],
    ["146", "18", "233"],
    ["248", "45", "267"],
    ["578", "04", "590"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["260", "88", "468"],
    ["128", "17", "133"],
    ["126", "91", "380"],
    ["359", "79", "667"],
    ["470", "10", "145"],
    ["115", "72", "679"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["180", "98", "558"],
    ["467", "72", "129"],
    ["378", "85", "168"],
    ["256", "33", "490"],
    ["128", "19", "568"],
    ["370", "05", "249"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["129", "24", "257"],
    ["130", "43", "247"],
    ["678", "11", "380"],
    ["590", "44", "257"],
    ["770", "40", "488"],
    ["459", "82", "237"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["499", "28", "260"],
    ["377", "78", "567"],
    ["227", "16", "457"],
    ["770", "47", "133"],
    ["770", "40", "488"],
    ["678", "11", "380"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["789", "42", "345"],
    ["788", "39", "234"],
    ["490", "37", "467"],
    ["240", "60", "389"],
    ["689", "36", "367"],
    ["136", "09", "180"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["125", "80", "578"],
    ["229", "36", "150"],
    ["277", "63", "229"],
    ["125", "86", "169"],
    ["200", "20", "569"],
    ["135", "90", "569"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["570", "27", "124"],
    ["249", "57", "115"],
    ["580", "30", "226"],
    ["334", "03", "139"],
    ["338", "45", "113"],
    ["169", "62", "246"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["669", "12", "110"],
    ["288", "85", "140"],
    ["257", "46", "150"],
    ["345", "21", "560"],
    ["335", "16", "268"],
    ["560", "13", "157"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["457", "69", "667"],
    ["457", "64", "770"],
    ["168", "51", "290"],
    ["458", "71", "245"],
    ["160", "72", "129"],
    ["237", "29", "568"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["567", "89", "270"],
    ["245", "10", "389"],
    ["130", "45", "357"],
    ["129", "29", "388"],
    ["366", "56", "240"],
    ["139", "34", "338"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["124", "72", "147"],
    ["335", "10", "578"],
    ["578", "05", "889"],
    ["570", "26", "123"],
    ["128", "16", "358"],
    ["225", "93", "788"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["139", "30", "127"],
    ["237", "26", "150"],
    ["278", "76", "790"],
    ["455", "48", "170"],
    ["233", "82", "147"],
    ["249", "55", "249"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["490", "32", "147"],
    ["790", "62", "345"],
    ["259", "64", "699"],
    ["668", "09", "117"],
    ["100", "13", "238"],
    ["236", "19", "568"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["126", "99", "126"],
    ["200", "28", "990"],
    ["680", "49", "577"],
    ["358", "68", "233"],
    ["258", "57", "269"],
    ["460", "09", "270"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["577", "90", "370"],
    ["116", "88", "567"],
    ["450", "98", "558"],
    ["770", "49", "379"],
    ["259", "67", "160"],
    ["240", "64", "347"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["234", "93", "490"],
    ["588", "19", "450"],
    ["255", "23", "337"],
    ["349", "60", "370"],
    ["567", "85", "258"],
    ["589", "24", "680"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["899", "62", "200"],
    ["667", "90", "578"],
    ["240", "65", "177"],
    ["799", "52", "246"],
    ["148", "34", "699"],
    ["148", "32", "345"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["229", "33", "670"],
    ["445", "32", "246"],
    ["349", "69", "450"],
    ["129", "29", "135"],
    ["348", "57", "223"],
    ["346", "31", "119"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["144", "99", "289"],
    ["477", "82", "390"],
    ["489", "16", "457"],
    ["335", "16", "790"],
    ["167", "42", "570"],
    ["345", "27", "458"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["148", "38", "369"],
    ["456", "56", "880"],
    ["780", "59", "478"],
    ["689", "33", "238"],
    ["112", "44", "167"],
    ["170", "85", "690"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["220", "44", "347"],
    ["666", "85", "339"],
    ["125", "85", "267"],
    ["550", "01", "399"],
    ["120", "38", "350"],
    ["368", "71", "245"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["130", "49", "270"],
    ["334", "07", "566"],
    ["489", "11", "560"],
    ["889", "53", "238"],
    ["234", "93", "157"],
    ["289", "95", "456"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["246", "20", "136"],
    ["550", "04", "266"],
    ["225", "99", "289"],
    ["146", "18", "233"],
    ["248", "45", "267"],
    ["578", "04", "590"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["260", "88", "468"],
    ["128", "17", "133"],
    ["126", "91", "380"],
    ["359", "79", "667"],
    ["470", "10", "145"],
    ["115", "72", "679"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["180", "98", "558"],
    ["467", "72", "129"],
    ["378", "85", "168"],
    ["256", "33", "490"],
    ["128", "19", "568"],
    ["370", "05", "249"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["129", "24", "257"],
    ["130", "43", "247"],
    ["678", "11", "380"],
    ["590", "44", "257"],
    ["459", "82", "237"],
    ["467", "72", "129"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["***", "**", "***"],
    ["499", "28", "260"],
    ["377", "78", "567"],
    ["227", "16", "457"],
    ["770", "47", "133"],
    ["770", "40", "488"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["789", "42", "345"],
    ["788", "39", "234"],
    ["490", "37", "467"],
    ["240", "60", "389"],
    ["689", "36", "367"],
    ["136", "09", "180"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["399", "19", "360"],
    ["348", "57", "250"],
    ["240", "60", "569"],
    ["147", "20", "299"],
    ["569", "00", "235"],
    ["378", "84", "789"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["890", "70", "677"],
    ["237", "26", "349"],
    ["889", "51", "290"],
    ["568", "93", "229"],
    ["180", "97", "377"],
    ["458", "78", "116"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["147", "25", "348"],
    ["125", "89", "450"],
    ["234", "97", "160"],
    ["460", "04", "680"],
    ["490", "34", "266"],
    ["112", "43", "490"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["458", "73", "247"],
    ["380", "15", "780"],
    ["136", "05", "500"],
    ["256", "39", "117"],
    ["269", "77", "340"],
    ["468", "80", "578"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["670", "32", "129"],
    ["257", "47", "340"],
    ["233", "85", "348"],
    ["247", "32", "570"],
    ["158", "43", "670"],
    ["130", "40", "190"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["470", "15", "249"],
    ["279", "86", "457"],
    ["126", "99", "360"],
    ["580", "38", "567"],
    ["469", "99", "450"],
    ["120", "38", "558"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["560", "13", "599"],
    ["170", "84", "158"],
    ["578", "06", "448"],
    ["240", "65", "168"],
    ["360", "98", "260"],
    ["669", "16", "268"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["689", "38", "125"],
    ["345", "26", "123"],
    ["334", "02", "778"],
    ["578", "02", "336"],
    ["580", "39", "126"],
    ["348", "57", "160"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["126", "90", "479"],
    ["137", "19", "289"],
    ["889", "56", "240"],
    ["790", "69", "117"],
    ["345", "23", "139"],
    ["466", "62", "480"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["114", "65", "249"],
    ["128", "10", "578"],
    ["889", "56", "240"],
    ["678", "19", "379"],
    ["345", "26", "123"],
    ["268", "68", "189"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["334", "08", "800"],
    ["889", "56", "240"],
    ["237", "23", "256"],
    ["359", "71", "146"],
    ["466", "62", "480"],
    ["345", "26", "123"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["189", "82", "255"],
    ["120", "34", "248"],
    ["447", "51", "588"],
    ["449", "77", "467"],
    ["356", "45", "348"],
    ["669", "15", "348"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["399", "14", "446"],
    ["234", "99", "568"],
    ["378", "84", "239"],
    ["457", "64", "400"],
    ["334", "08", "800"],
    ["237", "23", "256"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["144", "90", "578"],
    ["156", "24", "446"],
    ["680", "48", "990"],
    ["268", "68", "125"],
    ["159", "57", "368"],
    ["359", "71", "146"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["559", "94", "789"],
    ["234", "95", "267"],
    ["467", "76", "268"],
    ["890", "79", "135"],
    ["249", "50", "389"],
    ["359", "76", "123"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["340", "77", "890"],
    ["167", "41", "678"],
    ["267", "53", "256"],
    ["113", "54", "347"],
    ["255", "21", "137"],
    ["268", "63", "148"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["400", "42", "778"],
    ["467", "75", "113"],
    ["588", "14", "248"],
    ["359", "79", "126"],
    ["600", "67", "467"],
    ["560", "15", "267"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["230", "50", "280"],
    ["279", "88", "288"],
    ["459", "80", "488"],
    ["568", "96", "349"],
    ["150", "69", "270"],
    ["340", "79", "478"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["790", "61", "777"],
    ["299", "02", "480"],
    ["889", "50", "190"],
    ["349", "68", "468"],
    ["127", "02", "345"],
    ["156", "27", "250"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["890", "77", "188"],
    ["145", "08", "189"],
    ["369", "88", "440"],
    ["244", "01", "380"],
    ["890", "77", "458"],
    ["290", "18", "369"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["568", "91", "146"],
    ["169", "61", "380"],
    ["570", "28", "279"],
    ["123", "64", "149"],
    ["789", "44", "789"],
    ["369", "88", "440"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["137", "12", "480"],
    ["456", "50", "145"],
    ["226", "05", "456"],
    ["135", "99", "478"],
    ["550", "05", "357"],
    ["145", "04", "257"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["250", "70", "190"],
    ["180", "95", "159"],
    ["134", "87", "368"],
    ["134", "81", "245"],
    ["239", "41", "678"],
    ["249", "58", "189"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["149", "40", "578"],
    ["123", "66", "178"],
    ["158", "44", "149"],
    ["456", "52", "480"],
    ["118", "05", "230"],
    ["358", "62", "246"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["225", "97", "115"],
    ["156", "20", "479"],
    ["455", "40", "479"],
    ["235", "08", "666"],
    ["790", "67", "449"],
    ["670", "36", "358"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["460", "01", "155"],
    ["569", "05", "780"],
    ["699", "46", "178"],
    ["234", "94", "455"],
    ["129", "27", "250"],
    ["450", "92", "570"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["560", "17", "368"],
    ["449", "70", "280"],
    ["600", "63", "229"],
    ["248", "47", "890"],
    ["568", "95", "258"],
    ["237", "24", "699"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["678", "13", "166"],
    ["180", "98", "260"],
    ["370", "01", "579"],
    ["160", "78", "350"],
    ["159", "53", "670"],
    ["236", "19", "180"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["347", "44", "248"],
    ["578", "01", "245"],
    ["146", "12", "156"],
    ["399", "16", "240"],
    ["238", "34", "347"],
    ["240", "66", "349"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["399", "16", "240"],
    ["157", "37", "340"],
    ["378", "84", "257"],
    ["670", "30", "569"],
    ["267", "57", "250"],
    ["257", "46", "358"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["169", "67", "269"],
    ["178", "62", "228"],
    ["689", "39", "270"],
    ["244", "04", "680"],
    ["689", "32", "138"],
    ["800", "88", "224"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["126", "91", "399"],
    ["160", "78", "567"],
    ["230", "51", "560"],
    ["280", "04", "347"],
    ["578", "06", "358"],
    ["139", "35", "168"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["455", "41", "137"],
    ["479", "03", "238"],
    ["467", "73", "490"],
    ["670", "39", "478"],
    ["779", "39", "360"],
    ["669", "10", "578"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["126", "90", "569"],
    ["179", "71", "560"],
    ["178", "69", "478"],
    ["148", "31", "669"],
    ["240", "65", "168"],
    ["146", "13", "148"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["278", "78", "459"],
    ["155", "19", "379"],
    ["256", "39", "900"],
    ["336", "29", "180"],
    ["235", "02", "129"],
    ["280", "09", "450"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["468", "82", "129"],
    ["400", "40", "244"],
    ["137", "17", "449"],
    ["239", "43", "247"],
    ["279", "89", "478"],
    ["478", "91", "137"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["445", "35", "140"],
    ["167", "43", "346"],
    ["167", "46", "880"],
    ["590", "42", "589"],
    ["350", "84", "158"],
    ["149", "47", "557"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["590", "40", "127"],
    ["246", "25", "690"],
    ["350", "86", "349"],
    ["249", "57", "160"],
    ["789", "43", "139"],
    ["249", "57", "250"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["380", "19", "478"],
    ["170", "89", "379"],
    ["157", "35", "230"],
    ["148", "32", "679"],
    ["269", "76", "268"],
    ["230", "59", "568"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["567", "85", "267"],
    ["358", "63", "148"],
    ["680", "43", "788"],
    ["148", "30", "280"],
    ["137", "15", "889"],
    ["557", "79", "360"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["369", "82", "237"],
    ["170", "86", "790"],
    ["779", "31", "588"],
    ["337", "33", "346"],
    ["126", "95", "113"],
    ["778", "23", "689"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["688", "25", "230"],
    ["358", "68", "279"],
    ["380", "14", "112"],
    ["660", "28", "558"],
    ["350", "88", "468"],
    ["156", "26", "268"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["358", "62", "156"],
    ["790", "62", "228"],
    ["179", "72", "147"],
    ["269", "76", "123"],
    ["140", "54", "239"],
    ["790", "68", "170"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["789", "48", "260"],
    ["239", "43", "256"],
    ["157", "36", "349"],
    ["347", "40", "677"],
    ["458", "72", "679"],
    ["226", "08", "125"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["459", "88", "369"],
    ["136", "06", "268"],
    ["236", "11", "678"],
    ["789", "41", "236"],
    ["450", "90", "190"],
    ["780", "55", "159"],
  ],
};

export const RAJDHANINIGHT = {
  "01/02/2023 to 01/08/2023": [
    ["334", "02", "336"],
    ["169", "67", "179"],
    ["490", "38", "378"],
    ["147", "23", "355"],
    ["137", "17", "890"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["689", "34", "158"],
    ["177", "58", "468"],
    ["150", "60", "127"],
    ["145", "06", "457"],
    ["799", "56", "349"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["117", "97", "557"],
    ["179", "72", "228"],
    ["360", "98", "134"],
    ["470", "18", "224"],
    ["179", "75", "339"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["125", "89", "289"],
    ["133", "76", "349"],
    ["255", "21", "560"],
    ["***", "**", "***"],
    ["167", "40", "370"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["129", "24", "770"],
    ["566", "73", "580"],
    ["239", "40", "280"],
    ["340", "78", "170"],
    ["138", "23", "148"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["458", "76", "790"],
    ["590", "46", "600"],
    ["288", "87", "890"],
    ["155", "15", "159"],
    ["138", "28", "134"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["258", "53", "148"],
    ["345", "21", "119"],
    ["136", "08", "116"],
    ["688", "21", "245"],
    ["790", "69", "289"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["458", "70", "370"],
    ["189", "80", "226"],
    ["125", "84", "680"],
    ["138", "21", "489"],
    ["800", "82", "480"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["146", "18", "189"],
    ["569", "01", "579"],
    ["147", "23", "229"],
    ["279", "82", "147"],
    ["126", "93", "247"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["246", "23", "256"],
    ["550", "09", "379"],
    ["115", "70", "479"],
    ["566", "78", "468"],
    ["456", "53", "148"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["289", "98", "233"],
    ["160", "74", "590"],
    ["256", "37", "133"],
    ["348", "56", "358"],
    ["115", "70", "479"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["146", "19", "270"],
    ["340", "74", "149"],
    ["468", "85", "177"],
    ["389", "03", "490"],
    ["130", "42", "129"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["578", "04", "266"],
    ["490", "34", "680"],
    ["170", "87", "566"],
    ["127", "03", "139"],
    ["136", "05", "690"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["900", "94", "266"],
    ["459", "85", "447"],
    ["677", "09", "379"],
    ["270", "94", "130"],
    ["780", "59", "289"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["250", "77", "269"],
    ["389", "04", "149"],
    ["223", "72", "688"],
    ["469", "92", "499"],
    ["457", "65", "780"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["680", "44", "257"],
    ["335", "19", "360"],
    ["379", "95", "267"],
    ["358", "68", "567"],
    ["249", "51", "290"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["789", "42", "679"],
    ["228", "22", "147"],
    ["245", "12", "228"],
    ["189", "83", "300"],
    ["358", "63", "157"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["339", "51", "678"],
    ["358", "67", "250"],
    ["567", "83", "120"],
    ["147", "25", "799"],
    ["129", "25", "159"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["677", "02", "110"],
    ["145", "00", "235"],
    ["340", "73", "139"],
    ["158", "46", "114"],
    ["245", "14", "239"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["288", "84", "789"],
    ["456", "59", "469"],
    ["678", "19", "234"],
    ["369", "86", "330"],
    ["110", "24", "149"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["145", "05", "780"],
    ["457", "67", "160"],
    ["290", "13", "256"],
    ["245", "17", "359"],
    ["345", "20", "389"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["224", "88", "459"],
    ["236", "10", "677"],
    ["355", "39", "469"],
    ["389", "00", "479"],
    ["556", "65", "357"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["379", "95", "230"],
    ["179", "77", "160"],
    ["589", "24", "167"],
    ["588", "18", "134"],
    ["560", "13", "247"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["246", "21", "489"],
    ["289", "93", "337"],
    ["123", "61", "245"],
    ["477", "83", "788"],
    ["150", "60", "190"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["688", "23", "490"],
    ["356", "40", "299"],
    ["690", "57", "467"],
    ["679", "26", "259"],
    ["220", "45", "348"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["367", "61", "678"],
    ["150", "67", "160"],
    ["557", "78", "134"],
    ["155", "11", "245"],
    ["689", "33", "689"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["569", "09", "379"],
    ["560", "11", "128"],
    ["890", "72", "246"],
    ["579", "14", "167"],
    ["660", "20", "127"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["346", "39", "568"],
    ["245", "13", "148"],
    ["110", "27", "133"],
    ["190", "07", "377"],
    ["188", "78", "260"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["256", "37", "269"],
    ["346", "39", "667"],
    ["670", "30", "460"],
    ["478", "93", "120"],
    ["577", "91", "489"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["240", "68", "800"],
    ["468", "89", "568"],
    ["366", "57", "467"],
    ["267", "56", "178"],
    ["568", "99", "144"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["356", "49", "135"],
    ["279", "81", "245"],
    ["670", "33", "157"],
    ["160", "71", "290"],
    ["450", "91", "128"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["144", "99", "135"],
    ["269", "76", "457"],
    ["188", "70", "136"],
    ["159", "55", "366"],
    ["133", "78", "567"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["235", "06", "790"],
    ["468", "89", "568"],
    ["140", "52", "147"],
    ["400", "44", "680"],
    ["488", "09", "577"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["357", "58", "288"],
    ["880", "65", "159"],
    ["690", "59", "270"],
    ["160", "78", "369"],
    ["468", "89", "568"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["366", "57", "467"],
    ["267", "56", "178"],
    ["349", "68", "189"],
    ["148", "36", "169"],
    ["299", "04", "248"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["400", "43", "256"],
    ["180", "99", "478"],
    ["134", "86", "556"],
    ["790", "67", "188"],
    ["900", "99", "469"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["566", "72", "138"],
    ["114", "61", "579"],
    ["450", "94", "149"],
    ["469", "99", "126"],
    ["145", "01", "579"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["289", "98", "990"],
    ["128", "16", "448"],
    ["468", "87", "269"],
    ["236", "12", "156"],
    ["225", "96", "457"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["129", "20", "578"],
    ["114", "63", "238"],
    ["180", "99", "568"],
    ["338", "45", "780"],
    ["279", "81", "245"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["559", "94", "112"],
    ["340", "73", "689"],
    ["158", "47", "223"],
    ["258", "53", "247"],
    ["357", "53", "599"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["144", "91", "678"],
    ["370", "04", "158"],
    ["400", "41", "137"],
    ["390", "27", "467"],
    ["259", "65", "447"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["445", "37", "368"],
    ["190", "02", "246"],
    ["126", "91", "560"],
    ["440", "89", "270"],
    ["130", "49", "180"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["445", "33", "247"],
    ["130", "40", "190"],
    ["880", "61", "470"],
    ["399", "12", "138"],
    ["168", "53", "238"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["146", "16", "556"],
    ["145", "02", "589"],
    ["170", "84", "158"],
    ["235", "01", "470"],
    ["480", "20", "136"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["779", "38", "350"],
    ["256", "34", "590"],
    ["347", "42", "237"],
    ["570", "24", "257"],
    ["347", "42", "237"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["570", "24", "257"],
    ["247", "37", "340"],
    ["238", "34", "347"],
    ["350", "87", "278"],
    ["135", "93", "148"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["258", "54", "130"],
    ["135", "93", "148"],
    ["589", "29", "478"],
    ["780", "51", "335"],
    ["136", "08", "468"],
    ["156", "28", "477"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["345", "24", "149"],
    ["158", "49", "289"],
    ["690", "54", "167"],
    ["126", "99", "379"],
    ["366", "55", "168"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["458", "74", "400"],
    ["570", "26", "600"],
    ["248", "47", "179"],
    ["570", "24", "257"],
    ["247", "37", "340"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["478", "94", "347"],
    ["288", "83", "445"],
    ["238", "34", "347"],
    ["350", "87", "278"],
    ["156", "28", "477"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["144", "94", "680"],
    ["167", "47", "377"],
    ["689", "30", "145"],
    ["678", "15", "500"],
    ["247", "38", "189"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["445", "37", "179"],
    ["129", "28", "170"],
    ["136", "09", "234"],
    ["689", "30", "389"],
    ["699", "42", "200"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["138", "22", "499"],
    ["990", "86", "169"],
    ["170", "85", "357"],
    ["236", "18", "189"],
    ["589", "22", "660"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["699", "47", "368"],
    ["167", "41", "678"],
    ["399", "17", "458"],
    ["280", "09", "135"],
    ["255", "25", "500"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["234", "90", "235"],
    ["380", "12", "228"],
    ["235", "01", "344"],
    ["570", "21", "678"],
    ["348", "52", "679"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["120", "33", "256"],
    ["178", "64", "789"],
    ["166", "31", "579"],
    ["150", "68", "170"],
    ["***", "**", "***"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["779", "38", "170"],
    ["117", "95", "230"],
    ["589", "24", "789"],
    ["270", "90", "479"],
    ["259", "60", "299"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["340", "77", "359"],
    ["660", "22", "679"],
    ["567", "83", "670"],
    ["480", "28", "260"],
    ["359", "72", "129"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["389", "03", "580"],
    ["556", "60", "677"],
    ["120", "36", "880"],
    ["370", "07", "449"],
    ["347", "45", "357"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["123", "60", "460"],
    ["579", "11", "669"],
    ["349", "60", "226"],
    ["126", "91", "155"],
    ["179", "77", "467"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["346", "38", "459"],
    ["279", "84", "590"],
    ["490", "36", "367"],
    ["368", "79", "144"],
    ["469", "91", "137"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["458", "79", "270"],
    ["570", "23", "247"],
    ["800", "86", "600"],
    ["278", "74", "338"],
    ["170", "86", "556"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["289", "98", "990"],
    ["128", "16", "448"],
    ["468", "87", "269"],
    ["236", "12", "156"],
    ["225", "96", "457"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["129", "20", "578"],
    ["114", "63", "238"],
    ["180", "99", "568"],
    ["338", "45", "780"],
    ["279", "81", "245"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["559", "94", "112"],
    ["340", "73", "689"],
    ["158", "47", "223"],
    ["258", "53", "247"],
    ["357", "53", "599"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["144", "91", "678"],
    ["370", "04", "158"],
    ["400", "41", "137"],
    ["390", "27", "467"],
    ["259", "65", "447"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["445", "37", "368"],
    ["190", "02", "246"],
    ["126", "91", "560"],
    ["440", "89", "270"],
    ["130", "49", "180"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["445", "33", "247"],
    ["130", "40", "190"],
    ["880", "61", "470"],
    ["399", "12", "138"],
    ["168", "53", "238"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["146", "16", "556"],
    ["145", "02", "589"],
    ["170", "84", "158"],
    ["235", "01", "470"],
    ["480", "20", "136"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["779", "38", "350"],
    ["256", "34", "590"],
    ["347", "42", "237"],
    ["447", "56", "150"],
    ["247", "37", "340"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["570", "24", "257"],
    ["247", "37", "340"],
    ["689", "30", "145"],
    ["247", "37", "340"],
    ["678", "15", "500"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["258", "54", "130"],
    ["135", "93", "148"],
    ["589", "29", "478"],
    ["780", "51", "335"],
    ["136", "08", "468"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["345", "24", "149"],
    ["158", "49", "289"],
    ["690", "54", "167"],
    ["126", "99", "379"],
    ["366", "55", "168"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["458", "74", "400"],
    ["570", "26", "600"],
    ["248", "47", "179"],
    ["570", "24", "257"],
    ["247", "37", "340"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["478", "94", "347"],
    ["288", "83", "445"],
    ["238", "34", "347"],
    ["350", "87", "278"],
    ["156", "28", "477"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["144", "94", "680"],
    ["167", "47", "377"],
    ["689", "30", "145"],
    ["678", "15", "500"],
    ["247", "38", "189"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["445", "37", "179"],
    ["129", "28", "170"],
    ["136", "09", "234"],
    ["689", "30", "389"],
    ["699", "42", "200"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["138", "22", "499"],
    ["990", "86", "169"],
    ["170", "85", "357"],
    ["236", "18", "189"],
    ["589", "22", "660"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["699", "47", "368"],
    ["167", "41", "678"],
    ["399", "17", "458"],
    ["280", "09", "135"],
    ["255", "25", "500"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["234", "90", "235"],
    ["380", "12", "228"],
    ["235", "01", "344"],
    ["570", "21", "678"],
    ["348", "52", "679"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["120", "33", "256"],
    ["178", "64", "789"],
    ["166", "31", "579"],
    ["150", "68", "170"],
    ["699", "47", "368"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["779", "38", "170"],
    ["117", "95", "230"],
    ["589", "24", "789"],
    ["270", "90", "479"],
    ["259", "60", "299"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["340", "77", "359"],
    ["660", "22", "679"],
    ["567", "83", "670"],
    ["480", "28", "260"],
    ["359", "72", "129"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["389", "03", "580"],
    ["556", "60", "677"],
    ["120", "36", "880"],
    ["370", "07", "449"],
    ["347", "45", "357"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["123", "60", "460"],
    ["579", "11", "669"],
    ["349", "60", "226"],
    ["126", "91", "155"],
    ["179", "77", "467"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["346", "38", "459"],
    ["279", "84", "590"],
    ["490", "36", "367"],
    ["368", "79", "144"],
    ["469", "91", "137"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["458", "79", "270"],
    ["570", "23", "247"],
    ["800", "86", "600"],
    ["278", "74", "338"],
    ["170", "86", "556"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["150", "68", "260"],
    ["147", "25", "690"],
    ["567", "85", "258"],
    ["890", "79", "450"],
    ["470", "17", "179"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["133", "76", "358"],
    ["470", "13", "490"],
    ["247", "34", "239"],
    ["267", "56", "169"],
    ["889", "51", "579"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["128", "18", "116"],
    ["157", "31", "470"],
    ["170", "83", "120"],
    ["366", "55", "140"],
    ["470", "13", "490"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["347", "48", "350"],
    ["236", "14", "400"],
    ["360", "91", "489"],
    ["223", "77", "458"],
    ["460", "09", "667"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["680", "43", "238"],
    ["167", "41", "380"],
    ["122", "54", "239"],
    ["136", "02", "345"],
    ["268", "63", "670"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["236", "16", "240"],
    ["338", "40", "280"],
    ["247", "32", "480"],
    ["170", "80", "145"],
    ["344", "13", "238"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["480", "28", "459"],
    ["290", "18", "189"],
    ["390", "29", "450"],
    ["446", "48", "279"],
    ["334", "05", "267"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["246", "21", "245"],
    ["248", "49", "360"],
    ["560", "14", "446"],
    ["140", "56", "466"],
    ["168", "52", "156"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["370", "05", "230"],
    ["149", "46", "178"],
    ["577", "96", "349"],
    ["245", "18", "134"],
    ["169", "64", "266"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["130", "42", "570"],
    ["177", "50", "127"],
    ["125", "80", "190"],
    ["389", "08", "558"],
    ["147", "20", "280"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["789", "40", "136"],
    ["499", "21", "489"],
    ["480", "25", "140"],
    ["679", "27", "278"],
    ["466", "63", "247"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["235", "08", "125"],
    ["267", "51", "579"],
    ["134", "87", "278"],
    ["122", "57", "467"],
    ["459", "81", "227"],
  ],

  "06/03/2024 to 06/09/2024": [
    ["179", "72", "138"],
    ["390", "20", "370"],
    ["780", "53", "670"],
    ["455", "41", "137"],
    ["168", "55", "267"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["688", "25", "249"],
    ["900", "93", "580"],
    ["366", "57", "250"],
    ["579", "13", "300"],
    ["480", "23", "670"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["256", "37", "188"],
    ["240", "67", "890"],
    ["290", "11", "678"],
    ["457", "61", "579"],
    ["359", "78", "369"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["470", "12", "228"],
    ["568", "90", "460"],
    ["467", "77", "890"],
    ["778", "23", "490"],
    ["247", "33", "256"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["160", "77", "278"],
    ["445", "34", "158"],
    ["590", "49", "478"],
    ["480", "27", "340"],
    ["588", "17", "890"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["134", "81", "579"],
    ["279", "02", "138"],
    ["690", "51", "489"],
    ["400", "45", "140"],
    ["379", "98", "369"],
  ],
};

export const MAINBAZAR = {
  "01/02/2023 to 01/08/2023": [
    ["144", "93", "238"],
    ["239", "49", "577"],
    ["489", "15", "258"],
    ["457", "62", "345"],
    ["233", "88", "134"],
  ],
  "01/09/2023 to 01/15/2023": [
    ["890", "74", "266"],
    ["139", "31", "146"],
    ["258", "56", "259"],
    ["339", "53", "490"],
    ["490", "31", "380"],
  ],
  "01/16/2023 to 01/22/2023": [
    ["788", "31", "489"],
    ["249", "52", "336"],
    ["688", "24", "590"],
    ["367", "60", "299"],
    ["800", "84", "257"],
  ],
  "01/23/2023 to 01/29/2023": [
    ["245", "18", "800"],
    ["150", "64", "680"],
    ["367", "63", "355"],
    ["248", "40", "668"],
    ["688", "24", "590"],
  ],
  "01/30/2023 to 02/05/2023": [
    ["137", "10", "460"],
    ["348", "53", "300"],
    ["125", "89", "379"],
    ["189", "85", "140"],
    ["113", "52", "237"],
  ],
  "02/06/2023 to 02/12/2023": [
    ["377", "76", "367"],
    ["780", "53", "238"],
    ["270", "92", "237"],
    ["257", "48", "990"],
    ["470", "19", "478"],
  ],
  "02/13/2023 to 02/19/2023": [
    ["245", "19", "379"],
    ["490", "36", "448"],
    ["234", "97", "359"],
    ["560", "12", "345"],
    ["229", "36", "466"],
  ],
  "02/20/2023 to 02/26/2023": [
    ["456", "57", "160"],
    ["449", "79", "126"],
    ["890", "76", "790"],
    ["126", "96", "240"],
    ["248", "44", "248"],
  ],
  "02/27/2023 to 03/05/2023": [
    ["278", "75", "780"],
    ["134", "84", "248"],
    ["590", "45", "267"],
    ["170", "88", "170"],
    ["680", "43", "346"],
  ],
  "03/06/2023 to 03/12/2023": [
    ["247", "32", "390"],
    ["189", "88", "233"],
    ["140", "51", "579"],
    ["268", "65", "339"],
    ["248", "44", "248"],
  ],
  "03/13/2023 to 03/19/2023": [
    ["369", "89", "225"],
    ["234", "92", "679"],
    ["249", "58", "134"],
    ["168", "57", "359"],
    ["399", "13", "337"],
  ],
  "03/20/2023 to 03/26/2023": [
    ["157", "32", "110"],
    ["179", "71", "236"],
    ["134", "88", "125"],
    ["458", "76", "457"],
    ["369", "81", "128"],
  ],
  "03/27/2023 to 04/02/2023": [
    ["680", "44", "699"],
    ["458", "71", "678"],
    ["114", "60", "127"],
    ["289", "93", "355"],
    ["490", "30", "460"],
  ],
  "04/03/2023 to 04/09/2023": [
    ["260", "88", "567"],
    ["377", "72", "390"],
    ["456", "57", "368"],
    ["338", "40", "334"],
    ["144", "90", "668"],
  ],
  "04/10/2023 to 04/16/2023": [
    ["128", "13", "256"],
    ["335", "10", "479"],
    ["289", "95", "230"],
    ["478", "90", "479"],
    ["577", "95", "249"],
  ],
  "04/17/2023 to 04/23/2023": [
    ["255", "28", "350"],
    ["246", "28", "134"],
    ["380", "11", "236"],
    ["799", "56", "880"],
    ["129", "27", "458"],
  ],
  "04/24/2023 to 04/30/2023": [
    ["100", "12", "390"],
    ["160", "72", "336"],
    ["138", "26", "150"],
    ["278", "77", "160"],
    ["469", "93", "300"],
  ],
  "05/01/2023 to 05/07/2023": [
    ["249", "58", "378"],
    ["279", "87", "340"],
    ["570", "24", "130"],
    ["159", "56", "600"],
    ["679", "26", "349"],
  ],
  "05/08/2023 to 05/14/2023": [
    ["178", "64", "699"],
    ["238", "34", "158"],
    ["459", "87", "179"],
    ["336", "24", "455"],
    ["134", "80", "460"],
  ],
  "05/15/2023 to 05/21/2023": [
    ["149", "47", "340"],
    ["236", "15", "230"],
    ["126", "93", "148"],
    ["134", "82", "660"],
    ["178", "64", "239"],
  ],
  "05/22/2023 to 05/28/2023": [
    ["169", "62", "110"],
    ["170", "85", "690"],
    ["166", "37", "124"],
    ["790", "61", "399"],
    ["678", "13", "238"],
  ],
  "05/29/2023 to 06/04/2023": [
    ["270", "95", "780"],
    ["290", "14", "789"],
    ["460", "03", "247"],
    ["180", "98", "116"],
    ["688", "20", "569"],
  ],
  "06/05/2023 to 06/11/2023": [
    ["489", "10", "488"],
    ["278", "77", "467"],
    ["460", "05", "799"],
    ["150", "69", "126"],
    ["114", "63", "120"],
  ],
  "06/12/2023 to 06/18/2023": [
    ["349", "61", "344"],
    ["158", "43", "229"],
    ["389", "05", "168"],
    ["119", "13", "779"],
    ["379", "94", "400"],
  ],
  "06/19/2023 to 06/25/2023": [
    ["580", "35", "348"],
    ["160", "73", "779"],
    ["147", "20", "677"],
    ["578", "07", "890"],
    ["128", "14", "149"],
  ],
  "06/26/2023 to 07/02/2023": [
    ["568", "99", "225"],
    ["345", "26", "358"],
    ["577", "93", "580"],
    ["456", "50", "145"],
    ["289", "99", "180"],
  ],
  "07/03/2023 to 07/09/2023": [
    ["123", "65", "113"],
    ["400", "43", "247"],
    ["789", "42", "138"],
    ["170", "81", "245"],
    ["334", "05", "357"],
  ],
  "07/10/2023 to 07/16/2023": [
    ["456", "52", "129"],
    ["569", "03", "599"],
    ["238", "31", "588"],
    ["699", "48", "800"],
    ["699", "48", "800"],
  ],
  "07/17/2023 to 07/23/2023": [
    ["270", "91", "137"],
    ["155", "11", "470"],
    ["590", "42", "679"],
    ["227", "11", "290"],
    ["259", "69", "469"],
  ],
  "07/24/2023 to 07/30/2023": [
    ["247", "30", "677"],
    ["270", "94", "266"],
    ["246", "24", "239"],
    ["129", "25", "348"],
    ["457", "61", "489"],
  ],
  "07/31/2023 to 08/06/2023": [
    ["289", "94", "239"],
    ["160", "77", "458"],
    ["227", "18", "378"],
    ["233", "88", "224"],
    ["390", "24", "356"],
  ],
  "08/07/2023 to 08/13/2023": [
    ["368", "75", "267"],
    ["126", "92", "147"],
    ["150", "69", "144"],
    ["239", "40", "569"],
    ["126", "92", "390"],
  ],
  "08/14/2023 to 08/20/2023": [
    ["239", "44", "266"],
    ["259", "61", "128"],
    ["457", "67", "250"],
    ["679", "20", "235"],
    ["126", "92", "147"],
  ],
  "08/21/2023 to 08/27/2023": [
    ["390", "22", "390"],
    ["467", "75", "889"],
    ["280", "05", "690"],
    ["180", "94", "158"],
    ["590", "44", "257"],
  ],
  "08/28/2023 to 09/03/2023": [
    ["790", "60", "677"],
    ["237", "22", "129"],
    ["170", "89", "469"],
    ["116", "81", "146"],
    ["400", "47", "250"],
  ],
  "09/04/2023 to 09/10/2023": [
    ["158", "41", "290"],
    ["157", "37", "278"],
    ["155", "14", "356"],
    ["360", "98", "567"],
    ["250", "74", "699"],
  ],
  "09/11/2023 to 09/17/2023": [
    ["336", "26", "169"],
    ["300", "31", "290"],
    ["377", "78", "170"],
    ["339", "59", "559"],
    ["780", "59", "270"],
  ],
  "09/18/2023 to 09/24/2023": [
    ["125", "88", "369"],
    ["469", "97", "160"],
    ["158", "44", "770"],
    ["679", "25", "249"],
    ["366", "51", "245"],
  ],
  "09/25/2023 to 10/01/2023": [
    ["790", "69", "469"],
    ["134", "86", "169"],
    ["457", "68", "233"],
    ["139", "38", "558"],
    ["269", "77", "368"],
  ],
  "10/02/2023 to 10/08/2023": [
    ["279", "83", "580"],
    ["369", "83", "337"],
    ["346", "35", "140"],
    ["570", "21", "290"],
    ["480", "26", "259"],
  ],
  "10/09/2023 to 10/15/2023": [
    ["369", "80", "668"],
    ["123", "61", "380"],
    ["580", "39", "144"],
    ["249", "56", "178"],
    ["300", "30", "488"],
  ],
  "10/16/2023 to 10/22/2023": [
    ["660", "25", "456"],
    ["390", "24", "590"],
    ["770", "40", "578"],
    ["345", "25", "159"],
    ["145", "04", "789"],
  ],
  "10/23/2023 to 10/29/2023": [
    ["699", "47", "160"],
    ["158", "40", "145"],
    ["246", "24", "130"],
    ["247", "35", "159"],
    ["279", "84", "266"],
  ],
  "10/30/2023 to 11/05/2023": [
    ["348", "50", "578"],
    ["889", "55", "889"],
    ["558", "87", "179"],
    ["445", "30", "136"],
    ["138", "28", "279"],
  ],
  "11/06/2023 to 11/12/2023": [
    ["246", "29", "199"],
    ["240", "67", "115"],
    ["158", "41", "137"],
    ["256", "30", "460"],
    ["558", "87", "179"],
  ],
  "11/13/2023 to 11/19/2023": [
    ["158", "41", "137"],
    ["889", "55", "889"],
    ["558", "87", "179"],
    ["345", "25", "159"],
    ["158", "40", "145"],
  ],
  "11/20/2023 to 11/26/2023": [
    ["122", "59", "180"],
    ["238", "34", "257"],
    ["179", "72", "156"],
    ["290", "15", "339"],
    ["116", "83", "337"],
  ],
  "11/27/2023 to 12/03/2023": [
    ["690", "55", "357"],
    ["169", "66", "268"],
    ["259", "69", "180"],
    ["478", "91", "227"],
    ["180", "97", "188"],
  ],
  "12/04/2023 to 12/10/2023": [
    ["345", "27", "269"],
    ["477", "89", "559"],
    ["480", "28", "350"],
    ["390", "26", "178"],
    ["146", "10", "578"],
  ],
  "12/11/2023 to 12/17/2023": [
    ["457", "62", "688"],
    ["334", "09", "900"],
    ["268", "66", "358"],
    ["155", "14", "149"],
    ["479", "03", "346"],
  ],
  "12/18/2023 to 12/24/2023": [
    ["340", "71", "489"],
    ["358", "66", "880"],
    ["357", "51", "678"],
    ["670", "34", "220"],
    ["246", "29", "577"],
  ],
  "12/25/2023 to 12/31/2023": [
    ["157", "39", "234"],
    ["220", "48", "459"],
    ["489", "19", "234"],
    ["270", "91", "236"],
    ["166", "34", "112"],
  ],
  "01/01/2024 to 01/07/2024": [
    ["170", "81", "137"],
    ["279", "85", "159"],
    ["237", "25", "159"],
    ["440", "82", "688"],
    ["670", "34", "338"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["279", "85", "500"],
    ["390", "21", "560"],
    ["235", "02", "237"],
    ["667", "90", "460"],
    ["345", "23", "238"],
  ],
  "01/15/2024 to 01/21/2024": [
    ["789", "45", "267"],
    ["245", "11", "470"],
    ["124", "78", "170"],
    ["570", "25", "555"],
    ["133", "74", "167"],
  ],
  "01/22/2024 to 01/28/2024": [
    ["990", "89", "469"],
    ["338", "47", "368"],
    ["118", "04", "446"],
    ["236", "16", "358"],
    ["125", "87", "359"],
  ],
  "01/29/2024 to 02/04/2024": [
    ["249", "58", "233"],
    ["223", "70", "280"],
    ["357", "55", "159"],
    ["125", "87", "359"],
    ["160", "72", "129"],
  ],
  "02/05/2024 to 02/11/2024": [
    ["589", "28", "477"],
    ["469", "99", "469"],
    ["467", "72", "570"],
    ["460", "01", "380"],
    ["123", "65", "357"],
  ],
  "02/12/2024 to 02/18/2024": [
    ["668", "04", "338"],
    ["890", "77", "133"],
    ["348", "55", "140"],
    ["456", "52", "336"],
    ["124", "77", "223"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["160", "70", "145"],
    ["126", "96", "150"],
    ["267", "54", "400"],
    ["180", "94", "167"],
    ["489", "14", "356"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["249", "50", "190"],
    ["460", "04", "257"],
    ["259", "68", "288"],
    ["550", "03", "670"],
    ["456", "55", "168"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["355", "31", "399"],
    ["459", "88", "279"],
    ["237", "20", "280"],
    ["290", "11", "344"],
    ["800", "82", "246"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["588", "15", "230"],
    ["245", "15", "690"],
    ["700", "71", "669"],
    ["135", "90", "389"],
    ["680", "41", "335"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["144", "98", "288"],
    ["356", "42", "228"],
    ["457", "63", "599"],
    ["269", "71", "470"],
    ["578", "02", "156"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["470", "12", "589"],
    ["890", "71", "560"],
    ["147", "22", "688"],

    ["369", "84", "770"],
    ["460", "08", "378"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["123", "67", "377"],
    ["889", "54", "680"],
    ["124", "71", "560"],
    ["190", "03", "256"],
    ["147", "22", "688"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["169", "61", "489"],
    ["144", "97", "340"],
    ["355", "38", "189"],
    ["578", "01", "489"],
    ["250", "79", "289"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["790", "63", "670"],
    ["559", "90", "136"],
    ["116", "85", "249"],
    ["240", "64", "590"],
    ["799", "58", "189"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["267", "55", "456"],
    ["347", "44", "257"],
    ["489", "11", "470"],
    ["233", "87", "160"],
    ["344", "14", "257"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["245", "11", "236"],
    ["358", "66", "178"],
    ["356", "40", "569"],
    ["347", "44", "130"],
    ["167", "40", "488"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["349", "67", "269"],
    ["688", "20", "280"],
    ["670", "32", "200"],
    ["189", "86", "268"],
    ["358", "67", "359"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["136", "03", "148"],
    ["117", "98", "260"],
    ["125", "80", "127"],
    ["345", "20", "127"],
    ["138", "29", "469"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["159", "54", "789"],
    ["189", "81", "236"],
    ["145", "04", "680"],
    ["270", "97", "368"],
    ["347", "46", "330"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["490", "39", "559"],
    ["346", "36", "240"],
    ["447", "58", "233"],
    ["139", "32", "156"],
    ["359", "74", "789"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["335", "19", "234"],
    ["466", "67", "458"],
    ["277", "68", "224"],
    ["238", "32", "200"],
    ["330", "65", "348"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["590", "48", "369"],
    ["270", "93", "445"],
    ["780", "52", "589"],
    ["144", "97", "458"],
    ["144", "97", "700"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["148", "32", "200"],
    ["159", "50", "578"],
    ["356", "47", "179"],
    ["369", "89", "568"],
    ["245", "12", "138"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["389", "09", "469"],
    ["169", "66", "150"],
    ["228", "25", "366"],
    ["579", "13", "490"],
    ["279", "81", "146"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["459", "82", "156"],
    ["150", "67", "179"],
    ["360", "95", "267"],
    ["169", "68", "170"],
    ["789", "44", "248"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["770", "40", "136"],
    ["356", "45", "267"],
    ["130", "40", "370"],
    ["125", "82", "778"],
    ["588", "13", "148"],
  ],
};

export const MAYAMORNING = {};

export const QUEENMORNING = {
  "01/01/2024 to 07/01/2024": [
    ["358", "67", "467"],
    ["110", "25", "889"],
    ["577", "99", "289"],
    ["357", "51", "128"],
    ["667", "92", "336"],
    ["238", "37", "133"],
    ["470", "13", "788"],
  ],
  "08/01/2024 to 04/01/2024": [
    ["338", "45", "690"],
    ["678", "16", "349"],
    ["348", "59", "117"],
    ["247", "31", "560"],
    ["158", "40", "389"],
    ["235", "08", "990"],
    ["457", "69", "234"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["588", "13", "148"],
    ["120", "32", "237"],
    ["136", "09", "469"],
    ["338", "44", "257"],
    ["579", "17", "160"],
    ["122", "52", "390"],
    ["250", "74", "112"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["139", "38", "378"],
    ["456", "57", "115"],
    ["335", "14", "680"],
    ["116", "86", "556"],
    ["237", "20", "488"],
    ["150", "64", "770"],
    ["360", "91", "245"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["557", "75", "339"],
    ["248", "48", "134"],
    ["779", "33", "445"],
    ["156", "29", "270"],
    ["229", "34", "149"],
    ["380", "10", "118"],
    ["230", "56", "367"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["114", "62", "246"],
    ["447", "53", "689"],
    ["570", "21", "137"],
    ["458", "78", "369"],
    ["127", "09", "900"],
    ["359", "72", "589"],
    ["220", "41", "146"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["478", "97", "160"],
    ["236", "12", "345"],
    ["440", "85", "168"],
    ["256", "36", "790"],
    ["177", "54", "158"],
    ["240", "60", "479"],
    ["480", "23", "599"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["125", "83", "346"],
    ["590", "46", "123"],
    ["227", "15", "780"],
    ["379", "90", "668"],
    ["778", "24", "130"],
    ["689", "33", "300"],
    ["368", "76", "169"],
  ],
  "26/02/2024 to 23/03/2024": [
    ["277", "63", "490"],
    ["350", "89", "199"],
    ["569", "02", "138"],
    ["249", "55", "500"],
    ["489", "14", "356"],
    ["269", "74", "167"],
    ["469", "96", "123"],
  ],
  "04/03/2024 to 25/03/2024": [
    ["155", "10", "280"],
    ["789", "44", "446"],
    ["225", "93", "580"],
    ["147", "28", "800"],
    ["267", "52", "679"],
    ["448", "69", "568"],
    ["239", "47", "340"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["578", "05", "113"],
    ["669", "17", "278"],
    ["148", "36", "466"],
    ["248", "48", "567"],
    ["129", "29", "450"],
    ["230", "58", "477"],
    ["890", "78", "369"],
  ],
  "18/03/2024 to 25/02/2024": [
    ["117", "92", "345"],
    ["558", "88", "125"],
    ["167", "41", "100"],
    ["390", "22", "246"],
    ["119", "10", "389"],
    ["458", "73", "490"],
    ["337", "31", "245"],
  ],
  "25/03/2024 to 21/03/2024": [
    ["112", "47", "269"],
    ["345", "28", "558"],
    ["247", "36", "358"],
    ["119", "12", "688"],
    ["122", "55", "230"],
    ["667", "96", "367"],
    ["224", "87", "458"],
  ],
  "01/04/2024 to 07/03/2024": [
    ["150", "64", "257"],
    ["348", "51", "579"],
    ["478", "97", "223"],
    ["789", "42", "147"],
    ["345", "20", "677"],
    ["246", "25", "159"],
    ["560", "16", "268"],
  ],
  "08/04/2024 to 15/04/2024": [
    ["334", "05", "456"],
    ["124", "73", "229"],
    ["790", "62", "345"],
    ["470", "15", "500"],
    ["260", "89", "478"],
    ["347", "41", "245"],
    ["122", "57", "368"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["139", "34", "770"],
    ["667", "90", "235"],
    ["580", "31", "678"],
    ["889", "52", "156"],
    ["250", "76", "457"],
    ["470", "13", "599"],
    ["789", "44", "347"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["378", "86", "123"],
    ["458", "75", "357"],
    ["259", "68", "468"],
    ["370", "04", "590"],
    ["246", "22", "679"],
    ["348", "54", "130"],
    ["588", "17", "467"],
  ],
  "29/04/2024 to 25/05/2024": [
    ["445", "32", "589"],
    ["680", "41", "245"],
    ["***", "**", "***"],
    ["135", "93", "346"],
    ["468", "85", "249"],
    ["259", "60", "460"],
    ["345", "23", "148"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["124", "71", "678"],
    ["780", "52", "589"],
    ["236", "14", "446"],
    ["390", "28", "279"],
    ["157", "33", "689"],
    ["144", "93", "490"],
    ["123", "65", "267"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["257", "48", "558"],
    ["166", "34", "220"],
    ["379", "99", "180"],
    ["555", "50", "668"],
    ["290", "12", "778"],
    ["440", "85", "168"],
    ["334", "01", "678"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["124", "73", "346"],
    ["457", "64", "590"],
    ["570", "21", "344"],
    ["239", "43", "580"],
    ["478", "96", "367"],
    ["579", "17", "340"],
    ["267", "59", "144"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["260", "82", "589"],
    ["235", "03", "148"],
    ["599", "36", "790"],
    ["122", "59", "379"],
    ["250", "70", "145"],
    ["334", "00", "677"],
    ["489", "18", "378"],
  ],
  "03/06/2024 to 10/06/2024": [
    ["346", "30", "127"],
    ["455", "41", "128"],
    ["237", "22", "345"],
    ["236", "13", "490"],
    ["358", "69", "234"],
    ["456", "54", "149"],
    ["568", "92", "200"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["226", "02", "570"],
    ["278", "76", "349"],
    ["680", "45", "159"],
    ["368", "73", "247"],
    ["170", "82", "679"],
    ["245", "11", "344"],
    ["670", "35", "258"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["345", "27", "133"],
    ["588", "19", "234"],
    ["359", "77", "467"],
    ["790", "64", "158"],
    ["266", "45", "357"],
    ["567", "82", "156"],
    ["139", "31", "146"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["468", "88", "233"],
    ["140", "57", "557"],
    ["279", "86", "123"],
    ["668", "03", "346"],
    ["450", "94", "699"],
    ["278", "74", "770"],
    ["169", "63", "580"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["380", "10", "479"],
    ["246", "22", "129"],
    ["580", "37", "250"],
    ["456", "51", "137"],
    ["239", "42", "570"],
    ["168", "59", "360"],
    ["459", "89", "289"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["124", "76", "600"],
    ["389", "04", "248"],
    ["135", "92", "336"],
    ["268", "61", "678"],
    ["447", "53", "139"],
    ["569", "06", "457"],
    ["489", "18", "800"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["235", "08", "170"],
    ["469", "92", "138"],
    ["448", "68", "369"],
    ["138", "20", "569"],
    ["225", "92", "480"],
    ["589", "27", "278"],
    ["169", "60", "677"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["599", "31", "344"],
    ["179", "75", "780"],
    ["260", "88", "134"],
    ["158", "40", "136"],
    ["678", "17", "377"],
    ["177", "53", "346"],
    ["670", "32", "246"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["280", "00", "578"],
    ["379", "91", "245"],
    ["570", "21", "128"],
    ["357", "56", "259"],
    ["466", "61", "560"],
    ["190", "05", "889"],
    ["579", "19", "126"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["467", "78", "440"],
    ["170", "89", "559"],
    ["788", "30", "127"],
    ["590", "41", "380"],
    ["126", "98", "800"],
    ["445", "37", "250"],
    ["130", "44", "347"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["588", "10", "280"],
    ["799", "56", "268"],
    ["389", "07", "278"],
    ["158", "44", "446"],
    ["789", "46", "123"],
    ["260", "86", "349"],
    ["566", "73", "120"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["149", "42", "336"],
    ["455", "49", "469"],
    ["156", "29", "568"],
    ["359", "71", "100"],
    ["249", "59", "450"],
    ["556", "66", "367"],
    ["780", "57", "449"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["235", "01", "137"],
    ["127", "09", "289"],
    ["579", "11", "678"],
    ["457", "62", "336"],
    ["146", "17", "890"],
    ["238", "30", "145"],
    ["379", "95", "267"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["378", "89", "360"],
    ["445", "34", "590"],
    ["137", "10", "136"],
    ["249", "59", "577"],
    ["345", "21", "470"],
    ["258", "52", "570"],
    ["156", "29", "478"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["124", "79", "270"],
    ["589", "20", "145"],
    ["246", "27", "250"],
    ["568", "99", "478"],
    ["178", "67", "160"],
    ["790", "64", "356"],
    ["578", "08", "279"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["466", "66", "349"],
    ["199", "98", "477"],
    ["790", "64", "347"],
    ["239", "48", "125"],
    ["390", "22", "237"],
    ["134", "80", "479"],
    ["368", "77", "250"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["137", "17", "700"],
    ["226", "00", "488"],
    ["456", "51", "579"],
    ["334", "06", "123"],
    ["240", "67", "458"],
    ["344", "15", "230"],
    ["148", "30", "389"],
  ],
};

export const QUEENDAY = {
  "01/01/2024 to 07/01/2024": [
    ["280", "05", "230"],
    ["458", "75", "366"],
    ["234", "98", "350"],
    ["367", "64", "266"],
    ["157", "33", "788"],
    ["340", "79", "234"],
    ["148", "38", "279"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["246", "28", "125"],
    ["899", "65", "690"],
    ["456", "53", "148"],
    ["359", "79", "180"],
    ["189", "81", "344"],
    ["770", "43", "256"],
    ["136", "08", "279"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["130", "42", "237"],
    ["149", "46", "880"],
    ["468", "85", "889"],
    ["269", "70", "235"],
    ["236", "19", "450"],
    ["890", "70", "235"],
    ["167", "41", "678"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["122", "58", "350"],
    ["579", "14", "158"],
    ["357", "56", "259"],
    ["126", "93", "120"],
    ["257", "42", "570"],
    ["350", "81", "290"],
    ["134", "89", "577"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["455", "46", "330"],
    ["247", "37", "890"],
    ["110", "24", "446"],
    ["250", "79", "135"],
    ["125", "86", "330"],
    ["789", "42", "480"],
    ["348", "51", "100"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["350", "89", "126"],
    ["120", "38", "468"],
    ["129", "27", "368"],
    ["557", "74", "167"],
    ["458", "77", "700"],
    ["345", "21", "236"],
    ["400", "44", "130"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["356", "42", "255"],
    ["360", "95", "159"],
    ["347", "49", "289"],
    ["259", "63", "689"],
    ["789", "46", "178"],
    ["336", "29", "450"],
    ["238", "37", "467"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["123", "62", "480"],
    ["456", "57", "160"],
    ["667", "90", "569"],
    ["589", "23", "238"],
    ["230", "52", "147"],
    ["155", "15", "168"],
    ["900", "96", "169"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["269", "77", "124"],
    ["220", "48", "468"],
    ["140", "52", "345"],
    ["288", "81", "236"],
    ["456", "54", "149"],
    ["178", "69", "234"],
    ["367", "62", "246"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["340", "77", "133"],
    ["370", "07", "269"],
    ["445", "30", "127"],
    ["380", "17", "368"],
    ["455", "45", "249"],
    ["125", "83", "490"],
    ["670", "38", "567"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["229", "39", "234"],
    ["347", "44", "356"],
    ["245", "13", "120"],
    ["134", "83", "247"],
    ["780", "56", "349"],
    ["124", "77", "160"],
    ["139", "39", "225"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["560", "14", "112"],
    ["570", "25", "140"],
    ["238", "36", "457"],
    ["227", "11", "489"],
    ["346", "39", "379"],
    ["780", "50", "145"],
    ["245", "18", "468"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["340", "77", "458"],
    ["160", "78", "260"],
    ["123", "68", "567"],
    ["225", "93", "670"],
    ["678", "15", "249"],
    ["299", "06", "358"],
    ["167", "45", "168"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["159", "56", "268"],
    ["270", "90", "136"],
    ["459", "88", "567"],
    ["290", "12", "679"],
    ["156", "25", "267"],
    ["466", "63", "670"],
    ["178", "67", "278"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["346", "39", "270"],
    ["589", "23", "238"],
    ["357", "51", "146"],
    ["228", "22", "679"],
    ["458", "79", "135"],
    ["359", "75", "348"],
    ["110", "23", "120"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["690", "51", "579"],
    ["480", "24", "167"],
    ["259", "66", "277"],
    ["250", "78", "477"],
    ["589", "21", "678"],
    ["379", "99", "577"],
    ["124", "70", "127"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["344", "15", "168"],
    ["147", "28", "189"],
    ["699", "43", "238"],
    ["289", "98", "350"],
    ["370", "03", "346"],
    ["359", "74", "680"],
    ["200", "27", "449"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["228", "25", "348"],
    ["590", "49", "225"],
    ["188", "71", "380"],
    ["126", "95", "780"],
    ["238", "39", "478"],
    ["567", "87", "449"],
    ["267", "56", "150"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["237", "22", "156"],
    ["179", "78", "170"],
    ["190", "02", "129"],
    ["279", "84", "167"],
    ["379", "93", "670"],
    ["357", "50", "370"],
    ["336", "27", "124"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["159", "57", "368"],
    ["689", "37", "359"],
    ["367", "61", "669"],
    ["778", "26", "123"],
    ["267", "59", "270"],
    ["680", "40", "226"],
    ["157", "39", "478"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["348", "52", "255"],
    ["378", "83", "166"],
    ["700", "71", "489"],
    ["345", "20", "235"],
    ["668", "08", "567"],
    ["255", "26", "150"],
    ["390", "29", "234"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["300", "39", "469"],
    ["390", "26", "169"],
    ["488", "06", "240"],
    ["345", "21", "146"],
    ["478", "98", "369"],
    ["600", "60", "334"],
    ["340", "76", "349"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["567", "89", "135"],
    ["155", "42", "650"],
    ["235", "57", "160"],
    ["267", "68", "769"],
    ["578", "17", "268"],
    ["139", "57", "489"],
    ["200", "45", "123"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["158", "41", "236"],
    ["560", "13", "120"],
    ["346", "31", "112"],
    ["130", "41", "155"],
    ["478", "98", "369"],
    ["600", "60", "334"],
    ["340", "76", "349"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["790", "68", "260"],
    ["990", "82", "237"],
    ["899", "60", "900"],
    ["469", "98", "116"],
    ["579", "18", "567"],
    ["266", "45", "140"],
    ["136", "08", "990"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["135", "93", "256"],
    ["235", "02", "129"],
    ["148", "33", "667"],
    ["478", "98", "134"],
    ["389", "09", "577"],
    ["789", "43", "490"],
    ["269", "78", "279"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["178", "61", "146"],
    ["230", "55", "168"],
    ["237", "25", "139"],
    ["120", "37", "269"],
    ["240", "65", "366"],
    ["139", "35", "168"],
    ["155", "15", "690"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["134", "87", "250"],
    ["790", "61", "245"],
    ["235", "03", "490"],
    ["567", "82", "138"],
    ["450", "97", "160"],
    ["470", "13", "788"],
    ["337", "39", "469"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["129", "23", "148"],
    ["589", "25", "357"],
    ["569", "03", "148"],
    ["134", "87", "124"],
    ["668", "08", "567"],
    ["255", "26", "150"],
    ["390", "29", "234"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["357", "54", "455"],
    ["358", "60", "550"],
    ["278", "74", "446"],
    ["189", "80", "569"],
    ["478", "98", "369"],
    ["600", "60", "334"],
    ["340", "76", "349"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["350", "89", "667"],
    ["489", "18", "279"],
    ["120", "32", "246"],
    ["690", "51", "579"],
    ["579", "18", "567"],
    ["266", "45", "140"],
    ["136", "08", "990"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["135", "95", "113"],
    ["479", "06", "466"],
    ["234", "93", "256"],
    ["344", "15", "168"],
    ["389", "09", "577"],
    ["789", "43", "490"],
    ["269", "78", "279"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["458", "77", "160"],
    ["679", "21", "236"],
    ["457", "66", "899"],
    ["228", "25", "348"],
    ["570", "27", "557"],
    ["167", "46", "169"],
    ["480", "24", "455"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["457", "66", "899"],
    ["390", "29", "577"],
    ["177", "59", "559"],
    ["399", "16", "240"],
    ["117", "98", "189"],
    ["348", "51", "489"],
    ["178", "69", "469"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["238", "39", "478"],
    ["567", "87", "449"],
    ["267", "56", "150"],
    ["369", "84", "356"],
    ["259", "64", "130"],
    ["477", "85", "230"],
    ["227", "19", "144"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["379", "93", "670"],
    ["357", "50", "370"],
    ["336", "27", "124"],
    ["146", "12", "138"],
    ["245", "16", "114"],
    ["127", "05", "348"],
    ["356", "40", "226"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["690", "51", "344"],
    ["300", "39", "469"],
    ["390", "26", "169"],
    ["488", "06", "240"],
    ["136", "07", "359"],
    ["489", "11", "579"],
    ["268", "63", "300"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["140", "54", "789"],
    ["138", "28", "288"],
    ["250", "79", "568"],
    ["259", "66", "277"],
    ["250", "78", "233"],
    ["789", "47", "223"],
    ["567", "85", "177"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["267", "59", "270"],
    ["680", "40", "226"],
    ["157", "39", "478"],
    ["269", "76", "349"],
    ["480", "24", "167"],
    ["259", "66", "277"],
    ["250", "78", "477"],
  ],
  // "30/09/2024 to 06/10/2024": [
  //   ["778", "24", "257"],
  //   ["557", "79", "450"],
  //   ["560", "18", "116"],
  //   ["466", "67", "160"],
  //   ["147", "28", "189"],
  //   ["699", "43", "238"],
  //   ["289", "98", "350"]
  // ],
};

export const QUEENNIGHT = {
  "01/01/2024 to 07/01/2024": [
    ["120", "31", "380"],
    ["118", "02", "480"],
    ["160", "75", "140"],
    ["280", "09", "270"],
    ["150", "64", "220"],
    ["120", "32", "480"],
    ["336", "21", "290"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["223", "74", "220"],
    ["245", "11", "335"],
    ["135", "94", "130"],
    ["490", "31", "137"],
    ["455", "43", "120"],
    ["390", "26", "114"],
    ["170", "89", "270"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["560", "12", "336"],
    ["115", "72", "480"],
    ["455", "47", "250"],
    ["240", "60", "226"],
    ["120", "33", "490"],
    ["113", "52", "336"],
    ["280", "04", "338"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["344", "14", "130"],
    ["133", "70", "280"],
    ["150", "68", "116"],
    ["480", "21", "227"],
    ["350", "86", "240"],
    ["225", "90", "280"],
    ["160", "73", "120"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["139", "32", "110"],
    ["150", "64", "130"],
    ["122", "51", "380"],
    ["390", "20", "118"],
    ["380", "12", "480"],
    ["490", "33", "157"],
    ["170", "86", "880"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["280", "05", "122"],
    ["110", "21", "137"],
    ["120", "36", "330"],
    ["339", "54", "220"],
    ["113", "59", "135"],
    ["240", "66", "330"],
    ["220", "43", "120"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["130", "47", "250"],
    ["123", "62", "110"],
    ["124", "70", "118"],
    ["440", "83", "120"],
    ["590", "45", "230"],
    ["120", "30", "280"],
    ["380", "13", "490"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["220", "42", "390"],
    ["334", "08", "350"],
    ["200", "23", "120"],
    ["126", "99", "270"],
    ["350", "82", "480"],
    ["270", "97", "124"],
    ["360", "91", "380"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["100", "10", "370"],
    ["240", "64", "112"],
    ["330", "66", "123"],
    ["240", "60", "280"],
    ["360", "98", "350"],
    ["368", "71", "146"],
    ["400", "47", "340"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["330", "62", "156"],
    ["580", "36", "330"],
    ["117", "91", "380"],
    ["490", "37", "160"],
    ["220", "43", "120"],
    ["440", "83", "490"],
    ["200", "25", "230"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["550", "09", "450"],
    ["270", "96", "330"],
    ["116", "89", "450"],
    ["123", "68", "260"],
    ["490", "32", "480"],
    ["230", "55", "780"],
    ["550", "01", "380"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["129", "25", "113"],
    ["230", "51", "128"],
    ["120", "31", "380"],
    ["240", "63", "490"],
    ["780", "51", "290"],
    ["110", "20", "460"],
    ["220", "47", "160"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["129", "28", "125"],
    ["560", "13", "490"],
    ["330", "66", "123"],
    ["245", "18", "279"],
    ["570", "20", "136"],
    ["570", "24", "789"],
    ["260", "85", "113"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["135", "97", "449"],
    ["679", "29", "234"],
    ["259", "68", "459"],
    ["678", "18", "125"],
    ["120", "37", "278"],
    ["559", "97", "467"],
    ["490", "30", "127"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["345", "26", "448"],
    ["570", "20", "136"],
    ["129", "25", "113"],
    ["679", "29", "234"],
    ["570", "20", "136"],
    ["157", "38", "170"],
    ["566", "72", "345"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["278", "73", "139"],
    ["150", "61", "290"],
    ["330", "66", "123"],
    ["448", "69", "559"],
    ["278", "76", "367"],
    ["280", "04", "266"],
    ["288", "85", "122"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["160", "77", "179"],
    ["379", "96", "367"],
    ["377", "73", "779"],
    ["680", "42", "589"],
    ["347", "43", "670"],
    ["148", "35", "690"],
    ["379", "96", "466"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["689", "37", "467"],
    ["330", "66", "123"],
    ["330", "66", "123"],
    ["389", "04", "789"],
    ["234", "97", "890"],
    ["577", "95", "168"],
    ["666", "83", "779"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["357", "54", "455"],
    ["339", "58", "378"],
    ["669", "14", "347"],
    ["389", "07", "223"],
    ["690", "51", "669"],
    ["479", "07", "467"],
    ["266", "46", "448"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["679", "24", "789"],
    ["360", "99", "900"],
    ["260", "85", "113"],
    ["279", "82", "228"],
    ["578", "00", "389"],
    ["269", "79", "577"],
    ["288", "86", "899"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["330", "66", "123"],
    ["330", "66", "123"],
    ["360", "94", "455"],
    ["113", "59", "135"],
    ["120", "35", "339"],
    ["589", "27", "269"],
    ["124", "76", "169"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["390", "28", "369"],
    ["779", "39", "900"],
    ["678", "16", "114"],
    ["379", "90", "118"],
    ["234", "91", "678"],
    ["358", "67", "368"],
    ["457", "69", "478"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["458", "77", "458"],
    ["330", "66", "123"],
    ["168", "51", "669"],
    ["678", "13", "355"],
    ["578", "01", "399"],
    ["389", "05", "780"],
    ["389", "06", "600"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["378", "88", "279"],
    ["248", "43", "355"],
    ["134", "88", "170"],
    ["137", "17", "179"],
    ["680", "43", "689"],
    ["135", "91", "335"],
    ["699", "47", "115"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["159", "59", "388"],
    ["780", "59", "900"],
    ["358", "64", "789"],
    ["480", "26", "880"],
    ["780", "51", "678"],
    ["668", "03", "300"],
    ["256", "39", "577"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["358", "69", "234"],
    ["346", "32", "679"],
    ["377", "73", "300"],
    ["379", "96", "457"],
    ["356", "40", "488"],
    ["160", "70", "389"],
    ["458", "73", "779"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["220", "41", "678"],
    ["190", "06", "330"],
    ["150", "60", "389"],
    ["223", "71", "470"],
    ["449", "76", "600"],
    ["460", "07", "377"],
    ["489", "17", "250"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["680", "42", "679"],
    ["149", "48", "189"],
    ["379", "96", "457"],
    ["348", "54", "455"],
    ["258", "52", "390"],
    ["190", "08", "288"],
    ["388", "97", "377"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["248", "43", "580"],
    ["345", "29", "478"],
    ["577", "92", "688"],
    ["289", "98", "440"],
    ["250", "71", "335"],
    ["567", "88", "279"],
    ["170", "85", "889"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["148", "33", "256"],
    ["138", "25", "159"],
    ["160", "77", "700"],
    ["147", "22", "110"],
    ["355", "30", "145"],
    ["677", "08", "440"],
    ["699", "48", "116"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["330", "66", "123"],
    ["289", "92", "156"],
    ["379", "96", "457"],
    ["389", "02", "570"],
    ["679", "20", "145"],
    ["110", "29", "559"],
    ["460", "03", "120"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["129", "24", "149"],
    ["135", "94", "789"],
    ["499", "29", "568"],
    ["340", "70", "488"],
    ["440", "88", "189"],
    ["340", "79", "577"],
    ["224", "87", "133"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["330", "66", "123"],
    ["119", "17", "340"],
    ["149", "46", "259"],
    ["346", "37", "377"],
    ["338", "44", "220"],
    ["139", "37", "566"],
    ["110", "20", "677"],
  ],

  "19/08/2024 to 25/08/2024": [
    ["368", "79", "270"],
    ["577", "97", "458"],
    ["566", "70", "389"],
    ["800", "86", "346"],
    ["356", "42", "679"],
    ["127", "07", "458"],
    ["667", "95", "889"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["266", "48", "170"],
    ["378", "83", "779"],
    ["477", "89", "234"],
    ["469", "92", "129"],
    ["158", "49", "126"],
    ["148", "36", "178"],
    ["388", "98", "134"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["389", "08", "459"],
    ["599", "37", "890"],
    ["366", "59", "469"],
    ["180", "96", "358"],
    ["356", "40", "578"],
    ["190", "07", "368"],
    ["269", "78", "477"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["557", "77", "179"],
    ["500", "54", "356"],
    ["247", "39", "144"],
    ["348", "50", "389"],
    ["589", "24", "347"],
    ["379", "92", "246"],
    ["226", "03", "256"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["278", "73", "689"],
    ["110", "28", "233"],
    ["288", "82", "246"],
    ["389", "00", "460"],
    ["179", "79", "270"],
    ["246", "28", "468"],
    ["124", "73", "238"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["577", "96", "358"],
    ["680", "42", "778"],
    ["234", "98", "369"],
    ["278", "70", "479"],
    ["590", "46", "367"],
    ["289", "96", "259"],
    ["489", "17", "269"],
  ],
  // "30/09/2024 to 06/10/2024": [
  //   ["245", "16", "114"],
  //   ["127", "05", "348"],
  //   ["356", "40", "226"],
  //   ["235", "09", "289"],
  //   ["367", "62", "345"],
  //   ["669", "16", "358"],
  //   ["226", "03", "490"]
  // ]
};

export const RIDDHIDAY = {
  "01/01/2024 to 07/01/2024": [
    ["379", "93", "670"],
    ["357", "50", "370"],
    ["336", "27", "124"],
    ["150", "65", "249"],
    ["780", "53", "670"],
    ["259", "62", "345"],
    ["780", "54", "455"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["690", "51", "344"],
    ["300", "39", "469"],
    ["390", "26", "169"],
    ["489", "10", "569"],
    ["360", "91", "380"],
    ["115", "72", "778"],
    ["880", "67", "269"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["140", "54", "789"],
    ["138", "28", "288"],
    ["250", "79", "568"],
    ["200", "29", "568"],
    ["339", "51", "678"],
    ["159", "57", "188"],
    ["268", "62", "589"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["458", "77", "890"],
    ["224", "81", "128"],
    ["356", "48", "189"],
    ["199", "93", "166"],
    ["260", "87", "269"],
    ["668", "00", "578"],
    ["367", "64", "789"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["120", "31", "380"],
    ["570", "26", "330"],
    ["115", "78", "134"],
    ["130", "49", "450"],
    ["469", "91", "489"],
    ["589", "20", "145"],
    ["246", "29", "234"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["250", "73", "670"],
    ["567", "82", "570"],
    ["124", "79", "135"],
    ["139", "37", "566"],
    ["450", "95", "690"],
    ["590", "46", "367"],
    ["900", "95", "249"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["234", "90", "677"],
    ["167", "40", "479"],
    ["359", "74", "257"],
    ["570", "28", "459"],
    ["135", "93", "229"],
    ["479", "07", "359"],
    ["367", "69", "667"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["478", "90", "136"],
    ["689", "32", "237"],
    ["268", "63", "148"],
    ["139", "37", "368"],
    ["234", "97", "269"],
    ["230", "55", "267"],
    ["557", "72", "129"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["458", "77", "700"],
    ["345", "21", "236"],
    ["490", "37", "368"],
    ["244", "04", "266"],
    ["199", "90", "145"],
    ["178", "61", "146"],
    ["230", "55", "168"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["789", "46", "178"],
    ["336", "29", "450"],
    ["238", "37", "467"],
    ["344", "17", "458"],
    ["134", "87", "250"],
    ["790", "61", "245"],
    ["235", "03", "490"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["230", "52", "147"],
    ["155", "15", "168"],
    ["900", "96", "169"],
    ["456", "57", "160"],
    ["129", "23", "148"],
    ["589", "25", "357"],
    ["569", "03", "148"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["456", "54", "149"],
    ["178", "69", "234"],
    ["367", "62", "246"],
    ["220", "48", "468"],
    ["578", "08", "134"],
    ["468", "81", "380"],
    ["460", "08", "990"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["379", "93", "670"],
    ["357", "50", "370"],
    ["336", "27", "124"],
    ["150", "65", "249"],
    ["780", "53", "670"],
    ["259", "62", "345"],
    ["780", "54", "455"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["690", "51", "344"],
    ["300", "39", "469"],
    ["390", "26", "169"],
    ["489", "10", "569"],
    ["360", "91", "380"],
    ["115", "72", "778"],
    ["880", "67", "269"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["140", "54", "789"],
    ["138", "28", "288"],
    ["250", "79", "568"],
    ["200", "29", "568"],
    ["339", "51", "678"],
    ["159", "57", "188"],
    ["268", "62", "589"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["458", "77", "890"],
    ["224", "81", "128"],
    ["356", "48", "189"],
    ["199", "93", "166"],
    ["260", "87", "269"],
    ["668", "00", "578"],
    ["367", "64", "789"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["122", "55", "690"],
    ["490", "35", "889"],
    ["457", "69", "234"],
    ["178", "66", "790"],
    ["350", "84", "112"],
    ["445", "34", "149"],
    ["344", "18", "459"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["499", "21", "678"],
    ["166", "36", "240"],
    ["470", "18", "990"],
    ["257", "43", "355"],
    ["780", "52", "246"],
    ["260", "84", "590"],
    ["449", "72", "679"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["490", "34", "112"],
    ["500", "50", "389"],
    ["248", "44", "266"],
    ["168", "57", "278"],
    ["689", "34", "770"],
    ["136", "09", "388"],
    ["680", "40", "569"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["234", "95", "780"],
    ["129", "26", "349"],
    ["459", "80", "578"],
    ["279", "88", "116"],
    ["277", "61", "380"],
    ["246", "25", "113"],
    ["579", "12", "228"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["338", "43", "599"],
    ["467", "78", "260"],
    ["147", "21", "335"],
    ["450", "91", "678"],
    ["560", "16", "330"],
    ["890", "79", "379"],
    ["335", "15", "258"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["456", "56", "178"],
    ["199", "93", "346"],
    ["235", "01", "669"],
    ["569", "08", "170"],
    ["123", "60", "578"],
    ["456", "52", "255"],
    ["458", "78", "369"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["560", "12", "129"],
    ["179", "75", "249"],
    ["780", "54", "338"],
    ["146", "19", "568"],
    ["448", "61", "245"],
    ["257", "44", "789"],
    ["360", "93", "599"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["900", "99", "568"],
    ["355", "31", "137"],
    ["788", "30", "235"],
    ["440", "82", "345"],
    ["256", "30", "136"],
    ["690", "55", "258"],
    ["468", "89", "900"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["119", "12", "570"],
    ["459", "83", "300"],
    ["588", "16", "899"],
    ["169", "69", "450"],
    ["229", "33", "120"],
    ["678", "19", "234"],
    ["690", "58", "369"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["699", "40", "370"],
    ["356", "41", "579"],
    ["147", "20", "479"],
    ["348", "59", "450"],
    ["122", "52", "147"],
    ["780", "56", "790"],
    ["125", "88", "990"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["290", "14", "130"],
    ["358", "68", "990"],
    ["600", "63", "247"],
    ["770", "47", "890"],
    ["377", "71", "678"],
    ["478", "95", "122"],
    ["119", "12", "570"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["699", "43", "599"],
    ["689", "36", "259"],
    ["240", "64", "789"],
    ["358", "63", "120"],
    ["347", "40", "479"],
    ["467", "75", "357"],
    ["260", "82", "570"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["140", "58", "125"],
    ["460", "04", "590"],
    ["690", "50", "677"],
    ["588", "16", "899"],
    ["566", "72", "660"],
    ["449", "79", "180"],
    ["233", "81", "380"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["688", "28", "369"],
    ["137", "19", "289"],
    ["168", "53", "229"],
    ["147", "20", "479"],
    ["136", "00", "127"],
    ["246", "24", "680"],
    ["458", "77", "890"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["559", "92", "255"],
    ["457", "63", "157"],
    ["460", "07", "250"],
    ["600", "63", "247"],
    ["445", "32", "200"],
    ["479", "02", "778"],
    ["360", "95", "230"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["560", "15", "366"],
    ["339", "51", "227"],
    ["179", "71", "137"],
    ["168", "53", "229"],
    ["239", "46", "114"],
    ["167", "47", "278"],
    ["188", "76", "367"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["157", "36", "556"],
    ["125", "82", "679"],
    ["144", "90", "569"],
    ["236", "18", "350"],
    ["125", "82", "679"],
    ["144", "90", "569"],
    ["236", "18", "350"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["488", "02", "336"],
    ["690", "50", "479"],
    ["126", "93", "599"],
    ["147", "25", "799"],
    ["690", "50", "479"],
    ["126", "93", "599"],
    ["147", "25", "799"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["390", "25", "258"],
    ["588", "10", "235"],
    ["135", "90", "127"],
    ["248", "49", "450"],
    ["378", "84", "770"],
    ["135", "90", "127"],
    ["248", "49", "450"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["340", "70", "550"],
    ["178", "66", "790"],
    ["280", "06", "268"],
    ["570", "28", "459"],
    ["779", "35", "168"],
    ["489", "10", "578"],
    ["890", "77", "115"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["166", "39", "180"],
    ["470", "18", "990"],
    ["226", "08", "125"],
    ["359", "79", "135"],
    ["350", "84", "112"],
    ["445", "34", "149"],
    ["136", "09", "360"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["560", "15", "366"],
    ["339", "51", "227"],
    ["179", "71", "137"],
    ["168", "53", "229"],
    ["239", "46", "114"],
    ["167", "47", "278"],
    ["188", "76", "367"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["157", "36", "556"],
    ["125", "82", "679"],
    ["144", "90", "569"],
    ["236", "18", "350"],
    ["125", "82", "679"],
    ["144", "90", "569"],
    ["236", "18", "350"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["488", "02", "336"],
    ["690", "50", "479"],
    ["126", "93", "599"],
    ["147", "25", "799"],
    ["690", "50", "479"],
    ["126", "93", "599"],
    ["147", "25", "799"],
  ],
  // "07/10/2024 to 13/10/2024": [
  //     ["390", "25", "258"],
  //     ["588", "10", "235"],
  //     ["135", "90", "127"],
  //     ["248", "49", "450"],
  //     ["378", "84", "770"],
  //     ["135", "90", "127"],
  //     ["248", "49", "450"],
  // ],
};
export const RIDDHINIGHT = {
  "01/01/2024 to 07/01/2024": [
    ["169", "69", "379"],
    ["147", "21", "335"],
    ["168", "56", "240"],
    ["558", "89", "900"],
    ["779", "34", "680"],
    ["156", "24", "347"],
    ["129", "26", "349"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["126", "93", "599"],
    ["235", "01", "669"],
    ["678", "14", "158"],
    ["278", "70", "389"],
    ["580", "39", "388"],
    ["136", "07", "340"],
    ["467", "78", "260"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["248", "44", "266"],
    ["348", "55", "456"],
    ["589", "29", "234"],
    ["468", "87", "124"],
    ["223", "77", "700"],
    ["227", "15", "780"],
    ["199", "93", "346"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["459", "80", "578"],
    ["347", "41", "290"],
    ["144", "96", "150"],
    ["279", "83", "247"],
    ["389", "02", "679"],
    ["160", "78", "134"],
    ["179", "75", "249"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["267", "55", "230"],
    ["178", "62", "570"],
    ["370", "04", "248"],
    ["469", "95", "140"],
    ["368", "72", "138"],
    ["245", "17", "278"],
    ["489", "11", "137"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["388", "90", "145"],
    ["279", "84", "455"],
    ["237", "26", "150"],
    ["680", "40", "569"],
    ["112", "48", "260"],
    ["357", "56", "466"],
    ["560", "19", "180"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["240", "61", "146"],
    ["347", "48", "260"],
    ["135", "91", "678"],
    ["120", "30", "668"],
    ["235", "02", "499"],
    ["359", "77", "124"],
    ["689", "34", "248"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["480", "29", "667"],
    ["238", "35", "159"],
    ["370", "04", "789"],
    ["568", "92", "129"],
    ["357", "51", "245"],
    ["346", "36", "178"],
    ["233", "89", "450"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["359", "70", "578"],
    ["178", "65", "249"],
    ["480", "21", "399"],
    ["667", "99", "478"],
    ["266", "41", "137"],
    ["146", "18", "279"],
    ["369", "88", "567"],
  ],

  "06/05/2024 to 12/05/2024": [
    ["470", "13", "346"],
    ["158", "40", "226"],
    ["500", "52", "499"],
    ["240", "64", "257"],
    ["189", "88", "378"],
    ["589", "26", "457"],
    ["237", "20", "460"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["568", "92", "156"],
    ["246", "28", "369"],
    ["445", "33", "120"],
    ["690", "51", "579"],
    ["266", "40", "370"],
    ["788", "32", "129"],
    ["269", "78", "567"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["440", "85", "780"],
    ["267", "52", "679"],
    ["133", "79", "360"],
    ["480", "22", "147"],
    ["289", "91", "560"],
    ["345", "20", "136"],
    ["155", "14", "167"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["277", "60", "127"],
    ["229", "39", "234"],
    ["356", "40", "488"],
    ["259", "62", "246"],
    ["100", "14", "338"],
    ["599", "38", "260"],
    ["568", "92", "138"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["368", "78", "233"],
    ["559", "97", "890"],
    ["125", "80", "578"],
    ["889", "59", "199"],
    ["267", "55", "348"],
    ["237", "29", "225"],
    ["159", "57", "458"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["127", "05", "249"],
    ["480", "28", "378"],
    ["369", "84", "239"],
    ["568", "91", "489"],
    ["145", "02", "345"],
    ["467", "70", "280"],
    ["470", "18", "116"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["570", "21", "236"],
    ["135", "97", "458"],
    ["148", "31", "678"],
    ["258", "50", "460"],
    ["688", "23", "346"],
    ["125", "84", "590"],
    ["256", "37", "269"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["246", "20", "118"],
    ["149", "41", "146"],
    ["128", "19", "180"],
    ["677", "00", "569"],
    ["177", "51", "245"],
    ["356", "45", "799"],
    ["359", "76", "169"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["260", "87", "359"],
    ["788", "30", "479"],
    ["577", "95", "140"],
    ["279", "86", "457"],
    ["135", "93", "346"],
    ["590", "44", "248"],
    ["780", "58", "125"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["169", "62", "570"],
    ["378", "84", "257"],
    ["467", "79", "135"],
    ["600", "65", "348"],
    ["689", "30", "280"],
    ["157", "37", "458"],
    ["245", "10", "389"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["489", "17", "458"],
    ["190", "07", "124"],
    ["389", "02", "246"],
    ["567", "83", "490"],
    ["168", "51", "128"],
    ["240", "63", "300"],
    ["228", "23", "337"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["268", "61", "380"],
    ["660", "23", "788"],
    ["135", "91", "146"],
    ["445", "37", "269"],
    ["334", "04", "158"],
    ["800", "87", "557"],
    ["224", "89", "126"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["167", "43", "490"],
    ["234", "95", "339"],
    ["790", "65", "168"],
    ["580", "33", "247"],
    ["128", "15", "348"],
    ["250", "77", "458"],
    ["559", "96", "123"],
  ],
};
export const RIDDHIMORNING = {
  "01/01/2024 to 07/01/2024": [
    ["478", "92", "255"],
    ["155", "18", "125"],
    ["599", "33", "788"],
    ["499", "22", "570"],
    ["379", "91", "245"],
    ["569", "05", "159"],
    ["188", "73", "148"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["167", "40", "226"],
    ["189", "87", "223"],
    ["260", "82", "246"],
    ["570", "20", "226"],
    ["890", "74", "338"],
    ["499", "22", "570"],
    ["244", "09", "559"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["256", "33", "580"],
    ["248", "49", "559"],
    ["446", "49", "577"],
    ["366", "57", "188"],
    ["267", "58", "116"],
    ["500", "52", "156"],
    ["137", "12", "156"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["899", "69", "667"],
    ["138", "23", "689"],
    ["150", "66", "240"],
    ["348", "53", "247"],
    ["778", "26", "240"],
    ["579", "12", "679"],
    ["348", "54", "347"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["139", "34", "680"],
    ["266", "47", "115"],
    ["990", "81", "470"],
    ["356", "43", "157"],
    ["257", "48", "189"],
    ["137", "12", "147"],
    ["668", "06", "150"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["660", "29", "478"],
    ["348", "56", "178"],
    ["119", "10", "136"],
    ["480", "28", "233"],
    ["330", "64", "149"],
    ["460", "08", "189"],
    ["490", "31", "560"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["269", "75", "230"],
    ["200", "22", "110"],
    ["489", "16", "790"],
    ["368", "71", "380"],
    ["890", "79", "126"],
    ["238", "38", "369"],
    ["346", "36", "367"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["577", "99", "289"],
    ["228", "29", "469"],
    ["338", "45", "249"],
    ["256", "35", "339"],
    ["400", "43", "157"],
    ["244", "00", "578"],
    ["780", "52", "246"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["458", "79", "568"],
    ["770", "48", "369"],
    ["789", "45", "249"],
    ["345", "20", "244"],
    ["168", "50", "677"],
    ["155", "10", "479"],
    ["123", "65", "267"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["599", "31", "245"],
    ["399", "18", "279"],
    ["100", "18", "116"],
    ["168", "50", "677"],
    ["379", "90", "235"],
    ["220", "46", "277"],
    ["256", "39", "559"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["677", "08", "567"],
    ["269", "78", "378"],
    ["230", "06", "899"],
    ["468", "83", "788"],
    ["490", "37", "557"],
    ["556", "68", "440"],
    ["466", "66", "349"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["400", "41", "290"],
    ["770", "48", "800"],
    ["238", "32", "255"],
    ["588", "19", "360"],
    ["569", "08", "459"],
    ["114", "68", "567"],
    ["478", "98", "134"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["148", "30", "136"],
    ["479", "06", "169"],
    ["167", "42", "138"],
    ["667", "98", "260"],
    ["460", "04", "347"],
    ["370", "09", "225"],
    ["126", "28", "134"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["456", "58", "189"],
    ["146", "10", "389"],
    ["448", "63", "490"],
    ["277", "69", "388"],
    ["123", "69", "234"],
    ["246", "28", "125"],
    ["668", "06", "899"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["246", "26", "169"],
    ["148", "37", "160"],
    ["380", "17", "359"],
    ["889", "52", "110"],
    ["667", "98", "378"],
    ["679", "28", "170"],
    ["267", "50", "136"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["680", "43", "229"],
    ["359", "78", "125"],
    ["559", "99", "469"],
    ["227", "11", "290"],
    ["700", "79", "379"],
    ["144", "91", "335"],
    ["334", "00", "127"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["900", "99", "144"],
    ["378", "80", "136"],
    ["259", "69", "568"],
    ["270", "90", "389"],
    ["166", "66", "689"],
    ["169", "69", "117"],
    ["368", "70", "479"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["120", "38", "350"],
    ["135", "94", "158"],
    ["679", "24", "356"],
    ["688", "20", "479"],
    ["145", "04", "239"],
    ["344", "18", "666"],
    ["224", "84", "680"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["134", "81", "137"],
    ["348", "59", "234"],
    ["458", "79", "117"],
    ["269", "77", "124"],
    ["790", "62", "589"],
    ["160", "72", "147"],
    ["367", "62", "570"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["229", "33", "238"],
    ["268", "63", "445"],
    ["449", "77", "340"],
    ["236", "12", "246"],
    ["188", "70", "145"],
    ["117", "92", "147"],
    ["499", "25", "780"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["566", "76", "240"],
    ["200", "21", "119"],
    ["560", "19", "379"],
    ["669", "10", "299"],
    ["480", "20", "460"],
    ["256", "37", "359"],
    ["346", "31", "128"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["568", "97", "124"],
    ["489", "19", "289"],
    ["227", "13", "238"],
    ["240", "66", "259"],
    ["380", "10", "578"],
    ["479", "00", "235"],
    ["358", "62", "570"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["337", "30", "145"],
    ["130", "44", "130"],
    ["127", "08", "350"],
    ["190", "06", "150"],
    ["117", "97", "566"],
    ["477", "89", "450"],
    ["489", "15", "168"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["148", "30", "479"],
    ["449", "75", "140"],
    ["225", "90", "226"],
    ["567", "81", "245"],
    ["250", "73", "689"],
    ["355", "31", "678"],
    ["450", "95", "348"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["167", "40", "145"],
    ["440", "88", "567"],
    ["459", "89", "135"],
    ["356", "41", "560"],
    ["578", "06", "150"],
    ["240", "69", "469"],
    ["224", "84", "239"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["450", "90", "235"],
    ["369", "82", "688"],
    ["155", "15", "140"],
    ["249", "50", "280"],
    ["699", "48", "134"],
    ["558", "82", "110"],
    ["178", "68", "477"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["122", "57", "278"],
    ["129", "24", "338"],
    ["470", "19", "126"],
    ["446", "41", "290"],
    ["488", "02", "679"],
    ["889", "50", "226"],
    ["113", "50", "677"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["579", "15", "339"],
    ["266", "40", "370"],
    ["150", "68", "990"],
    ["770", "42", "156"],
    ["689", "35", "357"],
    ["458", "67", "124"],
    ["490", "37", "368"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["238", "39", "388"],
    ["340", "70", "479"],
    ["180", "91", "146"],
    ["789", "48", "279"],
    ["367", "67", "369"],
    ["900", "90", "479"],
    ["267", "51", "588"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["799", "51", "678"],
    ["459", "89", "577"],
    ["467", "76", "790"],
    ["133", "78", "558"],
    ["334", "07", "269"],
    ["469", "93", "355"],
    ["245", "14", "338"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["578", "00", "127"],
    ["678", "11", "245"],
    ["125", "82", "156"],
    ["346", "34", "167"],
    ["135", "90", "488"],
    ["689", "30", "127"],
    ["166", "37", "999"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["279", "85", "690"],
    ["478", "93", "355"],
    ["289", "96", "466"],
    ["169", "67", "269"],
    ["225", "91", "128"],
    ["469", "96", "349"],
    ["236", "13", "139"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["680", "47", "467"],
    ["126", "93", "455"],
    ["579", "17", "449"],
    ["990", "86", "556"],
    ["249", "51", "579"],
    ["145", "02", "778"],
    ["159", "55", "258"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["234", "94", "347"],
    ["359", "76", "178"],
    ["156", "23", "247"],
    ["580", "34", "257"],
    ["470", "13", "689"],
    ["269", "73", "157"],
    ["788", "34", "356"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["177", "52", "129"],
    ["188", "79", "126"],
    ["789", "40", "136"],
    ["130", "40", "389"],
    ["466", "69", "450"],
    ["135", "95", "690"],
    ["669", "10", "190"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["799", "52", "156"],
    ["379", "93", "580"],
    ["124", "71", "380"],
    ["478", "97", "890"],
    ["278", "71", "155"],
    ["259", "60", "299"],
    ["469", "90", "127"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["890", "79", "450"],
    ["360", "99", "568"],
    ["133", "73", "689"],
    ["168", "53", "148"],
    ["399", "19", "667"],
    ["250", "71", "236"],
    ["390", "27", "467"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["579", "10", "136"],
    ["460", "09", "270"],
    ["670", "35", "555"],
    ["339", "56", "367"],
    ["145", "03", "490"],
    ["356", "49", "577"],
    ["168", "52", "228"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["358", "62", "156"],
    ["236", "12", "778"],
    ["167", "44", "257"],
    ["790", "66", "169"],
    ["130", "42", "246"],
    ["449", "43", "157"],
    ["679", "27", "368"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["170", "83", "689"],
    ["449", "71", "489"],
    ["577", "96", "259"],
    ["144", "92", "138"],
    ["368", "70", "118"],
    ["669", "10", "389"],
    ["479", "03", "256"],
  ],
  "07/10/2024 to 13/10/2024": [
    ["450", "99", "126"],
    ["188", "70", "334"],
    ["257", "48", "134"],
    ["557", "74", "455"],
    ["599", "32", "237"],
    ["778", "26", "169"],
    ["279", "80", "668"],
  ],
};

export const SIDDHIDAY = {
  "01/01/2024 to 07/01/2024": [
    ["890", "79", "450"],
    ["360", "99", "568"],
    ["133", "73", "689"],
    ["168", "53", "148"],
    ["399", "19", "667"],
    ["250", "71", "236"],
    ["390", "27", "467"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["579", "10", "136"],
    ["460", "09", "270"],
    ["670", "35", "555"],
    ["339", "56", "367"],
    ["145", "03", "490"],
    ["356", "49", "577"],
    ["168", "52", "228"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["358", "62", "156"],
    ["236", "12", "778"],
    ["167", "44", "257"],
    ["790", "66", "169"],
    ["130", "42", "246"],
    ["449", "73", "157"],
    ["679", "27", "368"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["170", "83", "689"],
    ["449", "71", "489"],
    ["577", "96", "259"],
    ["144", "92", "138"],
    ["368", "70", "118"],
    ["669", "10", "389"],
    ["479", "03", "256"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["450", "99", "126"],
    ["188", "70", "334"],
    ["257", "48", "134"],
    ["557", "74", "455"],
    ["599", "32", "237"],
    ["778", "26", "169"],
    ["279", "80", "668"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["688", "24", "112"],
    ["568", "93", "788"],
    ["247", "31", "100"],
    ["136", "04", "356"],
    ["678", "11", "489"],
    ["135", "92", "589"],
    ["890", "71", "128"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["349", "66", "367"],
    ["490", "30", "235"],
    ["467", "77", "269"],
    ["477", "86", "556"],
    ["380", "16", "150"],
    ["359", "77", "278"],
    ["479", "03", "670"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["139", "30", "244"],
    ["300", "37", "700"],
    ["470", "17", "458"],
    ["112", "40", "145"],
    ["990", "83", "148"],
    ["388", "98", "468"],
    ["468", "86", "330"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["227", "10", "389"],
    ["135", "90", "677"],
    ["156", "20", "578"],
    ["559", "97", "179"],
    ["269", "79", "360"],
    ["112", "40", "136"],
    ["278", "74", "167"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["145", "08", "350"],
    ["680", "49", "234"],
    ["246", "26", "448"],
    ["770", "40", "479"],
    ["156", "23", "670"],
    ["337", "31", "579"],
    ["567", "83", "689"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["679", "26", "178"],
    ["330", "69", "379"],
    ["239", "46", "367"],
    ["334", "02", "390"],
    ["277", "60", "668"],
    ["667", "90", "127"],
    ["455", "41", "489"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["200", "29", "333"],
    ["259", "68", "459"],
    ["345", "23", "355"],
    ["237", "27", "115"],
    ["267", "56", "457"],
    ["556", "66", "358"],
    ["116", "89", "126"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["277", "68", "558"],
    ["348", "56", "367"],
    ["289", "91", "146"],
    ["299", "06", "123"],
    ["600", "63", "238"],
    ["789", "48", "440"],
    ["500", "56", "349"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["990", "85", "690"],
    ["114", "60", "280"],
    ["577", "95", "168"],
    ["799", "57", "377"],
    ["440", "86", "790"],
    ["270", "94", "257"],
    ["239", "47", "278"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["689", "32", "345"],
    ["130", "48", "459"],
    ["378", "81", "470"],
    ["789", "43", "148"],
    ["125", "82", "147"],
    ["890", "75", "267"],
    ["349", "62", "660"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["168", "53", "580"],
    ["700", "78", "369"],
    ["250", "79", "900"],
    ["339", "54", "257"],
    ["124", "70", "460"],
    ["247", "35", "366"],
    ["157", "35", "177"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["138", "24", "400"],
    ["670", "31", "335"],
    ["447", "53", "779"],
    ["266", "40", "550"],
    ["589", "21", "399"],
    ["355", "32", "390"],
    ["569", "09", "469"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["115", "71", "245"],
    ["268", "66", "268"],
    ["238", "38", "125"],
    ["880", "62", "688"],
    ["224", "82", "237"],
    ["779", "31", "669"],
    ["188", "78", "558"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["227", "10", "389"],
    ["135", "90", "677"],
    ["156", "20", "578"],
    ["559", "97", "179"],
    ["269", "79", "360"],
    ["112", "40", "136"],
    ["278", "74", "167"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["145", "08", "350"],
    ["680", "49", "234"],
    ["246", "26", "448"],
    ["770", "40", "479"],
    ["156", "23", "670"],
    ["337", "31", "579"],
    ["567", "83", "689"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["679", "26", "178"],
    ["330", "69", "379"],
    ["239", "46", "367"],
    ["334", "02", "390"],
    ["277", "60", "668"],
    ["667", "90", "127"],
    ["455", "41", "489"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["200", "29", "333"],
    ["259", "68", "459"],
    ["345", "23", "355"],
    ["237", "27", "115"],
    ["267", "56", "457"],
    ["556", "66", "358"],
    ["116", "89", "126"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["277", "68", "558"],
    ["348", "56", "367"],
    ["289", "91", "146"],
    ["299", "06", "123"],
    ["240", "63", "238"],
    ["789", "48", "440"],
    ["500", "56", "349"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["990", "85", "690"],
    ["114", "60", "280"],
    ["577", "95", "168"],
    ["799", "57", "377"],
    ["440", "86", "790"],
    ["270", "94", "257"],
    ["239", "47", "278"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["689", "32", "345"],
    ["130", "48", "459"],
    ["378", "81", "470"],
    ["789", "43", "148"],
    ["125", "82", "147"],
    ["890", "75", "267"],
    ["349", "62", "660"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["168", "53", "580"],
    ["700", "78", "369"],
    ["250", "79", "900"],
    ["339", "54", "257"],
    ["124", "70", "460"],
    ["247", "35", "366"],
    ["157", "35", "177"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["138", "24", "400"],
    ["670", "31", "335"],
    ["447", "53", "779"],
    ["266", "40", "550"],
    ["589", "21", "399"],
    ["355", "32", "390"],
    ["569", "09", "469"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["115", "71", "245"],
    ["268", "66", "268"],
    ["238", "38", "125"],
    ["880", "62", "688"],
    ["224", "82", "237"],
    ["779", "31", "669"],
    ["188", "78", "558"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["360", "90", "127"],
    ["348", "53", "148"],
    ["670", "33", "670"],
    ["125", "80", "668"],
    ["338", "42", "336"],
    ["167", "41", "119"],
    ["279", "80", "550"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["156", "26", "880"],
    ["126", "97", "124"],
    ["340", "70", "668"],
    ["234", "91", "155"],
    ["258", "58", "369"],
    ["250", "76", "268"],
    ["267", "59", "577"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["190", "06", "349"],
    ["289", "93", "139"],
    ["458", "70", "569"],
    ["157", "38", "279"],
    ["388", "98", "170"],
    ["340", "70", "668"],
    ["580", "32", "499"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["559", "92", "147"],
    ["770", "47", "368"],
    ["223", "73", "229"],
    ["333", "93", "148"],
    ["799", "51", "669"],
    ["479", "01", "290"],
    ["689", "34", "257"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["178", "64", "356"],
    ["500", "54", "446"],
    ["689", "32", "679"],
    ["370", "01", "678"],
    ["448", "63", "490"],
    ["337", "30", "569"],
    ["199", "92", "499"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["358", "60", "127"],
    ["136", "03", "120"],
    ["780", "54", "167"],
    ["144", "94", "248"],
    ["478", "96", "880"],
    ["124", "76", "600"],
    ["188", "73", "779"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["370", "04", "130"],
    ["278", "70", "127"],
    ["357", "55", "249"],
    ["237", "25", "456"],
    ["889", "58", "134"],
    ["380", "13", "256"],
    ["250", "72", "129"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["899", "68", "459"],
    ["478", "91", "146"],
    ["247", "31", "579"],
    ["588", "13", "157"],
    ["119", "14", "266"],
    ["246", "20", "136"],
    ["589", "28", "233"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["266", "49", "225"],
    ["169", "68", "170"],
    ["220", "40", "190"],
    ["268", "61", "245"],
    ["480", "27", "278"],
    ["469", "93", "490"],
    ["389", "02", "156"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["337", "32", "336"],
    ["118", "06", "600"],
    ["345", "29", "577"],
    ["599", "34", "789"],
    ["130", "40", "280"],
    ["468", "81", "344"],
    ["346", "30", "578"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["137", "11", "290"],
    ["190", "07", "278"],
    ["667", "96", "349"],
    ["490", "36", "556"],
    ["189", "83", "120"],
    ["390", "20", "488"],
    ["250", "79", "568"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["357", "51", "399"],
    ["788", "37", "467"],
    ["440", "80", "578"],
    ["223", "71", "344"],
    ["128", "11", "678"],
    ["358", "65", "339"],
    ["246", "25", "357"],
  ],
};
export const SIDDHINIGHT = {
  "01/01/2024 to 07/01/2024": [
    ["678", "12", "129"],
    ["499", "26", "268"],
    ["356", "46", "150"],
    ["667", "91", "100"],
    ["146", "19", "270"],
    ["790", "60", "479"],
    ["289", "97", "458"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["370", "06", "457"],
    ["250", "70", "578"],
    ["245", "11", "137"],
    ["358", "69", "469"],
    ["236", "15", "168"],
    ["138", "25", "122"],
    ["450", "92", "255"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["340", "77", "278"],
    ["246", "23", "256"],
    ["247", "30", "488"],
    ["579", "19", "234"],
    ["460", "02", "147"],
    ["366", "56", "367"],
    ["100", "13", "139"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["457", "69", "199"],
    ["140", "56", "178"],
    ["388", "99", "900"],
    ["380", "17", "269"],
    ["378", "84", "789"],
    ["490", "39", "478"],
    ["148", "32", "345"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["669", "10", "578"],
    ["456", "56", "169"],
    ["334", "06", "349"],
    ["690", "54", "257"],
    ["670", "39", "450"],
    ["229", "32", "679"],
    ["778", "21", "335"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["338", "47", "890"],
    ["136", "03", "238"],
    ["157", "37", "467"],
    ["459", "85", "249"],
    ["568", "90", "389"],
    ["235", "09", "289"],
    ["168", "59", "360"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["560", "17", "160"],
    ["258", "51", "380"],
    ["118", "09", "135"],
    ["458", "75", "889"],
    ["290", "10", "145"],
    ["688", "23", "445"],
    ["660", "25", "456"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["280", "07", "269"],
    ["244", "00", "370"],
    ["224", "88", "477"],
    ["344", "10", "569"],
    ["240", "61", "245"],
    ["368", "72", "480"],
    ["679", "25", "230"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["378", "88", "440"],
    ["780", "57", "278"],
    ["239", "48", "459"],
    ["256", "35", "267"],
    ["379", "94", "455"],
    ["229", "34", "266"],
    ["268", "67", "700"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["169", "67", "223"],
    ["588", "13", "580"],
    ["669", "17", "377"],
    ["699", "40", "578"],
    ["236", "17", "124"],
    ["118", "00", "299"],
    ["***", "**", "***"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["688", "27", "566"],
    ["188", "77", "340"],
    ["445", "30", "226"],
    ["350", "88", "279"],
    ["260", "88", "468"],
    ["479", "06", "448"],
    ["678", "14", "590"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["790", "60", "460"],
    ["567", "87", "160"],
    ["440", "82", "147"],
    ["370", "00", "569"],
    ["469", "91", "380"],
    ["135", "98", "170"],
    ["257", "48", "459"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["290", "19", "117"],
    ["359", "77", "340"],
    ["360", "98", "125"],
    ["160", "79", "478"],
    ["246", "25", "267"],
    ["689", "37", "458"],
    ["338", "40", "127"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["145", "06", "259"],
    ["349", "66", "114"],
    ["167", "49", "180"],
    ["789", "49", "388"],
    ["345", "28", "567"],
    ["347", "48", "369"],
    ["358", "61", "236"],
  ],
  "07/04/2024 to 13/04/2024": [
    ["280", "06", "123"],
    ["480", "20", "668"],
    ["590", "41", "137"],
    ["359", "79", "568"],
    ["470", "10", "389"],
    ["126", "90", "488"],
    ["557", "78", "567"],
  ],
  "14/04/2024 to 20/04/2024": [
    ["144", "90", "370"],
    ["199", "97", "160"],
    ["379", "97", "359"],
    ["257", "45", "168"],
    ["357", "58", "459"],
    ["600", "68", "279"],
    ["147", "21", "579"],
  ],
  "21/04/2024 to 27/04/2024": [
    ["250", "78", "369"],
    ["677", "04", "699"],
    ["289", "95", "177"],
    ["234", "97", "278"],
    ["336", "21", "236"],
    ["990", "87", "188"],
    ["457", "64", "158"],
  ],
  "28/04/2024 to 04/05/2024": [
    ["245", "13", "247"],
    ["367", "68", "567"],
    ["137", "12", "679"],
    ["260", "80", "145"],
    ["246", "29", "289"],
    ["390", "28", "233"],
    ["178", "69", "568"],
  ],
  "05/05/2024 to 11/05/2024": [
    ["389", "07", "124"],
    ["440", "80", "677"],
    ["566", "74", "770"],
    ["356", "48", "378"],
    ["257", "41", "380"],
    ["400", "46", "600"],
    ["116", "86", "358"],
  ],
  "12/05/2024 to 18/05/2024": [
    ["266", "40", "136"],
    ["689", "36", "150"],
    ["160", "75", "249"],
    ["670", "35", "366"],
    ["560", "12", "345"],
    ["257", "48", "134"],
    ["490", "30", "190"],
  ],
  "19/05/2024 to 25/05/2024": [
    ["279", "80", "479"],
    ["238", "32", "336"],
    ["244", "02", "778"],
    ["279", "88", "378"],
    ["135", "99", "568"],
    ["799", "58", "170"],
    ["348", "58", "378"],
  ],
  "26/05/2024 to 01/06/2024": [
    ["178", "66", "259"],
    ["226", "01", "678"],
    ["880", "64", "167"],
    ["248", "45", "267"],
    ["158", "46", "349"],
    ["137", "16", "457"],
    ["689", "35", "447"],
  ],
  "02/06/2024 to 08/06/2024": [
    ["450", "93", "157"],
    ["488", "01", "335"],
    ["479", "02", "660"],
    ["390", "28", "459"],
    ["235", "06", "169"],
    ["777", "11", "678"],
    ["249", "56", "259"],
  ],
  "09/06/2024 to 15/06/2024": [
    ["179", "77", "359"],
    ["145", "02", "129"],
    ["440", "89", "270"],
    ["177", "51", "489"],
    ["130", "45", "122"],
    ["559", "98", "468"],
    ["459", "84", "400"],
  ],
  "16/06/2024 to 22/06/2024": [
    ["126", "95", "348"],
    ["349", "65", "447"],
    ["255", "23", "599"],
    ["147", "29", "360"],
    ["660", "26", "790"],
    ["279", "84", "167"],
    ["125", "81", "470"],
  ],
  "23/06/2024 to 29/06/2024": [
    ["448", "65", "168"],
    ["270", "93", "247"],
    ["567", "80", "145"],
    ["230", "55", "690"],
    ["688", "26", "448"],
    ["280", "05", "348"],
    ["138", "29", "478"],
  ],
  "30/06/2024 to 06/07/2024": [
    ["458", "71", "560"],
    ["160", "79", "270"],
    ["489", "15", "122"],
    ["144", "95", "799"],
    ["239", "44", "590"],
    ["690", "58", "134"],
    ["239", "47", "269"],
  ],
  "07/07/2024 to 13/07/2024": [
    ["240", "63", "229"],
    ["236", "15", "690"],
    ["140", "52", "679"],
    ["489", "13", "238"],
    ["467", "70", "578"],
    ["359", "72", "589"],
    ["156", "23", "779"],
  ],
  "14/07/2024 to 20/07/2024": [
    ["458", "72", "147"],
    ["335", "15", "366"],
    ["490", "30", "127"],
    ["689", "30", "235"],
    ["138", "29", "478"],
    ["669", "12", "156"],
    ["356", "49", "126"],
  ],
  "21/07/2024 to 27/07/2024": [
    ["370", "04", "167"],
    ["170", "88", "459"],
    ["349", "61", "579"],
    ["148", "37", "359"],
    ["588", "13", "256"],
    ["236", "15", "690"],
    ["128", "12", "589"],
  ],
  "28/07/2024 to 03/08/2024": [
    ["267", "58", "378"],
    ["357", "51", "236"],
    ["590", "46", "259"],
    ["247", "34", "400"],
    ["289", "91", "489"],
    ["128", "10", "460"],
    ["890", "76", "457"],
  ],
  "04/08/2024 to 10/08/2024": [
    ["458", "79", "234"],
    ["700", "79", "199"],
    ["156", "26", "150"],
    ["369", "88", "279"],
    ["127", "04", "167"],
    ["180", "98", "170"],
    ["256", "38", "134"],
  ],
  "11/08/2024 to 17/08/2024": [
    ["569", "09", "478"],
    ["688", "29", "559"],
    ["389", "08", "558"],
    ["899", "61", "236"],
    ["368", "74", "356"],
    ["566", "71", "399"],
    ["226", "03", "346"],
  ],
  "18/08/2024 to 24/08/2024": [
    ["788", "38", "224"],
    ["178", "66", "240"],
    ["200", "22", "390"],
    ["679", "20", "460"],
    ["688", "21", "560"],
    ["149", "40", "244"],
    ["369", "84", "789"],
  ],
  "25/08/2024 to 30/08/2024": [
    ["890", "78", "459"],
    ["158", "41", "579"],
    ["289", "98", "459"],
    ["390", "20", "479"],
    ["567", "81", "155"],
    ["136", "03", "120"],
    ["280", "08", "468"],
  ],
  "31/09/2024 to 06/09/2024": [
    ["145", "03", "580"],
    ["178", "60", "190"],
    ["359", "79", "568"],
    ["146", "15", "348"],
    ["237", "21", "245"],
    ["358", "68", "189"],
    ["169", "62", "138"],
  ],
  "07/09/2024 to 13/09/2024": [
    ["150", "69", "450"],
    ["234", "97", "223"],
    ["158", "47", "124"],
    ["148", "39", "469"],
    ["258", "59", "117"],
    ["137", "18", "468"],
    ["789", "47", "278"],
  ],
  "14/09/2024 to 23/09/2024": [
    ["235", "06", "123"],
    ["145", "04", "347"],
    ["569", "06", "178"],
    ["160", "77", "359"],
    ["790", "66", "268"],
    ["356", "42", "237"],
    ["240", "69", "379"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["447", "52", "345"],
    ["589", "20", "578"],
    ["345", "28", "558"],
    ["237", "26", "150"],
    ["789", "48", "369"],
    ["580", "34", "347"],
    ["678", "19", "360"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["990", "81", "579"],
    ["770", "49", "900"],
    ["358", "66", "169"],
    ["299", "08", "233"],
    ["458", "70", "244"],
    ["489", "12", "147"],
    ["389", "07", "467"],
  ],
};
export const SIDDHIMORNING = {
  "01/01/2024 to 07/01/2024": [
    ["290", "14", "130"],
    ["358", "68", "990"],
    ["600", "00", "145"],
    ["445", "32", "200"],
    ["479", "02", "778"],
    ["190", "06", "448"],
    ["138", "22", "147"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["699", "43", "599"],
    ["689", "36", "259"],
    ["240", "64", "789"],
    ["179", "79", "199"],
    ["160", "79", "568"],
    ["667", "91", "579"],
    ["890", "77", "179"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["140", "58", "125"],
    ["460", "04", "590"],
    ["690", "50", "677"],
    ["134", "86", "349"],
    ["379", "92", "679"],
    ["380", "11", "236"],
    ["670", "30", "299"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["688", "28", "369"],
    ["137", "19", "289"],
    ["168", "53", "229"],
    ["239", "46", "114"],
    ["167", "47", "278"],
    ["188", "76", "367"],
    ["137", "12", "336"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["559", "92", "255"],
    ["457", "63", "157"],
    ["460", "07", "250"],
    ["158", "41", "245"],
    ["249", "57", "250"],
    ["128", "13", "580"],
    ["369", "81", "560"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["269", "79", "379"],
    ["780", "54", "167"],
    ["170", "80", "145"],
    ["489", "13", "689"],
    ["670", "37", "467"],
    ["122", "59", "559"],
    ["136", "08", "279"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["450", "98", "125"],
    ["260", "82", "679"],
    ["880", "64", "257"],
    ["248", "40", "280"],
    ["130", "48", "468"],
    ["267", "58", "378"],
    ["990", "89", "117"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["560", "15", "366"],
    ["339", "51", "227"],
    ["179", "71", "137"],
    ["170", "88", "116"],
    ["258", "59", "577"],
    ["158", "44", "770"],
    ["489", "17", "368"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["288", "84", "239"],
    ["479", "06", "240"],
    ["249", "58", "116"],
    ["179", "75", "168"],
    ["889", "55", "267"],
    ["560", "13", "599"],
    ["480", "26", "259"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["347", "46", "123"],
    ["788", "35", "168"],
    ["569", "05", "348"],
    ["889", "55", "267"],
    ["559", "97", "278"],
    ["156", "21", "128"],
    ["149", "44", "257"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["180", "93", "157"],
    ["456", "59", "667"],
    ["467", "79", "577"],
    ["190", "03", "788"],
    ["225", "90", "118"],
    ["380", "10", "127"],
    ["780", "55", "249"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["128", "15", "258"],
    ["569", "07", "557"],
    ["290", "14", "590"],
    ["100", "17", "124"],
    ["247", "31", "588"],
    ["579", "11", "146"],
    ["239", "42", "688"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["167", "40", "578"],
    ["670", "33", "346"],
    ["258", "56", "259"],
    ["224", "87", "269"],
    ["128", "17", "179"],
    ["259", "62", "589"],
    ["268", "67", "269"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["458", "79", "568"],
    ["199", "94", "789"],
    ["349", "60", "677"],
    ["359", "73", "157"],
    ["129", "25", "230"],
    ["119", "13", "120"],
    ["478", "99", "469"],
  ],
  "08/04/2024 to 08/04/2024": [
    ["334", "04", "455"],
    ["459", "86", "330"],
    ["357", "50", "280"],
    ["224", "80", "280"],
    ["144", "93", "256"],
    ["590", "44", "590"],
    ["440", "86", "880"],
  ],
  "09/04/2024 to 15/04/2024": [
    ["179", "78", "260"],
    ["560", "17", "269"],
    ["116", "85", "168"],
    ["239", "46", "367"],
    ["448", "60", "280"],
    ["366", "52", "147"],
    ["690", "55", "690"],
  ],
  "16/04/2024 to 22/04/2024": [
    ["220", "41", "245"],
    ["456", "53", "346"],
    ["225", "94", "149"],
    ["479", "04", "770"],
    ["347", "49", "388"],
    ["588", "12", "570"],
    ["668", "09", "144"],
  ],
  "23/04/2024 to 29/04/2024": [
    ["900", "96", "123"],
    ["688", "20", "280"],
    ["247", "37", "368"],
    ["150", "62", "589"],
    ["467", "71", "227"],
    ["446", "47", "115"],
    ["345", "28", "189"],
  ],
  "30/04/2024 to 06/05/2024": [
    ["678", "13", "445"],
    ["149", "44", "356"],
    ["270", "91", "137"],
    ["357", "59", "450"],
    ["580", "34", "248"],
    ["120", "39", "469"],
    ["279", "80", "370"],
  ],
  "07/05/2024 to 13/05/2024": [
    ["340", "76", "150"],
    ["558", "89", "234"],
    ["169", "67", "278"],
    ["334", "02", "156"],
    ["690", "51", "489"],
    ["488", "09", "360"],
    ["146", "19", "289"],
  ],
  "14/05/2024 to 20/05/2024": [
    ["135", "95", "258"],
    ["670", "32", "345"],
    ["237", "21", "560"],
    ["180", "96", "466"],
    ["279", "87", "368"],
    ["190", "02", "246"],
    ["477", "83", "139"],
  ],
  "21/05/2024 to 27/05/2024": [
    ["390", "26", "457"],
    ["136", "07", "223"],
    ["240", "64", "338"],
    ["557", "72", "147"],
    ["479", "09", "126"],
    ["790", "66", "178"],
    ["346", "30", "569"],
  ],
  "28/05/2024 to 03/06/2024": [
    ["180", "95", "348"],
    ["167", "43", "490"],
    ["990", "87", "269"],
    ["500", "58", "170"],
    ["156", "23", "445"],
    ["440", "87", "467"],
    ["234", "92", "589"],
  ],
  "04/06/2024 to 10/06/2024": [
    ["137", "13", "580"],
    ["258", "56", "457"],
    ["389", "02", "129"],
    ["560", "11", "245"],
    ["277", "65", "168"],
    ["777", "10", "190"],
    ["445", "36", "358"],
  ],
  "11/06/2024 to 17/06/2024": [
    ["360", "97", "124"],
    ["148", "33", "490"],
    ["349", "60", "578"],
    ["267", "59", "180"],
    ["356", "42", "570"],
    ["260", "80", "235"],
    ["780", "57", "368"],
  ],
  "18/06/2024 to 24/06/2024": [
    ["699", "45", "357"],
    ["227", "19", "568"],
    ["125", "84", "446"],
    ["247", "32", "688"],
    ["134", "85", "159"],
    ["179", "75", "159"],
    ["470", "10", "578"],
  ],
  "25/06/2024 to 01/07/2024": [
    ["299", "03", "157"],
    ["126", "91", "146"],
    ["780", "50", "389"],
    ["257", "48", "567"],
    ["180", "94", "248"],
    ["270", "91", "245"],
    ["378", "83", "670"],
  ],
  "02/07/2024 to 08/07/2024": [
    ["138", "25", "230"],
    ["780", "50", "389"],
    ["237", "29", "478"],
    ["369", "85", "780"],
    ["227", "10", "668"],
    ["558", "81", "335"],
    ["257", "49", "144"],
  ],
  "09/07/2024 to 15/07/2024": [
    ["480", "20", "136"],
    ["670", "35", "780"],
    ["279", "89", "117"],
    ["679", "24", "356"],
    ["290", "13", "580"],
    ["120", "39", "360"],
    ["344", "11", "119"],
  ],
  "16/07/2024 to 22/07/2024": [
    ["566", "74", "680"],
    ["289", "99", "568"],
    ["124", "71", "335"],
    ["457", "65", "690"],
    ["389", "09", "180"],
    ["460", "09", "379"],
    ["146", "13", "238"],
  ],
  "23/07/2024 to 29/07/2024": [
    ["168", "59", "478"],
    ["157", "30", "334"],
    ["480", "21", "227"],
    ["347", "40", "569"],
    ["449", "77", "278"],
    ["144", "98", "260"],
    ["567", "84", "338"],
  ],
  "30/07/2024 to 05/08/2024": [
    ["146", "17", "368"],
    ["450", "93", "670"],
    ["189", "82", "237"],
    ["590", "43", "355"],
    ["499", "25", "258"],
    ["349", "64", "167"],
    ["479", "06", "259"],
  ],
  "06/08/2024 to 12/08/2024": [
    ["116", "80", "569"],
    ["340", "76", "880"],
    ["300", "35", "267"],
    ["178", "60", "569"],
    ["237", "22", "345"],
    ["480", "26", "790"],
    ["256", "37", "115"],
  ],
  "13/08/2024 to 19/08/2024": [
    ["258", "55", "456"],
    ["145", "04", "130"],
    ["370", "04", "680"],
    ["560", "17", "368"],
    ["559", "91", "588"],
    ["700", "79", "450"],
    ["290", "17", "188"],
  ],
  "20/08/2024 to 26/08/2024": [
    ["128", "19", "234"],
    ["356", "41", "290"],
    ["260", "84", "167"],
    ["390", "29", "469"],
    ["169", "62", "156"],
    ["256", "30", "190"],
    ["670", "33", "157"],
  ],
  "27/08/2024 to 02/09/2024": [
    ["568", "96", "114"],
    ["116", "80", "479"],
    ["480", "27", "458"],
    ["258", "59", "199"],
    ["370", "07", "133"],
    ["330", "64", "699"],
    ["225", "92", "156"],
  ],
  "03/09/2024 to 09/09/2024": [
    ["223", "70", "127"],
    ["289", "97", "368"],
    ["156", "29", "360"],
    ["344", "13", "120"],
    ["790", "66", "457"],
    ["270", "99", "478"],
    ["560", "13", "139"],
  ],
  "10/09/2024 to 16/09/2024": [
    ["250", "79", "126"],
    ["570", "29", "180"],
    ["137", "16", "358"],
    ["577", "97", "359"],
    ["136", "04", "888"],
    ["134", "80", "280"],
    ["268", "65", "230"],
  ],
  "17/09/2024 to 23/09/2024": [
    ["167", "44", "257"],
    ["256", "35", "690"],
    ["780", "54", "158"],
    ["445", "32", "570"],
    ["240", "69", "126"],
    ["149", "42", "778"],
    ["227", "13", "166"],
  ],
  "24/09/2024 to 29/09/2024": [
    ["440", "81", "335"],
    ["460", "02", "589"],
    ["179", "76", "880"],
    ["680", "44", "167"],
    ["230", "51", "146"],
    ["459", "80", "389"],
    ["557", "73", "490"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["780", "54", "158"],
    ["440", "81", "335"],
    ["577", "97", "359"],
    ["680", "44", "167"],
    ["156", "29", "360"],
    ["260", "84", "167"],
    ["560", "13", "139"],
  ],
};

export const SHREEGANESH = {
  "01/01/2024 to 07/01/2024": [
    ["457", "63", "689"],
    ["379", "93", "120"],
    ["288", "80", "668"],
    ["359", "78", "567"],
    ["566", "70", "460"],
    ["680", "48", "440"],
    ["668", "04", "347"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["456", "52", "688"],
    ["689", "33", "689"],
    ["670", "39", "667"],
    ["460", "05", "348"],
    ["235", "08", "800"],
    ["234", "92", "688"],
    ["358", "67", "566"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["578", "08", "134"],
    ["468", "81", "380"],
    ["460", "08", "990"],
    ["400", "46", "466"],
    ["679", "28", "189"],
    ["789", "46", "790"],
    ["346", "39", "900"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["567", "86", "150"],
    ["800", "83", "779"],
    ["234", "95", "690"],
    ["360", "94", "455"],
    ["255", "26", "466"],
    ["679", "26", "349"],
    ["234", "95", "780"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["377", "70", "334"],
    ["130", "44", "789"],
    ["147", "25", "348"],
    ["200", "24", "347"],
    ["288", "81", "678"],
    ["359", "70", "578"],
    ["379", "98", "224"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["190", "07", "250"],
    ["360", "99", "450"],
    ["279", "89", "379"],
    ["233", "83", "346"],
    ["689", "32", "110"],
    ["123", "67", "458"],
    ["140", "52", "688"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["780", "53", "670"],
    ["259", "62", "345"],
    ["780", "54", "455"],
    ["169", "68", "189"],
    ["355", "39", "900"],
    ["460", "06", "169"],
    ["249", "53", "779"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["360", "91", "380"],
    ["115", "72", "778"],
    ["880", "67", "269"],
    ["340", "76", "600"],
    ["220", "46", "240"],
    ["670", "38", "279"],
    ["367", "62", "589"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["339", "51", "678"],
    ["159", "57", "188"],
    ["268", "62", "589"],
    ["240", "62", "228"],
    ["379", "94", "149"],
    ["690", "58", "233"],
    ["388", "97", "115"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["260", "87", "269"],
    ["668", "00", "578"],
    ["367", "64", "789"],
    ["189", "82", "129"],
    ["240", "67", "179"],
    ["677", "05", "889"],
    ["589", "26", "330"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["189", "85", "159"],
    ["379", "95", "690"],
    ["360", "90", "677"],
    ["148", "39", "577"],
    ["389", "04", "248"],
    ["467", "77", "377"],
    ["899", "64", "248"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["345", "26", "448"],
    ["357", "50", "479"],
    ["260", "85", "113"],
    ["368", "77", "133"],
    ["479", "06", "178"],
    ["157", "38", "170"],
    ["566", "72", "345"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["278", "73", "139"],
    ["150", "61", "290"],
    ["235", "04", "220"],
    ["448", "69", "559"],
    ["278", "76", "367"],
    ["280", "04", "266"],
    ["288", "85", "122"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["160", "77", "179"],
    ["379", "96", "367"],
    ["377", "73", "779"],
    ["680", "42", "589"],
    ["347", "43", "670"],
    ["148", "35", "690"],
    ["379", "96", "466"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["689", "37", "467"],
    ["668", "03", "599"],
    ["299", "01", "678"],
    ["389", "04", "789"],
    ["234", "97", "890"],
    ["577", "95", "168"],
    ["666", "83", "779"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["357", "54", "455"],
    ["339", "58", "378"],
    ["669", "14", "347"],
    ["389", "07", "223"],
    ["690", "51", "669"],
    ["479", "07", "467"],
    ["266", "46", "448"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["679", "24", "789"],
    ["360", "99", "900"],
    ["260", "85", "113"],
    ["279", "82", "228"],
    ["578", "00", "389"],
    ["269", "79", "577"],
    ["288", "86", "899"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["578", "06", "448"],
    ["389", "09", "234"],
    ["360", "94", "455"],
    ["120", "35", "339"],
    ["589", "27", "269"],
    ["124", "76", "169"],
    ["360", "99", "900"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["150", "61", "489"],
    ["447", "59", "478"],
    ["480", "29", "450"],
    ["667", "93", "670"],
    ["880", "69", "568"],
    ["260", "84", "220"],
    ["558", "87", "160"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["158", "42", "570"],
    ["116", "85", "230"],
    ["116", "80", "118"],
    ["233", "84", "680"],
    ["470", "10", "190"],
    ["456", "56", "240"],
    ["499", "21", "588"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["180", "90", "389"],
    ["128", "16", "790"],
    ["455", "43", "157"],
    ["120", "31", "579"],
    ["140", "50", "370"],
    ["169", "62", "688"],
    ["137", "14", "266"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["128", "10", "190"],
    ["233", "80", "550"],
    ["136", "01", "380"],
    ["137", "18", "800"],
    ["100", "15", "780"],
    ["245", "15", "889"],
    ["370", "01", "290"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["799", "55", "113"],
    ["557", "79", "559"],
    ["377", "75", "140"],
    ["236", "13", "148"],
    ["588", "12", "147"],
    ["160", "71", "236"],
    ["158", "40", "136"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["780", "52", "147"],
    ["300", "36", "899"],
    ["119", "15", "159"],
    ["899", "63", "580"],
    ["480", "27", "557"],
    ["145", "00", "190"],
    ["370", "04", "680"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["458", "77", "458"],
    ["460", "07", "700"],
    ["168", "51", "669"],
    ["678", "13", "355"],
    ["578", "01", "399"],
    ["389", "05", "780"],
    ["389", "06", "600"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["378", "88", "279"],
    ["248", "43", "355"],
    ["134", "88", "170"],
    ["137", "17", "179"],
    ["680", "43", "689"],
    ["135", "91", "335"],
    ["699", "47", "115"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["159", "59", "388"],
    ["780", "59", "900"],
    ["358", "64", "789"],
    ["480", "26", "880"],
    ["780", "51", "678"],
    ["668", "03", "300"],
    ["256", "39", "577"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["358", "69", "234"],
    ["346", "32", "679"],
    ["377", "73", "300"],
    ["379", "96", "457"],
    ["356", "40", "488"],
    ["160", "70", "389"],
    ["458", "73", "779"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["678", "10", "145"],
    ["580", "38", "189"],
    ["379", "90", "578"],
    ["160", "71", "236"],
    ["249", "56", "123"],
    ["568", "94", "149"],
    ["278", "72", "246"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["156", "20", "299"],
    ["136", "08", "558"],
    ["560", "15", "159"],
    ["269", "75", "500"],
    ["168", "51", "290"],
    ["569", "07", "115"],
    ["690", "51", "137"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["459", "88", "369"],
    ["268", "61", "128"],
    ["440", "87", "250"],
    ["277", "68", "350"],
    ["360", "97", "368"],
    ["490", "37", "340"],
    ["347", "49", "126"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["479", "07", "188"],
    ["113", "56", "466"],
    ["270", "98", "800"],
    ["155", "14", "257"],
    ["790", "66", "150"],
    ["499", "21", "579"],
    ["336", "22", "147"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["689", "30", "226"],
    ["269", "77", "458"],
    ["335", "10", "460"],
    ["179", "78", "369"],
    ["560", "11", "380"],
    ["889", "50", "127"],
    ["113", "58", "558"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["124", "79", "568"],
    ["778", "21", "236"],
    ["478", "92", "679"],
    ["130", "40", "578"],
    ["229", "34", "680"],
    ["388", "90", "118"],
    ["260", "84", "400"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["900", "95", "249"],
    ["148", "33", "346"],
    ["477", "89", "667"],
    ["124", "77", "250"],
    ["470", "13", "300"],
    ["233", "87", "467"],
    ["168", "50", "389"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["266", "47", "160"],
    ["225", "90", "569"],
    ["456", "58", "134"],
    ["670", "34", "356"],
    ["458", "71", "560"],
    ["470", "13", "300"],
    ["277", "63", "445"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["240", "61", "146"],
    ["477", "86", "330"],
    ["358", "60", "136"],
    ["249", "58", "350"],
    ["470", "14", "220"],
    ["134", "80", "389"],
    ["690", "50", "299"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["240", "62", "499"],
    ["244", "06", "790"],
    ["990", "87", "278"],
    ["344", "12", "390"],
    ["126", "90", "569"],
    ["880", "60", "677"],
    ["470", "14", "112"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["459", "87", "115"],
    ["290", "12", "570"],
    ["377", "70", "136"],
    ["133", "79", "900"],
    ["158", "42", "237"],
    ["129", "24", "149"],
    ["550", "03", "139"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["339", "51", "489"],
    ["569", "06", "330"],
    ["259", "69", "577"],
    ["560", "11", "245"],
    ["667", "95", "799"],
    ["360", "94", "347"],
    ["490", "38", "230"],
  ],
};
